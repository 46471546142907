import { Link } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import {
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button
} from "@mui/material";
import {
  VIGENCIA_INE,
  VOL_RC,
  VOL_RG,
  VOL_OBSERVER,
  VOL_FiL,
} from "@data/constants";
import Swal from "sweetalert2";
import moment from "moment";
import avatar from "@assets/img/SIAN.png";
import { useState, useEffect } from "react";
import { validaINE, handleAttrs } from "@utils/Utilities";
import PersonalInformationForm from "@components/Compromisos/PersonalInformationForm";
import ContactInformationForm from "@components/Compromisos/ContactInformationForm";
import AddressForm from "@components/Compromisos/AddressForm";
import VolunteerForm from "@components/Compromisos/VolunteerForm";
import SocialForm from "@components/Compromisos/SocialForm";
import UserForm from "../../components/Compromisos/UserForm";
import LoadingForm from "@components/LinearProgress/LoadingForm";

import { CompromisoAddInterfacePublic,CompromisoAddInterface } from "@data/interfaces/CompromisoInterfaces";
import { CompromisoAddPublicSchema } from "@data/schemas/CompromisoSchemas";
import { isEmptyOrNullObject } from "@utils/validations";
import { useFormik } from "formik";
import CompromisoServices from "@services/CompromisoServices";
import UserServices from "@services/UserServices";
import CatalogServices from "@services/CatalogServices";
import SendIcon from '@mui/icons-material/Send';

// project imports
//import Logo from 'ui-component/Logo';

// assets

const Register = () => {
  const { id } = useParams();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const catalogsOptions = [
    { id: "entidades" },
    { id: "municipios" },
    { id: "voluntarios" },
    { id: "tipo_vialidad" },
    { id: "mis_segmentos" },
    { id: "partidos" },
    { id: "medios_contacto" },
    { id: "generos" },
  ];
  //LoadigForm
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const formik = useFormik({
    initialValues: CompromisoAddInterfacePublic,
    validationSchema: CompromisoAddPublicSchema,
    onSubmit: (values) => {
      handleVerification(handleAttrs(values));
    },
  });
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [load, setLoad] = useState(true);
  const [dataUser,setDataUser] = useState({});
  const [idEstructuraPadre,setIdEstructuraPadre] = useState();

 
  useEffect(() => {
    UserServices.getDataUserOwner({ "Username":id})
    .then((res) => {
      if (res.results) {
        formik.setFieldValue('idUsuarioPropietario', res.response.id, false);
        setDataUser({
          "idUsuario":res.response.id,
          "DeviceID":res.response.DeviceID, 
        });
        let found = res.response.ambito_perfil.find(element => element.idTipo === 4);
        setIdEstructuraPadre(found ? found.AmbitoValor : 1)
        getCatalogs(res.response.id,res.response.DeviceID);
      } else {
        Swal.fire({ title: res.message, icon: "warning" });
      }
    })
    .catch((error) => Swal.fire({ title: error.message, icon: "warning" }))
    .finally(() => setLoad(false));

    // eslint-disable-next-line
  }, []);

  const getCatalogs = (id,celularDevice) => {
    CatalogServices.getCatalogsPublicEvents({ 
      catalogs: catalogsOptions })
    .then((res) => {
      if (res.results) {
        setCatalogFiltered(() => {
          let newObject = {};
          for (const item of catalogsOptions) {
            newObject[item.id] = res.response.catalogs[item.id];
          }
          return newObject;
        });
      } else {
        Swal.fire({ title: res.message, icon: "warning" });
      }
    })
    .catch((error) => Swal.fire({ title: error.message, icon: "warning" }))
    .finally(() => setLoad(false));
  }

  const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      const res = validaINE(
        values.INE,
        values.Nombre,
        values.Paterno,
        values.Materno,
        values.idSexo,
        values.FechaNacimiento,
        values.idEstadoNacimiento
      );

      if (!res.results) {
        Swal.fire({
          title: `¡Atención!`,
          text: res.message,
          icon: "info",
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
      } else {
        if (
          values.EsVoluntario === 1 &&
          [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(
            values.idVoluntario
          ) &&
          values.VigenciaINE < VIGENCIA_INE
        ) {
          Swal.fire({
            title: `¡Vigencia INE!`,
            text: "Invite a la persona a renovar su credencial de elector, ya que no será válida como medio de identificación en el siguiente proceso",
            icon: "info",
            reverseButtons: true,
            showCancelButton: false,
            confirmButtonText: "Continuar",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((res) => {
            if (res.isConfirmed) {
              handleSave(values);
            }
          });
        } else {
          handleSave(values);
        }
      }
    }
  };

  const handleSave = (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);
    let data = {};
   if(values.isUsuario === 0){
    Object.keys(CompromisoAddInterface).forEach((key) => {
      data[key] = values[key] !== null ? values[key] : CompromisoAddInterface[key];
    });
   }else{
    Object.keys(CompromisoAddInterfacePublic).forEach((key) => {
      data[key] = values[key] !== null ? values[key] : CompromisoAddInterfacePublic[key];
    });
   }
   

    data = {
      ...data,
      VigenciaINE: values.VigenciaINE ? parseInt(values.VigenciaINE) : null,
      INE: values.INE.toUpperCase(),
      Nombre: values.Nombre.toUpperCase(),
      Paterno: values.Paterno.toUpperCase(),
      Materno: values.Materno.toUpperCase(),
      FechaNacimiento: moment(values.FechaNacimiento).format("YYYY-MM-DD"),
      CalleVive: `${values.TipoVialidad} ${values.CalleVive}`,
    };
    delete data.TipoVialidad;
    if(values.isUsuario === 0){

      data = { ...data, EsNuevo: 0 };
      delete data.idUsuarioPropietario;
      if(![VOL_RC, VOL_RG, VOL_OBSERVER].includes(data.idVoluntario)){
        delete data.TieneAuto;
        delete data.TieneExperiencia;
        delete data.AceptaCuidarOtra;
      }

      CompromisoServices.createCompromisoPublic({
        ...data,
        "idUsuario":dataUser.idUsuario,
        "DeviceID":dataUser.DeviceID, 
        })
        .then((res) => {
          if (res.success && res.results) {
            Swal.fire({
              title: res.message,
              icon: "success",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((isResponse) => {
              if (isResponse.isConfirmed) {
                clear();
                /*   if (!isNullOrUndefined(getFindCommitment))
                    navigate("/pase-lista"); */
              }
            });
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: "warning" });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
          setIsSuccessFormSave(true);
        });
    }else{
      delete data.isUsuario;
      data = { ...data, 
        Username: values.Celular,
        medioConfirmacion: "sms",
        idEstructuraSocial: idEstructuraPadre,
      
      };
      delete data.idMedioContacto;
      delete data.Segmentos;
      if(![VOL_RC, VOL_RG, VOL_OBSERVER].includes(data.idVoluntario)){
        delete data.TieneAuto;
        delete data.TieneExperiencia;
        delete data.AceptaCuidarOtra;
      }

      delete data.idEvento;
      delete data.token;
      
      UserServices.createUser({
        ...data,
        "idUsuario":dataUser.idUsuario,
        "DeviceID":dataUser.DeviceID, 
        })
      .then((res) => {
        if (res.success && res.results) {
          Swal.fire({
            title: res.message,
            icon: "success",
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            showLoaderOnConfirm: true,
            preConfirm: (code) => {
              if(code === res.response.codigo){
               UserServices.codeValidate({
                  code: code,
                  c: res.response.UUID
                })
                .then(respuesta => {
                    if (respuesta.success) {
                        Swal.fire({
                          title: `Usuario Validado`,
                          icon: 'info',
                          html:
                            '<b>Puede Ingrese en </b>, ' +
                            '<a href="https://siangto.com/login">Sian</a> ',
                        })
                  }
                  else{
                    Swal.showValidationMessage(
                      `Error en la validación. contacte al administrador`
                    )
                  }
                   
                })
                .catch(e => {
                    Swal.showValidationMessage(
                      `Error en la validación. contacte al administrador.`
                    )
                  })
              }else{
                Swal.showValidationMessage(
                  `El codígo no coincide`
                )
              }
            },
          allowOutsideClick: () => !Swal.isLoading()
          })
          clear();
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
        setIsSuccessFormSave(true);
      });
    }

  };

  const clear = () => {
    formik.setValues({
      ...formik.values,
      Password:"",
      Confirm:"",
      PIN:"",
      idVoluntario: "",
      INE: "",
      VigenciaINE: "",
      Nombre: "",
      Paterno: "",
      Materno: "",
      FechaNacimiento: "",
      idSexo: "",
      idEstadoNacimiento: "",
      CalleVive: "",
      TipoVialidad: "C",
      NumExtVive: "",
      NumIntVive: "",
      idMunicipioVive: "",
      idLocalidadVive: "",
      ColoniaVive: "",
      CPVive: "",
      SeccionVota: "",
      Celular: "",
      Telefono: "",
      TelMensajes: "",
      Correo: "",
      Facebook: "",
      Instagram: "",
      Twitter: "",
      TikTok: "",
      EsVoluntario: 1,
      idPartido: "",
      Segmentos: [],
      idMedioContacto: "",
      TieneAuto: "",
      TieneExperiencia: "",
      AceptaCuidarOtra: "",
    });
  };


  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-end"
      sx={{ minHeight: "100vh" }}
    >
    <LoadingForm
      loadinMessage={"Cargando datos..."}
      successMessage="¡Cargado con exito!"
      isLoading={isLoadigForm}
      success={isSuccessFormSave}
      isOpen={openLinearProgress}
      setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
    />
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "calc(100vh - 68px)" }}
        >
          <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Link to="#">
                  <img alt="Logo" src={avatar} width={80} height={80} />
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction={"column"}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Typography
                        variant="caption"
                        fontSize="16px"
                        color={"primary"}
                        textAlign={matchDownSM ? "center" : "inherit"}
                      >
                        ¿Desea tener una cuenta de usuario?
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue={formik.values.isUsuario}
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="No"
                          onClick={()=>formik.handleChange({
                            target: {
                              name: "isUsuario",
                              value: 0
                            },
                          })}
                        />
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Sí"
                          onClick={()=>formik.handleChange({
                            target: {
                              name: "isUsuario",
                              value: 1
                            },
                          })}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {
                formik.values.isUsuario === 1 &&
                <Grid item xs={12}>
                <UserForm
                  catalogs={catalogsFiltered}
                  loadigCatalogs={load}
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  setValues={formik.setValues}
                  handleChange={formik.handleChange}
                />
              </Grid>
              }
              <Grid item xs={12}>
                <PersonalInformationForm
                  catalogs={catalogsFiltered}
                  loadigCatalogs={load}
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  setValues={formik.setValues}
                  handleChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ContactInformationForm
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  handleChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <AddressForm
                  catalogs={catalogsFiltered}
                  loadigCatalogs={load}
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  setValues={formik.setValues}
                  handleChange={formik.handleChange}
                  movile={true}
                  movil={dataUser}
                />
              </Grid>
              <Grid item xs={12}>
                <VolunteerForm
                  medioContacto={false}
                  catalogs={catalogsFiltered}
                  loadigCatalogs={load}
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  setValues={formik.setValues}
                  handleChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                {catalogsFiltered.mis_segmentos.length > 0 && (
                  <SocialForm
                    catalogs={catalogsFiltered}
                    loadigCatalogs={load}
                    values={formik.values}
                    setValues={formik.setValues}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  xs={12}
                >
                  <Button variant="contained" endIcon={<SendIcon />} onClick={formik.submitForm}>
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
        {/* <AuthFooter /> */}
      </Grid>
    </Grid>
  );
};

export default Register;
