import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
//MUI
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  ListItemText,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
} from "@mui/material";
import BasicTable from "@components/Tables/BasicTable";
import ActionHeader from "@components/Containers/ActionHeader";
import events from "@services/EventsServices";
import Swal from "sweetalert2";
import { blue, grey, red, yellow } from "@mui/material/colors";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { getFechaActual } from "@utils/Utilities";
import { isEmptyOrNullObject } from "@utils/validations";
import Filter from "@components/Events/PaseListaFilter";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
const EventosPaseLista = () => {
  //Constants
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("table");
  const [data, setData] = useState({
    page: 0,
    pageSize: 20,
    filtered: [
      {
        id: "eventos.id",
        value: 5,
        filter: "=",
      },
    ],
    sorted: [{ id: "eventos.FechaInicio", value: "asc" }],
  });
  const [dataListEvents, setDataListEvents] = useState([]);
  const [total, setTotal] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const colums = [
    { label: "Fecha", id: "FechaInicio", columnAction: false, width: 150 },
    {
      label: "Evento",
      id: "Actividad",
      columnAction: false,
      width: 900,
      orderBy: "Actividad",
    },
    {
      label: "Hora Inicio",
      id: "HoraInicio",
      align: "center",
      columnAction: false,
      width: 300,
    },
  ];

  const getEventosData = useCallback((data) => {
    setLoading(true);
    events
      .getEventsList(data)
      .then((res) => {
        if (res.results) {
          const Eventos = res.response.data.map((item) => {
            return {
              ...item,
              Actividad: (
                <Button
                  disableElevation
                  variant="text"
                  color="primaryDark"
                  size="small"
                  sx={{
                    ":hover": {
                      bgcolor: blue[900],
                      color: "white",
                    },
                    p: "0 auto",
                    textAlign: "left",
                  }}
                  startIcon={<Icon>edit</Icon>}
                  onClick={(e) => handlePaseLista(item)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="div" fontWeight={700} fontSize={15}>
                        {item.Actividad}
                      </Typography>
                    }
                  />
                </Button>
              ),
            };
          });
          setDataListEvents(Eventos);
          setTotal(res.response.data.length);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getEventosData(data);
    // eslint-disable-next-line
  }, [data]);

  const handlePaseLista = (item) => {
    const { id } = item;

    navigate(`/eventos/pase-lista/${id}`);
  };

  const handleChangeFilter = (filtered) => {
    setData((prevState) => ({ ...prevState, filtered }));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Actividad: "eventos.Actividad",
      HoraInicio: "eventos.HoraInicio",
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <AdminLayout>
      <Container maxWidth={false} sx={{ backgroundColor: grey }}>
        <ActionHeader title="Pase de Lista en eventos" />
        {
          {
            table: (
              <Box>
                <Filter onChangeFilter={(e) => handleChangeFilter(e)} />
                <Card className="card-primary">
                  <CardContent>
                    <Grid2 container sx={{ p: 2 }} spacing={2}>
                      <Grid2 xs={12} sm={12} md={4} lg={4}></Grid2>
                    </Grid2>
                    <BasicTable
                      rows={dataListEvents}
                      hcolumns={colums}
                      handleChangePagination={handleChangePagination}
                      isLoading={loading}
                      total={total}
                      pageProp={data.page}
                      pageSize={data.pageSize}
                      stickyHeader={true}
                      handleManualSort={handleRequestSort}
                    />
                  </CardContent>
                </Card>
              </Box>
            ),
          }[view]
        }
      </Container>
    </AdminLayout>
  );
};

export default EventosPaseLista;
