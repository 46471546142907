import { useRef, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  Switch,
  Stack,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import events from "../../services/EventsServices";
import moment from "moment";
import Swal from "sweetalert2";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { isEmptyOrInvalidString, isNullOrUndefined } from "@utils/validations";

function SearchPeople(props) {
  const {
    dataEvent,
    setDataUser,
    datauser,
    dataPreguntas,
    setFlagNot,
    setFlagQueestion,
    errors,
    touched,
    values,
    setValues,
    handleChange,
    catalogsFiltered,
    register,
    loadingRegister
  } = props;
  const [loadingIne, setLoadingIne] = useState(false);
  const [flagAccount, setFlagAccount] = useState(false);
  const botonRegistrar = useRef(null);

  const onblurINE = (INEValue) => {
    setFlagAccount(false);
    setDataUser({ Nombre: "" });
    let genderId = "";
    let stateId = "";
    let dob = "";
    let len = INEValue.length;

    if (len >= 12) {
      let year = moment().format("YYYY");
      let dobAux = INEValue.substring(6, 12);
      let anio = dobAux.substring(0, 2);
      let mes = dobAux.substring(2, 4);
      let dia = dobAux.substring(4, 6);

      anio = moment(anio, "YY").format("YYYY");

      let diff = parseInt(year) - parseInt(anio);

      if (diff > 0) {
        dob = `${dia}/${mes}/${anio}`;
      } else {
        anio = anio - 100;
        dob = `${dia}/${mes}/${anio}`;
      }
    }
    if (len >= 14) {
      let _sId = parseInt(INEValue.substring(12, 14));
      stateId = catalogsFiltered.entidades.find((item) => item.value === _sId)
        ? _sId
        : "";
    }
    if (len >= 15) {
      const genderKey = INEValue.substring(14, 15);
      genderId = genderKey === "M" ? 3 : genderKey === "H" ? 2 : 1;
    }

    setValues({
      ...values,
      FechaNacimiento: moment(dob, "DD/MM/YYYY"),
      idEstadoNacimiento: stateId,
      idSexo: genderId,
    });
  };

  const onblurCurp = (CurpValue) => {
    let genderId = "";
    let stateId = "";
    let dob = "";
    let len = CurpValue.length;

    if (len >= 12) {
      let year = moment().format("YYYY");
      let dobAux = CurpValue.substring(4, 10);
      let anio = dobAux.substring(0, 2);
      let mes = dobAux.substring(2, 4);
      let dia = dobAux.substring(4, 6);

      anio = moment(anio, "YY").format("YYYY");

      let diff = parseInt(year) - parseInt(anio);

      if (diff > 0) {
        dob = `${dia}/${mes}/${anio}`;
      } else {
        anio = anio - 100;
        dob = `${dia}/${mes}/${anio}`;
      }
    }

    const fechaNacimientoDate = moment(dob, "DD/MM/YYYY");
    const fechaActual = new Date();

    const edadMilisegundos = fechaActual - fechaNacimientoDate;
    const edadAnios = edadMilisegundos / (1000 * 60 * 60 * 24 * 365.25);

    if(edadAnios >= 19){
      Swal.fire({ title: "La CURP pertenece a alguien mayor de edad, coloque su Clave de Elector", icon: "warning" });
      handleChange({target:{name:"isMenor",value:0}});
      handleChange({target:{name:"CURP",value:""}});
      return true;
    }

    if (len >= 10) {
      const genderKey = CurpValue.substring(10, 11);
      genderId = genderKey === "M" ? 3 : genderKey === "H" ? 2 : 1;
    }
    let CurpData = {
      ...values,
      FechaNacimiento: moment(dob, "DD/MM/YYYY"),
      idSexo: genderId,
    };

    setValues(CurpData);

    return CurpData;
  };

  const searchINE = () => {
    let valorSearch = "";
    if (!values.isMenor && values.INE.length !== 18) {
      handleChange({
        target: {
          name: "INE",
          value: "",
        },
      });
      return false;
    }
    if (values.isMenor && values.CURP.length !== 18) {
      handleChange({
        target: {
          name: "CURP",
          value: "",
        },
      });
      return false;
    }
    if (loadingIne) {
      return false;
    }
    if (!values.isMenor && errors.INE) {
      return false;
    }
    if (values.isMenor && errors.CURP) {
      return false;
    }

    if(values.isMenor){
      valorSearch = values.CURP;
    }else{
      valorSearch = values.INE;
    }

    setLoadingIne(true);
    setFlagAccount(false);
    setDataUser({});
    events
      .validIne({ INE: valorSearch, idEvento: values.idEvento })
      .then((res) => {
        if (res.results) {
          setFlagAccount(true);
          console.log(botonRegistrar);
          if (botonRegistrar.current) {
            botonRegistrar.current.scrollIntoView({ behavior: 'smooth' });
          }
          setDataUser(res.response.data);
        } else {
          setFlagAccount(false);
          setDataUser();
        }
      })
      .catch((error) => {
        setFlagAccount(false);
        setDataUser();
        Swal.fire({ title: error.message, icon: "warning" });
      })
      .finally(() => setLoadingIne(false));
  };

  const onChange = (e) => {
    setFlagAccount(false);
    setDataUser({ message: "" });
    handleChange(e);
  };

  const onSwitch = (e) => {
    handleChange({ target: { name: "isMenor", value: e ? 1 : 0 } });
    if(e){
      handleChange({target:{name:"INE",value:""}});
    }{
      handleChange({target:{name:"CURP",value:""}});
    }
  };

  const handleRegister = (e) => {
    if (dataEvent.idModoEvento === 3 && dataPreguntas.length > 0 && datauser.pedir_cuestionario) {
      setFlagQueestion(true);
    }else{
        register();
    }
  };

  const handleForm = () => {
    if (dataEvent.idModoEvento === 3 && dataPreguntas.length > 0) {
      setFlagNot(true);
      setFlagQueestion(true);
    }else{
      setFlagNot(true);
      setFlagQueestion(false);
    }
  };

  return (
    <Box component="section" pt={6} my={6} sx={{ alignItems: "center" }}>
      <Container alignItems="center">
        <Grid container sx={{ alignItems: "center" }}>
          <Grid
            item
            md={12}
            sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}
          >
            <Typography
              variant="h5"
              color={"primary"}
              fontWeight="bold"
              sx={{ fontFamily: "initial" }}
            >{`${dataEvent.Actividad}`}</Typography>
            <Typography variant="body1" color="text" mb={3}>
              Para registrarte en el evento, por favor escribe tu clave de
              elector y da clic en el botón buscar
            </Typography>
            {dataEvent.idModoEvento === 3 && (
              <Stack
                direction="column"
                justifyContent="center"
                //alignItems="center"
                spacing={0.5}
              >
                <Typography variant="body2" color="#FE9A2E">
                  ¿Es Menor de Edad?
                </Typography>
                <Stack direction="row" spacing={0.5}>
                  <Typography variant="subtitle1" color="primary">
                    No
                  </Typography>
                  <Switch
                    color="primary"
                    onChange={(e) => onSwitch(e.target.checked)}
                    checked={values.isMenor === 1 ? true : false}
                  />
                  <Typography variant="subtitle1" color="primary">
                    Si
                  </Typography>
                </Stack>
              </Stack>
            )}
            <Grid container sx={{ mt: 2 }} spacing={0.5}>
              <Grid item xs={8} sm={8} md={8}>
                {values.isMenor ? (
                  <TextField
                    required
                    fullWidth
                    //sx={{ width: "50%" }}
                    error={!isEmptyOrInvalidString(errors.CURP)}
                    helperText={errors.CURP && errors.CURP}
                    value={values.CURP.toUpperCase()}
                    pattern="/[A-Z]{6}[0-9]{8}[A-Z]{1}[0-9]{3}/g"
                    type="text"
                    name="CURP"
                    onChange={onChange}
                    onBlur={(e) => onblurCurp(e.target.value)}
                    id="outlined-required"
                    label="CURP"
                    placeholder="CURP"
                  />
                ) : (
                  <TextField
                    required
                    fullWidth
                    //sx={{ width: "50%" }}
                    error={!isEmptyOrInvalidString(errors.INE)}
                    helperText={errors.INE && errors.INE}
                    value={values.INE.toUpperCase()}
                    pattern="/[A-Z]{6}[0-9]{8}[A-Z]{1}[0-9]{3}/g"
                    type="text"
                    name="INE"
                    onChange={onChange}
                    onBlur={(e) => onblurINE(e.target.value)}
                    id="outlined-required"
                    label="Clave de Elector"
                    placeholder="Clave Elector"
                  />
                )}
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  size="large"
                  sx={{ mt: 1 }}
                  onClick={(e) => searchINE()}
                  loading={loadingIne}
                  loadingPosition="start"
                  startIcon={<SearchIcon />}
                >
                  <span>Buscar</span>
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{mt:5}}>
          {flagAccount && (
            <Grid
              item
              xs={12}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {" "}
              <Typography variant="h6" sx={{ color: "#FE9A2E" }} align="center">
                HOLA {datauser.NombreCompleto}
              </Typography>
              <Typography align="center">
                {" "}
                DESEAS ASISTIR AL EVENTO?, DA CLICK AL BOTON{" "}
              </Typography>
              <Stack
                sx={{ pt: 1 }}
                direction="row"
                spacing={2}
                justifyContent="center"
              >
                <br />
                <LoadingButton
                  size="small"
                  color="primary"
                  onClick={handleRegister}
                  loading={loadingRegister}
                  loadingPosition="end"
                  endIcon={<AppRegistrationIcon />}
                  variant="contained"
                >
                  <span>Registrarme</span>
                </LoadingButton>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12}>
            {!flagAccount && !errors.INE && isNullOrUndefined(datauser) && (
              <Box align="center" sx={{ pt: 4, pb: 15 }}>
                <Typography sx={{ color: "#FE9A2E" }} align="center">
                  Esta CLAVE DE ELECTOR no está registrada.
                </Typography>
                <Typography sx={{ color: "#FE9A2E" }} align="center">
                  Para tener acceso al EVENTO por favor
                  <br />
                  <Button
                    variant="contained"
                    size="medium"
                    color={"primary"}
                    onClick={(e) => handleForm()}
                  >
                    {" "}
                    REGÍSTRATE AQUÍ.
                  </Button>
                  <br />
                  Es muy fácil.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default SearchPeople;
