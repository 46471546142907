export const style = (feature, flag) => {
    let style;
    if (flag === undefined || flag !== true) {
      if (feature.properties[feature.properties.shp]) {
        //L.fitBounds(feature.getBounds());
          style = {
            fillColor: feature.properties.color !== undefined? feature.properties.color: "#0066FF",
            weight: 2,
            opacity: 1,
            color: feature.properties.line_color !== undefined? feature.properties.line_color: "#fff", //'white',
            dashArray: "3",
            fillOpacity: 0.2,
          };
      } else {
        style = {
          fillColor: "white",
          weight: 2,
          opacity: 0.5,
          color: "#666", //'white',
          dashArray: "3",
          fillOpacity: 0.5,
        };
      }
    } else {
      style = {
        weight: 5,
        color: "#fff",//"#0000ff",
        dashArray: "",
        fillOpacity: 0.4,
      };
    }
    return style;
  };
  

  export const style_original = (feature, flag) => {
    let style;
    if (flag === undefined || flag !== true) {
      if (feature.properties[feature.properties.shp]) {
        //L.fitBounds(feature.getBounds());
        if(
          feature.properties.color !== undefined && feature.properties.color !== '#FFFFFF'
          /* feature.properties["Editable"] !== undefined && feature.properties["Editable"] ===1 */){
          
          style = {
            fillColor: feature.properties.color !== undefined? feature.properties.color: "#0066FF",
            weight: 2,
            color:  feature.properties.line_color !== undefined? feature.properties.line_color:"#fff", //"#0000ff",
            dashArray: "3",
            fillOpacity: 0.2,
          };
        }
        else{
          style = {
            fillColor: feature.properties.color !== undefined? feature.properties.color: "#0066FF",
            weight: 2,
            opacity: 1,
            color: feature.properties.line_color !== undefined? feature.properties.line_color: "#fff", //'white',
            dashArray: "2",
            fillOpacity: 0.2,
          };
        }
        
        
      } else {
        style = {
          fillColor: "white",
          weight: 2,
          opacity: 0.5,
          color: "#666", //'white',
          dashArray: "3",
          fillOpacity: 0.5,
        };
      }
    } else {
      style = {
        weight: 5,
        color: "#666", // "#fff",
        dashArray: "",
        fillOpacity: 0.3,
      };
    }
    return style;
  };

  export const style_original_av_struct = (feature, flag) => {
    let style;
    if (flag === undefined || flag !== true) {
      if (feature.properties[feature.properties.shp]) {
        //L.fitBounds(feature.getBounds());
        if(
          feature.properties.color_estructura !== undefined && feature.properties.color_estructura !== '#FFFFFF'
          /* feature.properties["Editable"] !== undefined && feature.properties["Editable"] ===1 */){
          
          style = {
            fillColor: feature.properties.color_estructura !== undefined? feature.properties.color_estructura: "#0066FF",
            weight: 2,
            color:  feature.properties.line_color !== undefined? feature.properties.line_color:"#fff", //"#0000ff",
            dashArray: "3",
            fillOpacity: 0.2,
          };
        }
        else{
          style = {
            fillColor: feature.properties.color_estructura !== undefined? feature.properties.color_estructura: "#0066FF",
            weight: 2,
            opacity: 1,
            color: feature.properties.line_color !== undefined? feature.properties.line_color: "#fff", //'white',
            dashArray: "2",
            fillOpacity: 0.2,
          };
        }
        
        
      } else {
        style = {
          fillColor: "white",
          weight: 2,
          opacity: 0.5,
          color: "#666", //'white',
          dashArray: "3",
          fillOpacity: 0.5,
        };
      }
    } else {
      style = {
        weight: 5,
        color: "#666", // "#fff",
        dashArray: "",
        fillOpacity: 0.3,
      };
    }
    return style;
  };
  export const style_original_data = (feature, flag) => {
    let style;
    if (flag === undefined || flag !== true) {
      if (feature.properties[feature.properties.shp]) {
        //L.fitBounds(feature.getBounds());
        if(
          feature.properties.color !== undefined && feature.properties.color !== '#FFFFFF'
          /* feature.properties["Editable"] !== undefined && feature.properties["Editable"] ===1 */){
          
          style = {
            fillColor: feature.properties.color !== undefined? feature.properties.color: "#0066FF",
            weight: 4,
            color:  feature.properties.line_color !== undefined? feature.properties.line_color:"#fff", //"#0000ff",
            dashArray: "1",
            fillOpacity: 0.2,
          };
        }
        else{
          style = {
            fillColor: feature.properties.color !== undefined? feature.properties.color: "#0066FF",
            weight: 2,
            opacity: 1,
            color: feature.properties.line_color !== undefined? feature.properties.line_color: "#fff", //'white',
            dashArray: "2",
            fillOpacity: 0.2,
          };
        }
        
        
      } else {
        style = {
          fillColor: "white",
          weight: 2,
          opacity: 0.5,
          color: "#666", //'white',
          dashArray: "3",
          fillOpacity: 0.5,
        };
      }
    } else {
      style = {
        weight: 6,
        color: "#666", // "#fff",
        dashArray: "",
        fillOpacity: 0.3,
      };
    }
    return style;
  };
  export const style_original_sub_data = (feature, flag) => {
    let style;
    if (flag === undefined || flag !== true) {
      if (feature.properties[feature.properties.shp]) {
        if(
          feature.properties.color !== undefined && feature.properties.color !== '#FFFFFF'){
          style = {
            fillColor: feature.properties.color !== undefined? feature.properties.color: "#0066FF",
            weight: 2,
            color:  feature.properties.line_color !== undefined? feature.properties.line_color :"#fff", //"#0000ff",
            dashArray: "3",
            fillOpacity: 0.2,
          };
        }
        else{
          style = {
            fillColor: feature.properties.color !== undefined? feature.properties.color: "#0066FF",
            weight: 2,
            opacity: 1,
            color: feature.properties.line_color !== undefined? feature.properties.line_color: "#fff", //'white',
            dashArray: "2",
            fillOpacity: 0.2,
          };
        }
        
        
      } else {
        style = {
          fillColor: "white",
          weight: 2,
          opacity: 0.5,
          color: "#666", //'white',
          dashArray: "3",
          fillOpacity: 0.5,
        };
      }
    } else {
      style = {
        weight: 5,
        color: "#666", // "#fff",
        dashArray: "",
        fillOpacity: 0.3,
      };
    }
    return style;
  };
  

  export const style_rutas = (feature, flag) => {
    let style;
    if (flag === undefined || flag !== true) {
      if (feature.properties[feature.properties.shp]) {
        //L.fitBounds(feature.getBounds());
          style = {
            fillColor: feature.properties.color !== undefined? feature.properties.color: "#0066FF",
            weight: 2,
            opacity: 1,
            color: feature.properties.line_color !== undefined? feature.properties.line_color: "#fff", //'white',
            dashArray: "3",
            fillOpacity: 0.2,
          };
      } else {
        style = {
          fillColor: "white",
          weight: 2,
          opacity: 0.5,
          color: "#666", //'white',
          dashArray: "3",
          fillOpacity: 0.5,
        };
      }
    } else {
      style = {
        weight: 5,
        color: "#fff",//"#0000ff",
        dashArray: "",
        fillOpacity: 0.4,
      };
    }
    return style;
  };
  
  
  export const style_original_rutas = (feature, flag) => {
    let style;
    
    if (flag === undefined || flag !== true) {
      
      if (feature.properties[feature.properties.shp]) {
        if(feature.properties["Editable"] !== undefined && feature.properties["Editable"] ===1){
          style = {
            fillColor: feature.properties.color !== undefined? feature.properties.color: "#0066FF",
            weight: 5,
            color: "#fff",//"#0000ff",
            dashArray: "",
            fillOpacity: 0.4,
          };
        }
        else{
          style = {
            fillColor: feature.properties.color !== undefined? feature.properties.color: "#0066FF",
            weight: 2,
            opacity: 1,
            color: feature.properties.line_color !== undefined? feature.properties.line_color: "#fff", //'white',
            dashArray: "3",
            fillOpacity: 0.2,
          };
        }
        
        
      } else {
        style = {
          fillColor: "white",
          weight: 2,
          opacity: 0.5,
          color: "#666", //'white',
          dashArray: "3",
          fillOpacity: 0.5,
        };
      }
    } else {
      style = {
        weight: 5,
        color: "#666", // "#fff",
        dashArray: "",
        fillOpacity: 0.3,
      };
    }
    return style;
  };
  

  