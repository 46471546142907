import Yup from "@utils/Yupi18n";
// import moment from "moment";
//import { VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL, VIGENCIA_INE } from "@data/constants"

//const pattern = /[A-Z|a-z]{6}[0-9]{8}[A-Z|a-z]{1}[0-9]{3}/g

const MapAddPolygonSchema = Yup.object({
  Description: Yup.string().optional().nullable(true).label("Descripción"),
  Sections: Yup.array().required(),
});

export {
  MapAddPolygonSchema
};
