import React from "react";
import { Box, Typography, Link, Stack, Grid, Divider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@src/theme/index";

import ErrorImg from "@assets/img/401.png";

const NotAuthorized = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box sx={{ flexGrow: 1, p: 3, mt: 20 }} alignItems="center">
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            sx={{ pt: 2, pb: 2 }}
          >
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <img
                src={ErrorImg}
                className="d-inline-block align-top wow fadeInLeft img-fluid"
                data-wow-delay="0.6s"
                alt="404"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Typography variant="h1" component="div" gutterBottom>
                USTED NO TIENE PERMISOS
              </Typography>
              <Typography variant="h1" component="div" gutterBottom>
                DE ACCESO
              </Typography>
              <Typography variant="h6" component="div" gutterBottom>
                CONTACTE AL ADMINISTRADOR
              </Typography>
              <br />
              <Typography variant="h4" component="div" gutterBottom>
                <Link href="/">IR AL INICIO</Link>
              </Typography>
            </Grid>
          </Stack>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default NotAuthorized;
