import ApiExec from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";

class FrenteAmplioServices {


  getList = (params) => {
    return ApiExec(params, "compromisos/get-my-records-fa", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  create = (params) => {
    return ApiExec(params, `compromisos/create-fa`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  update = (params) => {
    return ApiExec(params, "compromisos/update-fa", "PUT")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getFind = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `compromisos/find-my-record-fa/${_id}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResponsibles = (params) => {
    return ApiExec(params, "compromisos/get-responsiblesFA", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

}

const frente = new FrenteAmplioServices();
export default frente;
