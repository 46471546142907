import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircularProgress } from "@mui/material";

const PieChart = (props) => {
  const { series, options, loading, pieColors } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      chart: {
        type: "pie",
      },
      title: {
        text: options.title,
      },
      subtitle: {
        text: options.subtitle,
      },
     /*  credits: {
        enabled: false,
      }, */
      accessibility: {
     /*    announceNewData: {
          enabled: true,
        }, */
        point: {
          valueSuffix: "%",
        },
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          colors: pieColors,
          dataLabels: {
            enabled: true,
            format: "{point.percentage:.1f} %",
          /*   distance: -50,
            filter: {
              property: "percentage",
              operator: ">",
              value: 4,
            } */
          },
          showInLegend: true,
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}</b> del <b>{point.total}</b> total<br/>',
      },
      series: series,
    });

    // eslint-disable-next-line
  }, [series, options, loading]);

  return data !== null || loading ? (
    <HighchartsReact highcharts={Highcharts} options={data} />
  ) : (
    <CircularProgress />
  );
};

export default PieChart;
