import { useState, useEffect } from "react";

import {
  Box,
  Button,
  Icon,
  FormHelperText,
  TextField,
  InputAdornment,
  Divider,
  CircularProgress,
  Alert,
  AlertTitle,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import BasicSelect from "@components/Selects/BasicSelect";
import AutoComplete from "@components/Selects/Autocomplete";
import ModalUserResponsibleList from "@components/Users/ModalUserResponsibleList";
import { AddResponsibilityInterface } from "@interfaces/VolunteerInterfaces";
import { AddResponsibilitySchema } from "@schemas/VolunteerSchemas";
import CatalogService from "@services/CatalogServices";
import {
  isEmptyOrInvalidString,
  isValidSection,
  isTypePhone,
} from "@utils/validations";
import {
  CVE_ESTADO,
  AMBITO_ESTATAL,
  AMBITO_REGIONAL,
  AMBITO_DL,
  AMBITO_DF,
  AMBITO_MPAL,
  AMBITO_MPIO_DL,
  AMBITO_POLIGONAL,
  AMBITO_SECCIONAL,
  AMBITO_MZA,
  AMBITO_CASILLA,
  AMBITO_MODULO,
  PERFIL_RC,
  PERFIL_FiL,
  PERFIL_RG,
  PERFIL_CASA,
  PERFIL_OBSERVER,
  PERFIL_ABOGADO,
  idPropietario,
} from "@data/constants";
import { removeDefaultOption } from "@utils/Utilities";
import { getVars } from "@utils/global";
import middleware from "@middlewares/middleware";

const TerritorialForm = (props) => {
  const { volunteer, catResponsibilities, catalogs, onSubmit, assigned, type } =
    props;
  const { user } = getVars("Token");
  const [scopeLabel, setScopeLabel] = useState("");
  const [catScope, setCatScope] = useState([]);

  const formik = useFormik({
    initialValues: {
      ...AddResponsibilityInterface,
      idUsurioAutorizo: user.id,
      idCompromisoUnico: volunteer.id,
    },
    validationSchema: AddResponsibilitySchema,
    onSubmit: (values) => {
      if (catalogs.municipios_dl.length === 2) {
        values = {
          ...values,
          idMunicipio: catalogs.municipios_dl[1].value,
        };
      }
      onSubmit(values);
    },
  });
  const [sectionValid, setSectionValid] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [statusValidated, setStatusValidated] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loadingBlocks, setLoadingBlocks] = useState(false);
  const [sectionValue, setSectionValue] = useState("");
  const [sectionData, setSectionData] = useState({
    value: "",
    label: "",
    idPoligono: "",
    idDF: "",
    idDL: "",
    idRegion: "",
    idMunicipio: "",
    idMunicipioReportes: "",
    Municipio: "",
    Poligono: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [userOwner, setUserOwner] = useState(() => {
    return {
      value: user.id,
      label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
      TieneUsuario: 1,
    };
  });
  const [propietario] = useState(
    catalogs.calidad_representante
      ? catalogs.calidad_representante.filter((item) => item.value !== 0)
      : []
  );

  useEffect(() => {
    setUserOwner({
      value: user.id,
      label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
      TieneUsuario: 1,
    });
    formik.handleChange("idUsurioAutorizo", user.id);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleClearForm();
    //eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    if (assigned.length > 0) {
      handleClearForm();
    }
    //eslint-disable-next-line
  }, [assigned]);

  /*   useEffect(() => {
    if (!isEmptyOrNullObject(userOwner)) {
      formik.setFieldValue('idUsurioAutorizo', userOwner.idUsuario);
      if (userOwner.idUsuario !== user.id) {
        //ACTUALIZAR CATÁLOGOS
      }
    }
    // eslint-disable-next-line
  }, [userOwner]); */

  useEffect(() => {
    formik.setFieldValue("idCompromisoUnico", volunteer.id);
    //eslint-disable-next-line
  }, [volunteer]);

  const handleChangeResponsibility = (value) => {
    formik.handleChange({ target: { name: "idMunicipio", value: "" } });
    let responsabilitiAmbito = value.split("-");
    let ambito = parseInt(responsabilitiAmbito[1]);
    let perfil = parseInt(responsabilitiAmbito[0]);

    let newValues = {
      ...formik.values,
      idPefilAmbito: value,
      idPerfil: perfil,
      idAmbitoCargo: ambito,
      AmbitoValorCargo: "",
    };

    setSectionValue("");
    setBtnDisabled(false);

    switch (ambito) {
      case AMBITO_ESTATAL:
        setScopeLabel("");
        let textPerfil = catResponsibilities.find(
          (item) => item.id === newValues.idPerfil
        );
        newValues = {
          ...newValues,
          ResponsabilidadEtiqueta: `${textPerfil.label.toUpperCase()}`,
          idAmbitoCargo: AMBITO_ESTATAL,
          AmbitoValorCargo: CVE_ESTADO,
        };
        setCatScope([]);
        break;
      case AMBITO_REGIONAL:
        setScopeLabel("Región");
        newValues = {
          ...newValues,
          idAmbitoCargo: AMBITO_REGIONAL,
        };
        setCatScope(removeDefaultOption(catalogs.regiones));
        break;
      case AMBITO_DL:
        setScopeLabel("Distrito Local");
        newValues = {
          ...newValues,
          idAmbitoCargo: AMBITO_DL,
        };
        setCatScope(removeDefaultOption(catalogs.dl));
        break;
      case AMBITO_DF:
        setScopeLabel("Distrito Federal");
        setStatusValidated(true);
        newValues = {
          ...newValues,
          idAmbitoCargo: AMBITO_DF,
        };
        setCatScope(removeDefaultOption(catalogs.df));
        break;
      case AMBITO_MPAL:
        setScopeLabel("Municipio");
        newValues = {
          ...newValues,
          idAmbitoCargo: AMBITO_MPAL,
        };
        setCatScope(removeDefaultOption(catalogs.municipios));
        break;
      case AMBITO_MPIO_DL:
        setScopeLabel("Municipio");
        newValues = {
          ...newValues,
          idAmbitoCargo: AMBITO_MPIO_DL,
        };
        setCatScope(removeDefaultOption(catalogs.municipios_dl));
        break;
      case AMBITO_POLIGONAL:
        setScopeLabel("Polígono");
        newValues = {
          ...newValues,
          idAmbitoCargo: AMBITO_POLIGONAL,
        };
        setCatScope(removeDefaultOption(catalogs.poligonos));
        break;
      case AMBITO_SECCIONAL:
        setScopeLabel("Sección");
        newValues = {
          ...newValues,
          idAmbitoCargo: AMBITO_SECCIONAL,
        };
        setCatScope(removeDefaultOption(catalogs.secciones));
        if (perfil && [PERFIL_RC, PERFIL_FiL].includes(perfil)) {
          setBtnDisabled(true);
          setStatusValidated(false);
          setSectionValid(false);
        }
        break;
      case AMBITO_MZA:
        setScopeLabel("Manzana");
        newValues = {
          ...newValues,
          idAmbitoCargo: AMBITO_MZA,
        };
        setBtnDisabled(true);
        setStatusValidated(false);
        setSectionValid(false);
        break;
      case AMBITO_CASILLA:
        setScopeLabel("Casilla");
        newValues = {
          ...newValues,
          idAmbitoCargo: AMBITO_CASILLA,
        };
        setBtnDisabled(true);
        setStatusValidated(false);
        setSectionValid(false);
        break;
        case AMBITO_MODULO:
          setScopeLabel("Módulo");
          newValues = {
            ...newValues,
            idAmbitoCargo: AMBITO_MODULO,
          };
          setCatScope(removeDefaultOption(catalogs.modulos));
          break;
      default:
        setScopeLabel("");
        setCatScope([]);
        break;
    }

    formik.setValues(newValues);
    setLoadingStatus(false);
  };

  const handleChangeScope = (value) => {
    let newValues = {
      ...formik.values,
      AmbitoValorCargo: parseInt(value),
      ResponsabilidadEtiqueta: "",
    };
    let scope = {};
    let textPerfil = catResponsibilities.find(
      (item) => item.id === newValues.idPerfil
    );
    switch (newValues.idAmbitoCargo) {
      case AMBITO_REGIONAL:
        formik.setValues({
          ...newValues,
          ResponsabilidadEtiqueta: `${textPerfil.label.toUpperCase()} - R${value}`,
        });
        break;
      case AMBITO_DF:
        formik.setValues({
          ...newValues,
          ResponsabilidadEtiqueta: `${textPerfil.label.toUpperCase()} - DF${value}`,
        });
        break;
      case AMBITO_DL:
        formik.setValues({
          ...newValues,
          ResponsabilidadEtiqueta: `${textPerfil.label.toUpperCase()} - DL${value}`,
        });
        break;
      case AMBITO_MPAL:
        scope = catalogs.municipios.find((item) => item.value === value);
        formik.setValues({
          ...newValues,
          ResponsabilidadEtiqueta: `${textPerfil.label.toUpperCase()} - ${
            scope.label
          }`,
        });
        break;
      case AMBITO_MPIO_DL:
        scope = catalogs.municipios_dl.find((item) => item.value === value);
        formik.setValues({
          ...newValues,
          ResponsabilidadEtiqueta: `${textPerfil.label.toUpperCase()} - ${
            scope.label
          }`,
        });
        break;
      case AMBITO_POLIGONAL:
        scope = catalogs.poligonos.find((item) => item.value === value);
        formik.setValues({
          ...newValues,
          ResponsabilidadEtiqueta: `${textPerfil.label.toUpperCase()} - ${
            scope.label
          }`,
        });
        break;
      case AMBITO_SECCIONAL:
        scope = catalogs.secciones.find(
          (item) => item.value === parseInt(value)
        );
        formik.setValues({
          ...newValues,
          ResponsabilidadEtiqueta: `${textPerfil.label.toUpperCase()} - ${
            scope.label
          }`,
        });
        break;
      case AMBITO_MZA:
        scope = catScope.find((item) => item.value === value);
        formik.setValues({
          ...newValues,
          ResponsabilidadEtiqueta: `${textPerfil.label.toUpperCase()} - ${
            scope.label
          }`,
          Prioridad: scope.Prioridad,
        });
        break;
      case AMBITO_CASILLA:
        scope = catScope.find((item) => item.value === value);
        formik.setValues({
          ...newValues,
          ResponsabilidadEtiqueta: `${textPerfil.label.toUpperCase()} - ${
            scope.label
          }`,
          Prioridad: scope.Prioridad,
        });
        break;
        case AMBITO_MODULO:
          scope = catalogs.modulos.find((item) => item.value === value);
          formik.setValues({
            ...newValues,
            ResponsabilidadEtiqueta: `${textPerfil.label.toUpperCase()} - ${
              scope.label
            }`,
          });
          break;
      default:
        formik.setValues(newValues);
        break;
    }
  };

  const handleChangeRadio = (e) => {
    const name = e.target.name;
    const value = parseInt(e.target.value);
    formik.handleChange({
      target: {
        name: name,
        value: value,
      },
    });
  };

  const handleChangeMpio = (value) => {
    setCatScope(
      removeDefaultOption(
        catalogs.poligonos.filter((item) => item.idMunicipio === value)
      )
    );
  };

  const loadCatalogsByMunicipio = (e) => {
    let idMunicipioReportes = e.target.value;
    formik.setFieldValue(e.target.name, idMunicipioReportes);

    let catalogsParams = [
      {
        id: "df",
        filtered: [
          {
            id: "idMunicipioReportes",
            filter: "=",
            value: idMunicipioReportes,
          },
        ],
      },
    ];

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          formik.setFieldValue("AmbitoValorCargo", "");

          setCatScope(removeDefaultOption(res.response.catalogs.df));
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      validateSection(sectionValue);
    }
  };

  const validateSection = (value) => {
    const validationPromise = new Promise((resolve, reject) => {
      let section = catalogs.secciones.find(
        (item) => item.value === parseInt(value)
      );
      if (!section) {
        reject({
          success: false,
          message: "Sección inválida",
        });
      } else {
        let idMun = catalogs.municipios_dl.find(
          (item) => item.value === section.idMunicipioReportes
        ).value;
        formik.handleChange({ target: { name: "idMunicipio", value: idMun } });
        const data = {
          ...section,
          Municipio: catalogs.municipios.find(
            (item) => item.value === section.idMunicipio
          ).label,
          Poligono: catalogs.poligonos.find(
            (item) => item.value === section.idPoligono
          ).label,
        };

        resolve({
          success: true,
          data,
        });
      }
    });

    setStatusValidated(false);
    setLoadingStatus(true);
    setBtnDisabled(true);

    validationPromise
      .then((res) => {
        setBtnDisabled(false);
        setSectionValid(true);
        setSectionData(res.data);
        if (formik.values.idAmbitoCargo === AMBITO_MZA) {
          loadUrbanBlocks(value);
        }
        if (formik.values.idAmbitoCargo === AMBITO_CASILLA) {
          loadCasillas(value);
        }
      })
      .catch((error) => {
        setBtnDisabled(true);
        setSectionValid(false);
      })
      .finally(() => {
        setStatusValidated(true);
        setLoadingStatus(false);
      });
  };

  const loadUrbanBlocks = (section) => {
    setLoadingBlocks(true);
    const catalogsParams = [
      {
        id: "manzanas",
        filtered: [
          {
            id: "cat_manzanas.Seccion",
            filter: "=",
            value: section,
          },
        ],
      },
    ];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          setCatScope(
            res.response.catalogs.manzanas ? res.response.catalogs.manzanas : []
          );
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
        });
      })
      .finally(() => setLoadingBlocks(false));
  };

  const loadCasillas = (section) => {
    setLoadingBlocks(true);
    const catalogsParams = [
      {
        id: "casillas",
        filtered: [
          {
            id: "cat_casillas.Seccion",
            filter: "=",
            value: section,
          },
        ],
      },
    ];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          setCatScope(
            res.response.catalogs.casillas ? res.response.catalogs.casillas : []
          );
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
        });
      })
      .finally(() => setLoadingBlocks(false));
  };

  const handleClearForm = () => {
    formik.setValues({
      idUsurioAutorizo: userOwner.value,
      idCompromisoUnico: volunteer.id,
      idPerfil: "",
      idAmbitoCargo: "",
      AmbitoValorCargo: "",
      ResponsabilidadEtiqueta: "",
      Prioridad: "",
      idPropietario: "",
    });
  };

  const resetUserOwner = () => {
    formik.setValues({
      ...formik.values,
      idUsurioAutorizo: user.id,
    });

    setUserOwner({
      value: user.id,
      label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
      TieneUsuario: 1,
    });
  };

  const SetOwner = (item) => {
    formik.setValues({
      ...formik.values,
      idUsurioAutorizo: item.value,
    });

    setUserOwner({
      value: item.value,
      label: item.label,
      TieneUsuario: item.TieneUsuario,
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
          <Chip
            icon={<Icon>groups2</Icon>}
            label={`${
              middleware.checkMenuAction("Capturista") ? "Seleccione al" : ""
            } Responsable de asignar la responsabilidad`}
          />
        </Grid2>
      </Grid2>

      <Grid2 container spacing={1}>
        <Grid2 xs={12} sm={12} md={6} lg={6}>
          <TextField
            required={true}
            disabled={true}
            label="Nombre del Responsable del Voluntario"
            value={userOwner.label}
            name="Nombre"
            sx={{ width: "100%" }}
            type="text"
            variant="outlined"
            size="small"
            className="fixed-input"
            InputProps={
              middleware.checkMenuAction("Capturista") && {
                startAdornment: (
                  <InputAdornment position="start" sx={{ pl: 0, pr: 0 }}>
                    <Button
                      color="warning"
                      onClick={(e) => resetUserOwner()}
                      sx={{ ml: 0, pl: 0 }}
                    >
                      <Icon>restore</Icon>
                    </Button>
                    <Divider sx={{ height: 28 }} orientation="vertical" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <Button
                      color="info"
                      onClick={(e) => setOpenModal(true)}
                      sx={{ mr: 0, pr: 0 }}
                    >
                      <Icon>search</Icon>
                      <small>Buscar</small>
                    </Button>
                  </InputAdornment>
                ),
              }
            }
          />
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2}>
        <Grid2 item xs={12} sm={12} md={6} lg={6}>
          <BasicSelect
            error={
              formik.touched.idPerfil &&
              !isEmptyOrInvalidString(formik.errors.idPerfil)
            }
            label={`Función ${type} a desempeñar`}
            name="idPerfil"
            value={formik.values.idPefilAmbito}
            options={catResponsibilities}
            onChange={(e) => handleChangeResponsibility(e.target.value)}
            //onBlur={(e) => handleChangeResponsibility(e.target.value)}
            size="small"
            sx={{ width: "100%" }}
            errorMessage={formik.errors.idPerfil}
          />
        </Grid2>
      </Grid2>
      {formik.values.idAmbitoCargo &&
        formik.values.idAmbitoCargo !== AMBITO_ESTATAL && (
          <Grid2 container spacing={2}>
            {formik.values.idAmbitoCargo &&
              formik.values.idAmbitoCargo === AMBITO_POLIGONAL && (
                <Grid2 item xs={12} sm={12} md={6} lg={6}>
                  <BasicSelect
                    label={"Municipio"}
                    options={removeDefaultOption(catalogs.municipios)}
                    onChange={(e) => handleChangeMpio(e.target.value)}
                    size="small"
                    sx={{ width: "100%" }}
                  />
                </Grid2>
              )}
            {formik.values.idPerfil &&
              [PERFIL_RG, PERFIL_OBSERVER, PERFIL_CASA].includes(
                formik.values.idPerfil
              ) &&
              [AMBITO_DF].includes(formik.values.idAmbitoCargo) &&
              catalogs.municipios_dl.length !== 2 && (
                <Grid2 xs={12} sm={12} md={6} lg={6}>
                  <BasicSelect
                    label={"Municipio"}
                    name="idMunicipio"
                    options={
                      catalogs.municipios_dl.length === 2
                        ? removeDefaultOption(catalogs.municipios_dl)
                        : removeDefaultOption(catalogs.municipios_dl)
                    }
                    onChange={loadCatalogsByMunicipio}
                    size="small"
                    sx={{ width: "100%" }}
                  />
                </Grid2>
              )}
            {formik.values.idAmbitoCargo &&
              ![AMBITO_MZA, AMBITO_CASILLA].includes(
                formik.values.idAmbitoCargo
              ) && (
                <Grid2 item xs={12} sm={12} md={6} lg={6}>
                  <BasicSelect
                    label={scopeLabel}
                    options={catScope}
                    name="AmbitoValorCargo"
                    value={formik.values.AmbitoValorCargo}
                    onChange={(e) => handleChangeScope(e.target.value)}
                    onBlur={formik.handleBlur}
                    size="small"
                    sx={{ width: "100%" }}
                  />
                  {/* <AutoComplete
                    label={scopeLabel}
                    name="AmbitoValorCargo"
                    data={removeDefaultOption(catScope)}
                    setSelect={handleChangeScope}
                    size="small"
                    sx={{ width: "100%" }}
                    value={formik.values.AmbitoValorCargo}
                  /> */}
                  <FormHelperText error>
                    {formik.errors.AmbitoValorCargo &&
                      formik.errors.AmbitoValorCargo.replace(
                        "AmbitoValorCargo",
                        scopeLabel
                      )}
                  </FormHelperText>
                </Grid2>
              )}
            {formik.values.idPerfil &&
              [PERFIL_RC, PERFIL_FiL].includes(formik.values.idPerfil) &&
              formik.values.idAmbitoCargo &&
              [AMBITO_SECCIONAL, AMBITO_MZA, AMBITO_CASILLA].includes(
                formik.values.idAmbitoCargo
              ) && (
                <>
                  <Grid2 item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      required
                      label="Sección"
                      type={"text"}
                      name="sectionValue"
                      size="small"
                      color="info"
                      className="fixed-input"
                      placeholder="Validar Sección"
                      value={sectionValue}
                      onChange={(e) => {
                        setSectionValue(
                          isValidSection(e.target.value)
                            ? e.target.value
                            : sectionValue
                        );
                        setBtnDisabled(true);
                        setStatusValidated(false);
                        setSectionValid(false);
                        if (
                          [AMBITO_MZA, AMBITO_CASILLA].includes(
                            formik.values.idAmbitoCargo
                          )
                        ) {
                          handleChangeScope(
                            isValidSection(e.target.value)
                              ? e.target.value
                              : formik.values.AmbitoValorCargo
                          );
                        }
                        if (
                          [AMBITO_MZA, AMBITO_CASILLA].includes(
                            formik.values.idAmbitoCargo
                          )
                        ) {
                          formik.setValues({
                            ...formik.values,
                            AmbitoValorCargo: "",
                            ResponsabilidadEtiqueta: "",
                            Prioridad: "",
                          });
                        }
                      }}
                      onKeyUp={handleOnKeyPress}
                      InputProps={{
                        maxLength: 4,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider
                              sx={{ height: 28, m: 0.5 }}
                              orientation="vertical"
                            />
                            <Button
                              color="warning"
                              onClick={(e) => {
                                e.preventDefault();
                                validateSection(sectionValue);
                              }}
                            >
                              <Icon>search</Icon>
                              Validar
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                      error={!sectionValid}
                    />
                    <FormHelperText error>
                      {!sectionValid && "Ingrese una sección válida"}
                    </FormHelperText>
                    <br />
                    <BasicSelect
                      label={scopeLabel}
                      options={catScope}
                      name="AmbitoValorCargo"
                      value={formik.values.AmbitoValorCargo}
                      onChange={(e) => handleChangeScope(e.target.value)}
                      onBlur={formik.handleBlur}
                      size="small"
                      sx={{ width: "100%" }}
                      isLoading={loadingBlocks}
                    />
                    <FormHelperText error>
                      {formik.errors.AmbitoValorCargo &&
                        formik.errors.AmbitoValorCargo.replace(
                          "AmbitoValorCargo",
                          scopeLabel
                        )}
                    </FormHelperText>
                  </Grid2>
                  {loadingStatus ? (
                    <CircularProgress color="info" />
                  ) : (
                    statusValidated && (
                      <Grid2 item xs={12} sm={12} md={6} lg={6}>
                        <Alert severity={sectionValid ? "success" : "error"}>
                          {sectionValid ? (
                            <>
                              <AlertTitle>Sección válida</AlertTitle>
                              Municipio:{" "}
                              <strong>{sectionData.Municipio}</strong> <br />
                              Polígono: <strong>{sectionData.Poligono}</strong>
                            </>
                          ) : (
                            <AlertTitle>Sección inválida</AlertTitle>
                          )}
                        </Alert>
                      </Grid2>
                    )
                  )}
                  {/* ------ */}
                </>
              )}
          </Grid2>
        )}
      {
        /* formik.values.idAmbitoCargo &&
        [AMBITO_SECCIONAL, AMBITO_MZA, AMBITO_CASILLA, AMBITO_DF,].includes(
          formik.values.idAmbitoCargo
        ) && */ <Grid2 container spacing={2}>
          {formik.values.idPerfil &&
            [PERFIL_RC, PERFIL_RG, PERFIL_OBSERVER, PERFIL_FiL].includes(
              formik.values.idPerfil
            ) && (
              <Grid2 xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={
                    formik.touched.VigenciaINE &&
                    !isEmptyOrInvalidString(formik.errors.VigenciaINE)
                  }
                  helperText={
                    formik.touched.VigenciaINE &&
                    formik.errors.VigenciaINE &&
                    formik.errors.VigenciaINE
                  }
                  sx={{ width: "100%" }}
                  label="Vigencia de INE"
                  type="number"
                  name="VigenciaINE"
                  inputProps={{
                    maxLength: 4,
                  }}
                  onChange={(e) =>
                    formik.handleChange({
                      target: {
                        name: "VigenciaINE",
                        value: isTypePhone(e.target.value)
                          ? e.target.value
                          : formik.values.VigenciaINE,
                      },
                    })
                  }
                  onBlur={(e) =>
                    formik.handleChange({
                      target: {
                        name: "VigenciaINE",
                        value: isTypePhone(e.target.value)
                          ? e.target.value
                          : formik.values.VigenciaINE,
                      },
                    })
                  }
                  value={formik.values.VigenciaINE}
                  variant="outlined"
                  size="small"
                  className="fixed-input"
                />
              </Grid2>
            )}
          {formik.values.idPerfil &&
            idPropietario.includes(formik.values.idPerfil) && (
              <Grid2 item xs={12} sm={12} md={6} lg={6}>
                <BasicSelect
                  label={"Propietario"}
                  options={propietario}
                  name="idPropietario"
                  value={formik.values.idPropietario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  size="small"
                  sx={{ width: "100%" }}
                  isLoading={loadingBlocks}
                />
                <FormHelperText error>
                  {formik.errors.idPropietario && formik.errors.idPropietario}
                </FormHelperText>
              </Grid2>
            )}
          {loadingStatus ? (
            <CircularProgress color="info" />
          ) : (
            true && (
              <>
                {formik.values.idPerfil &&
                  [
                    PERFIL_RC,
                    PERFIL_RG,
                    PERFIL_OBSERVER,
                    PERFIL_FiL,
                    PERFIL_ABOGADO,
                  ].includes(formik.values.idPerfil) && (
                    <>
                      <Grid2 item xs={12} sm={12} md={3} lg={3}>
                        <FormControl>
                          <FormLabel id="TieneAuto">¿Tiene Auto?</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="TieneAuto"
                            name="TieneAuto"
                            value={formik.values.TieneAuto}
                            onChange={handleChangeRadio}
                          >
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="NO"
                            />
                          </RadioGroup>
                          {formik.errors.TieneAuto && (
                            <FormHelperText error>
                              {formik.errors.TieneAuto}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid2>
                      <Grid2 item xs={12} sm={12} md={3} lg={3}>
                        <FormControl>
                          <FormLabel id="TieneExperiencia">
                            ¿Tiene Experiencia?
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="TieneExperiencia"
                            name="TieneExperiencia"
                            value={formik.values.TieneExperiencia}
                            onChange={handleChangeRadio}
                          >
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="NO"
                            />
                          </RadioGroup>
                          {formik.errors.TieneExperiencia && (
                            <FormHelperText error>
                              {formik.errors.TieneExperiencia}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid2>
                      {formik.values.idPerfil &&
                        [PERFIL_RC, PERFIL_FiL].includes(
                          formik.values.idPerfil
                        ) && (
                          <Grid2 item xs={12} sm={12} md={3} lg={3}>
                            <FormControl>
                              <FormLabel id="AceptaCuidarOtra">
                                ¿Aceptaría cuidar otra casilla?
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="AceptaCuidarOtra"
                                name="AceptaCuidarOtra"
                                value={formik.values.AceptaCuidarOtra}
                                onChange={handleChangeRadio}
                              >
                                <FormControlLabel
                                  value={1}
                                  control={<Radio />}
                                  label="SI"
                                />
                                <FormControlLabel
                                  value={0}
                                  control={<Radio />}
                                  label="NO"
                                />
                              </RadioGroup>
                              {formik.errors.AceptaCuidarOtra && (
                                <FormHelperText error>
                                  {formik.errors.AceptaCuidarOtra}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid2>
                        )}
                    </>
                  )}
              </>
            )
          )}
        </Grid2>
      }

      <Grid2 xs={12} sm={12} md={12} lg={12}>
        <Box sx={{ width: "100%", display: "flex" }}>
          <Button
            type="button"
            color="error"
            variant="contained"
            size="small"
            onClick={handleClearForm}
            startIcon={<Icon>cancel</Icon>}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            color="primaryDark"
            variant="contained"
            size="small"
            onClick={formik.submitForm}
            startIcon={<Icon>save</Icon>}
            disabled={btnDisabled}
          >
            Guardar
          </Button>
        </Box>
      </Grid2>

      <ModalUserResponsibleList
        openModal={openModal}
        setOpenModal={setOpenModal}
        setResponsible={(e) => SetOwner(e)}
      />
    </Box>
  );
};

TerritorialForm.propTypes = {
  volunteer: PropTypes.object.isRequired,
  catResponsibilities: PropTypes.array.isRequired,
  catalogs: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

TerritorialForm.defaultProps = {
  catResponsibilities: [],
};

export default TerritorialForm;
