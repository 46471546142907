import React, { useState } from "react";
import { Container, Button, Icon, Grid, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { isEmptyOrInvalidString } from "@utils/validations";
import BasicSelect from "@components/Selects/BasicSelect";
import { UserStatusInterface } from "@data/interfaces/UserInterfaces";
import { UserStatusSchema } from "@data/schemas/UserSchemas";
import UserService from "@services/UserServices";
import { getVars } from "@utils/global";

const UserStatus = props => {
  const { idUsuario } = props;
  const [access_token] = useState(() => {
    let t = getVars("Token");
    if (t !== {})
      return t.access_token;
    return "";
  });
  const formik = useFormik({
    initialValues: UserStatusInterface,
    validationSchema: UserStatusSchema,
    onSubmit: values => {
      values.id = idUsuario;
      handleSave(values);
    }
  });

  const catalogs = [
    {
      value: 2,
      label: "Activar"
    },
    // {
    //   value: 3,
    //   label: "Suspender"
    // },
    {
      value: 4,
      label: "Bloquear"
    }
  ];

  const handleSave = data => {
    UserService.updateStatus(data, access_token)
      .then(res => {
        if (res.results) {
          Swal.fire({
            title: res.message,
            icon: "success"
          }).then(res => {
            if (res) {
            }
          });
        } else {
          Swal.fire({
            title: res.message,
            icon: "warning"
          });
        }
      })
      .catch(e => {
        Swal.fire({
          title: "Ha ocurrido un error",
          text: e.message,
          icon: "warning"
        });
      });
  };

  const handleCleanForm = e => {
    formik.setValues(UserStatusInterface);
  };

  return (
    <Container sx={{ ml: 4, mb: 4 }}>
      <Grid item xs={12} sm={12} sx={{ mr: 4 }}>
        <BasicSelect
          error={
            formik.touched.idStatus &&
            !isEmptyOrInvalidString(formik.errors.idStatus)
          }
          label="Acción"
          errorMessage={formik.errors.idStatus}
          name="idStatus"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.idStatus}
          options={catalogs}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} sx={{ mr: 4, mt: 4 }}>
        <TextField
          error={
            formik.touched.Motivo &&
            !isEmptyOrInvalidString(formik.errors.Motivo)
          }
          label="Motivo"
          helperText={
            formik.touched.Motivo &&
            formik.errors.Motivo &&
            formik.errors.Motivo
          }
          type="text"
          multiline
          rows={4}
          name="Motivo"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.Motivo}
          //   variant="standard"
          className="fixed-input"
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: "5vh", mr: 4, textAlign: "end" }}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCleanForm}
          startIcon={<Icon>cancel</Icon>}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={formik.submitForm}
          startIcon={<Icon>save</Icon>}
        >
          Guardar
        </Button>
      </Grid>
    </Container>
  );
};

export default UserStatus;
