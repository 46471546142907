import { useState, useEffect, useCallback, useRef } from "react";
//MUI
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

import QrReader from "react-camera-qr";
import { useParams } from "react-router-dom";
import ActionHeader from "@components/Containers/ActionHeader";
import events from "@services/EventsServices";
import Swal from "sweetalert2";
import { grey } from "@mui/material/colors";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Filter from "@components/Events/PaseListaInfoFilter";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";

import ModalQR from "@components/Events/ModalQR";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
const EventosPaseListaInfo = (navigator) => {
  //Constants

  const { id } = useParams();
  const [openModal1, setOpenModal1] = useState(false);
  const [startScan, setStartScan] = useState(false);
  const [loading, setLoading] = useState(true);
  const [facingMode, setFacinMode] = useState("front");
  const [loadingPaseLista, setLoadingPaseLista] = useState(false);
  const [dataItem, setDataItem] = useState(null);
  const view = "table";
  const [data, setData] = useState({
    page: 0,
    pageSize: 20,
    filtered: [
      {
        id: "eventos.id",
        value: id,
        filter: "=",
      },
    ],
    sorted: [{ id: "eventos.FechaInicio", value: "asc" }],
  });
  const [ultimoScann, setUltimoScann] = useState({
    code: "",
    data: { Materno: "", Nombre: "", Paterno: "", UUID: "", id: null },
  });
  const [dataListEvents, setDataListEvents] = useState({
    Actividad: "",
    Descripcion: "",
    FechaFin: "",
    FechaFinPreregistro: "",
    FechaInicio: "",
    HoraInicio: "",
    Modalidad: "",
    Municipio: "",
    TienePreregistro: null,
    TipoActividad: "",
    UUID: "",
    id: null,
    idCategoria: null,
    idEstatus: null,
    idTipoActividad: null,
    imgURL: "",
    isPrivado: null,
    textoPreregistro: "",
  });
  const [eventoStats, setEventoStats] = useState({
    id: null,
    UUID: null,
    Invitados: 1,
    Asistieron: 0,
  });
  const [deviceId, setDeviceId] = useState(null);

  var misLogs = "";

  const qrReaderRef = useRef(null);

  useEffect(() => {
    console.log("=>QR: ", eventoStats);
  }, [eventoStats]);

  useEffect(() => {
    const getCameraDeviceId = async () => {
      try {
        misLogs = "checar devices" + "\n" + misLogs;
        const devices = await navigator.mediaDevices.enumerateDevices();
        misLogs = "devices" + devices + "\n" + misLogs;
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        const rearCamera = videoDevices.find((device) =>
          device.label.toLowerCase().includes("back")
        );

        console.log("=>rearCamera: ", rearCamera);
        setDeviceId(rearCamera?.deviceId || videoDevices[0]?.deviceId || null);
      } catch (error) {
        console.error("Error al obtener los dispositivos de la cámara:", error);
      }
    };

    getCameraDeviceId();
  }, []);

  const getEventosData = useCallback((data) => {
    setLoading(true);
    events
      .getEventsList(data)
      .then((res) => {
        if (res.results) {
          if (res.response.data) {
            if (res.response.data.length > 0) {
              console.log("=>:res.response.data[0]", res.response.data[0]);
              setDataListEvents(res.response.data[0]);
            }
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const getStatsEventosData = useCallback((data) => {
    setLoading(true);
    events
      .statsAsisenciaXochitl({})
      .then((res) => {
        // console.log("=>stats.data", res);
        if (res.results) {
          if (res.response.data) {
            // console.log("=>:res.response.data", res.response.data);
            setEventoStats(res.response.data);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getEventosData(data);
    getStatsEventosData();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (dataItem !== null) {
      console.log("=>dataItem: ", dataItem);
      setPaseLista(dataItem);
    }
  }, [dataItem]);

  const setPaseLista = (item) => {
    setLoadingPaseLista(true);
    const data = {
      data: item,
      idEvento: dataListEvents.id,
    };

    console.log("=>setPaseListaXG: ", JSON.stringify(data));
    events
      .setEventsAsisenciaXochitl(data)
      .then((res) => {
        console.log("=>res: ", res);
        if (res.results) {
          if (res.response.data) {
            setUltimoScann(res.response);
            Swal.fire({ title: res.message, icon: "success" });
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoadingPaseLista(false));
  };

  const handleChangeFilter = (filtered) => {
    setData((prevState) => ({ ...prevState, filtered }));
  };

  const handleScan = (itemQR) => {
    if (itemQR !== null) {
      console.log("=>itemQR: ", itemQR);
      if (itemQR !== dataItem) {
        if (itemQR.length === 36) {
          setDataItem(itemQR);
          //setStartScan(false);
        }
      } else {
        console.log("=>Es el mismo QR: ", itemQR);
      }
    }
  };

  const handleError = (error) => {
    console.log("=>Error: ", error);
  };

  const handleQR = () => {
    setStartScan(!startScan);
  };

  const handleCloseModal = () => {
    setDataItem(null);
    setOpenModal1(false);
  };

  return (
    <AdminLayout>
      <Container maxWidth={false} sx={{ backgroundColor: grey }}>
        <ActionHeader title="Confirmación de asistencia" />
        {
          {
            table: (
              <Box>
                <Grid2 xs={6} sm={6} md={6} lg={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h2" fontWeight={700}>
                        {`${eventoStats.Asistieron} de ${eventoStats.Invitados}`}
                      </Typography>
                      <Typography variant="h5" fontWeight={700}>
                        {`${(
                          100 *
                          (eventoStats.Asistieron / eventoStats.Invitados)
                        ).toFixed(2)}%`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid2>
                <h3> {dataListEvents.Actividad}</h3>

                {!loading ? (
                  <Filter
                    onChangeFilter={(e) => handleChangeFilter(e)}
                    onGetQR={(e) => handleQR(e)}
                  />
                ) : null}
                <h3>Último escaneado: {`${ultimoScann.data.Nombre}`}</h3>
                <Grid2 xs={6} sm={6} md={4} lg={4}>
                  {startScan ? (
                    loadingPaseLista === false ? (
                      <>
                        <Button
                          variant="contained"
                          onClick={() => setFacinMode("environment")}
                          endIcon={<ThreeSixtyIcon />}
                        >
                          Trasera
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setFacinMode("user")}
                          endIcon={<ThreeSixtyIcon />}
                        >
                          Delantera
                        </Button>

                        <QrReader
                          facingMode={facingMode}
                          delay={1000}
                          onError={handleError}
                          onScan={handleScan}
                          style={{ width: "100%" }}
                        />
                      </>
                    ) : (
                      <CircularProgress />
                    )
                  ) : null}
                </Grid2>
              </Box>
            ),
          }[view]
        }
        <ModalQR
          openModal={openModal1}
          handleCloseModal={handleCloseModal}
          data={dataItem}
        />
      </Container>
    </AdminLayout>
  );
};

export default EventosPaseListaInfo;
