import { useState, useEffect, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCatalogs } from "@hooks/useCatalogs";

import {
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  Typography,
  ListItemText,
  Box,
  Link,
  IconButton,
} from "@mui/material";

import { blue, red, yellow } from "@mui/material/colors";
import Swal from "sweetalert2";
import "moment/locale/es";

import AdminLayout from "@components/MainPage/AdminLayout";
import BasicTable from "@components/Tables/BasicTableSelectable";
import ActionHeader from "@components/Containers/ActionHeader";
import Filter from "@components/FrenteAmplio/Filter.jsx";
import FrenteAmplioServices from "@services/FrenteAmplioServices";
import { isEmptyOrNullObject } from "@utils/validations";
import middleware from "@middlewares/middleware";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

const List = () => {
  //Constantes
  const colums = [
    {
      label: "Nombre Completo",
      id: "NombreCompleto",
      columnAction: false,
      width: 900,
      orderBy: "NombreCompleto",
    },
    { label: "Credencial", id: "descarga", columnAction: false, width: 20 },
    { label: "Municipio", id: "Municipio", columnAction: false, width: 350 },
    { label: "Correo", id: "account", columnAction: false, width: 350 },
  ];
  const catalogsOptions = [
    { id: "regiones", getAll: false },
    { id: "dl", getAll: false },
    { id: "df", getAll: false },
    { id: "municipios", getAll: false },
    { id: "municipios_dl", getAll: false },
    { id: "municipios_df", getAll: false },
    { id: "poligonos", getAll: false },
    { id: "secciones", getAll: false },
    { id: "perfiles_hijos", getAll: false },
    { id: "calidad_representante", getAll: false },
  ];

  let navigate = useNavigate();
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [{ id: "compromisos_unicos_fa.FechaCreo", value: "desc" }],
  });

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: true,
  });

  //State
  const [view, setView] = useState("table");
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLisCommitments, setDataLisCommitments] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [selectedVolunteer, setSelectedVolunteer] = useState({});

  
    const handleDownload = async (idImg,NombrePaterno) => {
      try {
        let imgReverso = `https://api.mapea.me/files/ines/frontal/${idImg}.jpeg`
        const response = await fetch(imgReverso);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.download = `${NombrePaterno}_Frontal.jpg`; // Nombre con el que se descargará la imagen
        link.click();
  
        // Limpiar el objeto URL
        URL.revokeObjectURL(url);

        let imgAdverso = `https://api.mapea.me/files/ines/reverso/${idImg}.jpeg`
        const responseAd = await fetch(imgAdverso);
        const blob1 = await responseAd.blob();
        const urlAd = URL.createObjectURL(blob1);
  
        const link1 = document.createElement('a');
        link1.href = urlAd;
        link1.download = `${NombrePaterno}_Reverso.jpg`; // Nombre con el que se descargará la imagen
        link1.click();
  
        // Limpiar el objeto URL
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error al descargar la imagen:', error);
      }
    };


  const getList = useCallback((data) => {
    setIsLoading(true);
    FrenteAmplioServices.getList(data)
      .then((res) => {
        if (res.results) {
          const Commitments = res.response.data.map((item) => {
            return {
              ...item,
              NombreCompleto: middleware.checkMenuAction("Editar") ? (
                <Button
                  disableElevation
                  variant="text"
                  color="primaryDark"
                  size="small"
                  sx={{
                    ":hover": {
                      bgcolor: blue[900],
                      color: "white",
                    },
                    p: "0 auto",
                    textAlign: "left",
                  }}
                  startIcon={<Icon>edit</Icon>}
                  onClick={(e) => handleEdit(item)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="div" fontWeight={700} fontSize={15}>
                        {item.NombreCompleto}
                      </Typography>
                    }
                  />
                </Button>
              ) : (
                <Typography variant="div" fontWeight={700} fontSize={15}>
                  {item.NombreCompleto}
                </Typography>
              ),
              account: (
                <Typography>
                  {item.account}
                  <IconButton
                    color="primary"
                    size="large"
                    target="_blank"
                    component={Link}
                    href={item.URL}
                  >
                    <ForwardToInboxIcon />
                  </IconButton>
                </Typography>
              ),
              descarga: middleware.checkMenuAction("Exportar") ?<>{
                item.insertID ? <IconButton
                color="primary"
                size="large"
                onClick={(e)=>handleDownload(item.insertID,item.NombrePaterno)}
              >
                <AssignmentReturnedIcon />
              </IconButton> : <></>
              }
              </>:<>{item.insertID ? "Si" : "No"}</>
            };
          });
          setDataLisCommitments(Commitments);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setDataLisCommitments([]);
          setTotal(0);
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: "error" });
        setDataLisCommitments([]);
        setTotal(0);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getList(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (sortedData.length > 0) {
      const params = {
        ...data,
        sorted: sortedData,
      };

      getList(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleEdit = (commitment) => {
    const { id } = commitment;

    FrenteAmplioServices.getFind(id)
      .then((res) => {
        if (res.results) {
          navigate("/frente-captura", { state: res.response });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: "warning" }));
  };

  const handleChangeFilter = (filtered) => {
    setDataLisCommitments([]);
    setData((prevState) => ({ ...prevState, filtered }));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Municipio: "cat_municipios.Municipio",
      NombreCompleto:
        "CONCAT_WS(' ',compromisos_unicos_fa.Nombre, compromisos_unicos_fa.Paterno, compromisos_unicos_fa.Materno)",
      Celular: "compromisos_unicos_fa.Celular",
      Correo: "compromisos_unicos_fa.Correo",
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Listado Frente Amplio" isLink ={true} titleLink="Ir a la Página Frente Amplio" link="https://enrolamientopan.mx" />
        {
          {
            table: (
              <Box>
                <Filter onChangeFilter={(e) => handleChangeFilter(e)} />
                <Card className="card-primary">
                  <CardContent>
                    <BasicTable
                      rows={dataLisCommitments}
                      hcolumns={colums}
                      handleChangePagination={handleChangePagination}
                      isLoading={load || isLoading}
                      total={total}
                      pageProp={data.page}
                      pageSize={data.pageSize}
                      stickyHeader={true}
                      handleManualSort={handleRequestSort}
                    />
                  </CardContent>
                </Card>
              </Box>
            ),
          }[view]
        }
      </Container>
    </AdminLayout>
  );
};

export default List;
