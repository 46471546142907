import {
    Button,
    Card,
    Container,
    Grid,
    Icon,
    Typography,
    Stack,
    TextField
} from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import { FilterFrente } from "@data/interfaces/FrenteAmplioInterfaces";
import { FilterFrenteSchema } from "@data/schemas/FrenteAmplioSchemas";

import { isTypePhone } from "@utils/validations";

const Filter = ({ onChangeFilter }) => {
    const formik = useFormik({
        initialValues: FilterFrente,
        validationSchema: FilterFrenteSchema,
        onSubmit: (values) => {
            handleFilter(values);
        },
    });

    const handleFilter = (values) => {
        const { celular, nombre, Correo } = values;
        let filtered = [];

        if (formik.values.celular !== "") {
            filtered.push({ id: "compromisos_unicos_fa.Celular", filter: "=", value: celular });
        }

        if (formik.values.nombre !== "") {
            filtered.push({
                id: "CONCAT_WS('%', compromisos_unicos_fa.Nombre, compromisos_unicos_fa.Paterno, compromisos_unicos_fa.Materno)",
                filter: "LIKE",
                value: nombre.toUpperCase(),
            });
        }

        if (formik.values.Correo !== "") {
            filtered.push({
                id: "mail_accounts.Correo",
                filter: "LIKE",
                value: `%${Correo.toUpperCase().replace(/ /g, '%')}%`,
            });
        }

        onChangeFilter(filtered)
    };

    const handleOnKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            formik.submitForm();
        }
    };

    const handleClearFilter = () => {
        formik.setValues({
            ...formik,
            celular: "",
            nombre: "",
            Correo:""
        });

        onChangeFilter([])
    };
    return (
        <Card className="card-primary">
            <Container maxWidth={false} sx={{ p: 2 }}>
                <Stack
                    direction="row"
                    spacing={1}
                    marginBottom={2}
                    alignItems="center"
                >
                    <Icon>tune</Icon>
                    <Typography variant="subtitle1" fontWeight={600}>
                        Filtros
                    </Typography>
                </Stack>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            sx={{ width: "100%" }}
                            label="Celular"
                            type="text"
                            name="celular"
                            onChange={(e) =>
                                formik.handleChange({
                                    target: {
                                        name: "celular",
                                        value: isTypePhone(e.target.value)
                                            ? e.target.value
                                            : formik.values.celular,
                                    },
                                })
                            }
                            onBlur={(e) =>
                                formik.handleChange({
                                    target: {
                                        name: "celular",
                                        value: isTypePhone(e.target.value)
                                            ? e.target.value
                                            : formik.values.celular,
                                    },
                                })
                            }
                            onKeyPress={handleOnKeyPress}
                            value={formik.values.celular}
                            variant="outlined"
                            size="small"
                            className="fixed-input"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            sx={{ width: "100%" }}
                            label="Nombre"
                            type="text"
                            name="nombre"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyPress={handleOnKeyPress}
                            value={formik.values.nombre.toUpperCase()}
                            variant="outlined"
                            size="small"
                            className="fixed-input"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            sx={{ width: "100%" }}
                            label="Correo"
                            type="text"
                            name="Correo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyPress={handleOnKeyPress}
                            value={formik.values.Correo.toUpperCase()}
                            variant="outlined"
                            size="small"
                            className="fixed-input"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Button
                            variant="contained"
                            color="primaryDark"
                            onClick={formik.submitForm}
                        >
                            Filtrar
                        </Button>
                        <Button
                            variant="text"
                            color="primaryDark"
                            onClick={handleClearFilter}
                            sx={{ ml: 1 }}
                        >
                            Limpiar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Card>
    );
};

Filter.propTypes = {
    onChangeFilter: PropTypes.func.isRequired
};

export default Filter;