import React, { useState, useEffect } from "react";
import CatalogServices from "@services/CatalogServices";
import Swal from "sweetalert2";
import { Grid,Box,Button, Typography } from "@mui/material";
import InputSelect from "@components/Selects/BasicSelect";
import Select from "react-select";
import makeAnimated from "react-select/animated";


const FiltroDL = ({
        onChangeFilter,
        poligono = false,
        seccion = true
    }) =>{
    
    const animatedComponents = makeAnimated();
    const defaultOption = { value: 0, label: "TODOS" };
    const [catDistritoDLFilter, setCatDistritoDLFilter] = useState([]);
    const [catMunicipality, setCatMunicipality] = useState([]);
    const [catMunicipalityFilter, setCatMunicipalityFilter] = useState([]);
    const [catSection, setCatSection] = useState([]);
    const [catSectionFilter, setCatSectionFilter] = useState([]);
    const [selectedSections, setSelectedSections] = useState([]);
    const [catPolygon, setCatPolygon] = useState([]);
    const [catPolygonFilter, setCatPolygonFilter] = useState([]);
    const [selectedPolygons, setSelectedPolygons] = useState([]);
    const [clean, setClean] = useState(false);
    const [dataCatalog, setDataCatalog] = useState({
        DL: 0,
        Municipio: 0,
        Seccion: []
      });

        //Functions Api's
  useEffect(() => {
    const catalogsParams = [
      { id: "dl", getAll: false },
      { id: "municipios_distritoLocal", getAll: false },
      { id: "poligonos", getAll: false },
      { id: "secciones", getAll: false },
    ];

    CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          catalogsParams.forEach((item) => {
            switch (item.id) {
              case "dl":
                setCatDistritoDLFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;

              case "municipios_distritoLocal":
                setCatMunicipality(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatMunicipalityFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;
              case "poligonos":
                setCatPolygon(res.response.catalogs[item.id]);
                setCatPolygonFilter(res.response.catalogs[item.id]);
                break;
              case "secciones":
                setCatSection(res.response.catalogs[item.id]);
                setCatSectionFilter(res.response.catalogs[item.id]);
                break;

              default:
            }
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: "warning" }));

    // eslint-disable-next-line
  }, []);

      const handleChangeDL = (event) => {
        const idDL = event.target.value;
        setDataCatalog({
          ...dataCatalog,
          DL: idDL,
          Municipio: 0,
          Seccion: [],
        });
    
        if (idDL > 0) {
          setCatMunicipalityFilter(
            [defaultOption].concat(
              catMunicipality.filter((item) => item.idDL === idDL)
            )
          );
          setCatSectionFilter(
            catSection.filter((item) => item.idDL === idDL)
          );
        } else {
          setCatMunicipalityFilter(catMunicipality);
          setCatSectionFilter(catSection);
        }
      };

      const handleChangeMunicipio = (e) => {
        const idMunicipality = e.target.value;
        setDataCatalog({
          ...dataCatalog,
          Municipio: idMunicipality,
          Seccion: [],
        });
    
        if (idMunicipality > 0) {
          setCatSectionFilter(
            catSection.filter((item) => item.idMunicipio === idMunicipality)
          );
        } else {
          setCatSectionFilter(catSection);
        }
      };

      const handleChangeSection = (newValue) => {
        setSelectedSections(newValue);
        const array_section = newValue.map((option) => option.value || option);
    
        setDataCatalog({
          ...dataCatalog,
          Seccion: array_section,
        });
      };

     const searchFilter = () => {
        const valueFilter = {
          DL: dataCatalog.DL,
          Municipio: dataCatalog.Municipio,
          Seccion: dataCatalog.Seccion,
          Poligono: dataCatalog.Poligono,
        };
    
        onChangeFilter(valueFilter);
      };
    
      const clearFilter = () => {
        setClean(!clean);
        const newFilter = {
          DL: 0,
          Municipio: 0,
          Seccion: [],
          Poligono: [],
        };
    
        setCatMunicipalityFilter(catMunicipality);
        setCatSectionFilter(catSection);
        setCatPolygonFilter(catPolygon);

        setDataCatalog({
          ...dataCatalog,
          DL: 0,
          Municipio: 0,
          Seccion: [],
          Poligono: [],
        });
    
      
        onChangeFilter(newFilter);
      };
   
      const selectStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
      };
    
      const handleChangePolygon = (newValue) => {
        setSelectedPolygons(newValue);
        const array_poligon = newValue.map((option) => option.value || option);
    
        setDataCatalog({
          ...dataCatalog,
          Poligono: array_poligon,
        });
    
        if (array_poligon.length > 0) {
          setCatSectionFilter(
            catSection.filter((item) =>
              array_poligon.some((id) => id === item.idPoligono)
            )
          );
        } else {
          setCatSectionFilter(catSection);
        }
      };


    return(
      <>
        <Grid container spacing={2} marginBottom={"1rem"} marginTop={"0.5rem"}>
            <Grid item xs={12} md={4}>
            <InputSelect
              label="Distrito Local"
              options={catDistritoDLFilter}
              name="dl"
              value={
                catDistritoDLFilter.length === 2
                  ? catDistritoDLFilter[1].value
                  : dataCatalog.DL
              }
              onChange={handleChangeDL}
              disabled={catDistritoDLFilter.length === 2 ? true : false}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
              <InputSelect
                label="Municipio"
                options={catMunicipalityFilter}
                name="municipio"
                value={
                    catMunicipality.length === 2
                    ? catMunicipalityFilter[1].value
                    : dataCatalog.Municipio
                }
                onChange={handleChangeMunicipio}
                disabled={(catMunicipality.length === 2)? true : dataCatalog.DL === 0 ? true : false}
                sx={{ width: "100%" }}
              />
            {dataCatalog.DL === 0 && <Typography variant="caption">Seleccione un Distrito Local</Typography>}
            </Grid>
            {poligono && (
            <Grid item xs={12} md={3}>
              <Select
                placeholder="Polígono"
                styles={selectStyles}
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={
                  catPolygonFilter.length === 1
                    ? [catPolygonFilter[0]]
                    : selectedPolygons
                }
                onChange={handleChangePolygon}
                isMulti
                options={catPolygonFilter}
                isDisabled={catPolygonFilter.length === 1 ? true : false}
              />
            </Grid>
          )}
          {
            seccion &&
            <Grid item xs={12} md={4}>
            <Select
              placeholder="Sección"
              styles={selectStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={
                catSectionFilter.length === 1
                  ? [catSectionFilter[0]]
                  : selectedSections
              }
              onChange={handleChangeSection}
              isMulti
              options={catSectionFilter}
              isDisabled={(catSectionFilter.length === 1)?  true : false}
            />
          </Grid>
          }
        </Grid>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={searchFilter}
          >
            Filtrar
          </Button>
          <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
        </>
    )
}
export default FiltroDL;