import React, { useState, useEffect, useCallback } from "react";

import {
  Card,
  CardContent,
  Container,
  Grid,
  Modal,
  Icon,
  Tab,
  CardHeader,
} from "@mui/material";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import Swal from "sweetalert2";
import ActionHeader from "@components/Containers/ActionHeader";
import middleware from "@middlewares/middleware";
import useWindowDimensions from "@hooks/useWindowDimensions";
import AdminLayout from "@components/MainPage/AdminLayout";
import BasicList from "@components/Lists/BasicList";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import UserMenuList from "@components/Menus/UserMenuList";
import MenusList from "@components/Menus/MenusList";
import { UserInfoInterface } from "@data/interfaces/UserInterfaces";
import UserInformation from "@components/Users/UserInformation";
import UserAccess from "@components/Users/UserAccess";
import UserListItem from "@components/Users/UserListItem";
import UserSecurity from "@components/Users/UserSecurity";
import UserForm from "@components/Users/UserForm";
import UserService from "@services/UserServices";
import MenuToolbar from "@components/Menus/MenuToolbar";
import AddNewMenuList from "@components/Menus/AddNewMenuList";

const Users = () => {

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 20;
  const [issuccessForm, setIsSuccessForm] = useState(false);
  const [isloadigForm, setIsLoadingForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isAddMenusModalOpen, setIsAddMenusModalOpen] = useState(false);
  const [isLoadingAddMenu, setIsLoadingAddMenu] = useState(false);

  const [userList, setUserList] = useState([]);
  const [userInfo, setUserInfo] = useState(UserInfoInterface);
  const [webMenus, setWebMenus] = useState([]);
  const [appMenus, setAppMenus] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [userId, setUserId] = useState();

  const [tabValue, setTabValue] = useState('1');
  const [flagForm, setFlagForm] = useState(false);
  const [actionForm, setActionForm] = useState("");
  const [title, setTitle] = useState("Administración de usuarios");

  const { height } = useWindowDimensions();
  const config = {
    title: "Listado de Usuarios",
    icon: "people",
    height: height * 0.5,
    endMessage: "No hay más registros para mostrar",
  };

  const handleClickItem = useCallback(
    (info) => {
      setUserId(info.id)
      setUserInfo(info);
      setWebMenus([]);
      setAppMenus([]);

      setIsLoadingForm(true);
      setOpenLinearProgress(true);

      UserService.getMenus({ idUsuario: info.id, Movil: 0 })
        .then((res) => {
          if (res.results) {
            setWebMenus(res.response.data.map(item => {
              return {
                ...item,
                idMenu: item.id,
                id: info.id
              }
            }));
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: "warning" });
        }).finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
        });

      UserService.getMenus({ idUsuario: info.id, Movil: 1 })
        .then((res) => {
          if (res.results) {
            setAppMenus(res.response.data.map(item => {
              return {
                ...item,
                idMenu: item.id,
                id: info.id
              }
            }));
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: "warning" });
        }).finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
        });
    },
    []
  );

  const getUsers = useCallback((data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    UserService.getUsers({ page: data.page, pageSize: data.pageSize, filtered: data.filtered })
      .then((res) => {
        if (res.results) {
          if (res.response.data.length > 0) {
            setUserList(res.response.data);
            setTotal(parseInt(res.response.total));
            handleClickItem(userId ? userInfo : res.response.data[0]);
          }
          if (pageSize >= parseInt(res.response.total)) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
        setHasMore(false);
      }).finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getUsers({ page, pageSize, filtered });
    // eslint-disable-next-line
  }, []);

  const fetchHasMore = () => {
    UserService.getUsers({ page: page + 1, pageSize: pageSize, filtered: filtered })
      .then((res) => {
        if (res.results) {
          setUserList(userList.concat(res.response.data));
          let end = (page + 1) * pageSize;
          if (end >= res.response.total) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      });
    setPage(page + 1);
  };

  const handleChangeTab = (e, newVal) => {
    setTabValue(newVal);
  };

  const handleChangeUserInfo = (params) => {
    setHasMore(true);
    UserService.getUsers({ page: 0, pageSize: pageSize, filtered: filtered })
      .then((res) => {
        if (res.results) {
          if (res.response.total > 0) {
            setUserList(res.response.data);
            setTotal(parseInt(res.response.total));
            handleClickItem(res.response.data[0]);
          }

          if (pageSize >= parseInt(res.response.total)) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setFlagForm(false);
        setTitle("Administración de usuarios");
      });
  };

  const handleAddMenu = () => {
    setIsAddMenusModalOpen(true);
  };

  const submitMenus = (menus) => {
    setIsLoadingAddMenu(true);
    const params = {
      menus,
      idUsuario: userInfo.id,
    };
    UserService.insertUserMenus(params)
      .then((response) => {
        let icon = "warning";
        if (response.success && response.results) {
          icon = "success";
          setIsAddMenusModalOpen(false);
          handleClickItem(userInfo)
        }
        setIsLoadingAddMenu(false);
        Swal.fire({
          title: response.message,
          icon,
          customClass: {
            container: "modal-alert",
          },
        });
      })
      .catch((error) => {
        setIsLoadingAddMenu(false);
        Swal.fire({
          title: error.message,
          icon: "warning",
          customClass: {
            container: "modal-alert",
          },
        });
      });
  };

  const handleSearch = (searchInput) => {
    let filtro = [];
    const regex = / /;
    if (searchInput === "") {
      filtro = [];
    } else {
      filtro = [{
        id:`CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno, usuarios.Username)`,
        filter: "LIKE",
        value: `%${searchInput.replace(/\s+/g, '%')}%`,
        inheritFilterType: null
      }];
    }
    setFiltered(filtro);
    setPage(0);
    getUsers({ page: 0, pageSize, filtered: filtro });
  }

  // const handleclickActionHeader = () => {
  //   if (!flagForm) {
  //     setFlagForm(true);
  //     setActionForm("add");
  //     setTitle("Agregar usuario nuevo");
  //   } else {
  //     setFlagForm(false);
  //     setTitle("Administración de usuarios");
  //   }
  // }

  return (
    <>
      <AdminLayout>
        <Container maxWidth="false">
          <ActionHeader
            title={title}
          // isAction={true}
          // titleButton={flagForm ? "Regresar" : "Agregar"}
          // iconButton={flagForm ? "chevron_left" : "add"}
          // handleclick={() => handleclickActionHeader()}
          />
          <Card className="card-primary">
            <CardContent>
              <Modal
                open={isAddMenusModalOpen}
                onClose={() => setIsAddMenusModalOpen(false)}
              >
                <Card
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "60%",
                    maxHeight: "100%",
                    overflowY: "auto",
                    transform: "translate(-50%, -50%)",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <CardHeader title="Agregar menus" />
                  <CardContent>
                    <AddNewMenuList
                      currentMenus={webMenus}
                      submitMenus={submitMenus}
                      isLoadingAdd={isLoadingAddMenu}
                      handleCloseModal={() => setIsAddMenusModalOpen(false)}
                    />
                  </CardContent>
                </Card>
              </Modal>
              <LoadingForm
                isLoading={isloadigForm}
                success={issuccessForm}
                isOpen={openLinearProgress}
                setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
                loadinMessage={"Cargando Datos..."}
              />
              {flagForm ? (
                <UserForm
                  action={actionForm}
                  data={userInfo}
                  handleChangeUserInfo={handleChangeUserInfo}
                />
              ) : (
                <Grid container sx={{ p: 2 }} spacing={4}>
                  <Grid item xs={12} md={4} sm={12} lg={5}>
                    {
                      <BasicList
                        configParams={config}
                        data={userList}
                        fetchHasMore={fetchHasMore}
                        totalItems={total}
                        itemComponent={UserListItem}
                        hasMore={hasMore}
                        handleClickItem={handleClickItem}
                        finder={true}
                        search={handleSearch}
                      />}
                  </Grid>
                  <Grid item xs={12} md={8} sm={12} lg={7}>
                    <TabContext value={tabValue}>
                      <TabList
                        centered
                        aria-label="lab API tabs example"
                        onChange={handleChangeTab}
                        textColor="secondary"
                        indicatorColor="secondary"
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                        scrollButtons="auto"
                      >
                        <Tab
                          icon={<Icon>info</Icon>}
                          label="Información"
                          value={1}
                        />
                        <Tab
                          icon={<Icon>admin_panel_settings</Icon>}
                          label="Menús Web"
                          value={2}
                        />
                        <Tab
                          icon={<Icon>app_settings_alt</Icon>}
                          label="Menús App"
                          value={3}
                        />
                        {middleware.checkMenuAction("Editar") && (
                          <Tab
                            icon={<Icon>lock</Icon>}
                            label="Seguridad"
                            value={4}
                          />
                        )}
                        <Tab
                          icon={<Icon>exit_to_app</Icon>}
                          label="Accesos"
                          value={5}
                        />
                      </TabList>
                      <TabPanel value={1}>
                        <UserInformation
                          data={userInfo}
                          setFlagForm={setFlagForm}
                          setActionForm={setActionForm}
                          height={height * 0.4}
                        />
                      </TabPanel>
                      <TabPanel value={2} sx={{ pt: 0 }}>
                        {middleware.checkMenuAction("Editar") ? (
                          <UserMenuList
                            menus={webMenus}
                            type={"list-edit"}
                            maxHeight={height * 0.5}
                            toolbar={true}
                            ComponentToolbar={MenuToolbar}
                            configToolbar={{
                              title: `${userInfo.Nombre} ${userInfo.Paterno} ${userInfo.Materno}`,
                              icon: "add_circle",
                              btnAdd: true,
                              handleAdd: handleAddMenu,
                            }}
                            setIsOpen={setOpenLinearProgress}
                            setIsLoading={setIsLoadingForm}
                            setIsSuccess={setIsSuccessForm}
                            externalID={userInfo.id}
                            info={userInfo}
                            handleReloadList={handleClickItem}
                          />)
                          : (
                            <MenusList
                              menus={webMenus}
                              type={"list"}
                              maxHeight={height * 0.5}
                            />
                          )}
                      </TabPanel>
                      <TabPanel value={3} sx={{ pt: 0 }}>
                        {middleware.checkMenuAction("Editar") ? (
                          <UserMenuList
                            menus={appMenus}
                            type={"list-edit"}
                            maxHeight={height * 0.5}
                            toolbar={true}
                            ComponentToolbar={MenuToolbar}
                            configToolbar={{
                              title: `${userInfo.Nombre} ${userInfo.Paterno} ${userInfo.Materno}`,
                              icon: "add_circle",
                              btnAdd: true,
                              handleAdd: handleAddMenu,
                            }}
                            setIsOpen={setOpenLinearProgress}
                            setIsLoading={setIsLoadingForm}
                            setIsSuccess={setIsSuccessForm}
                            externalID={userInfo.id}
                            info={userInfo}
                            handleReloadList={handleClickItem}
                          />
                        )
                          : (
                            <MenusList
                              menus={appMenus}
                              type={"list"}
                              maxHeight={height * 0.5}
                            />
                          )}
                      </TabPanel>
                      {middleware.checkMenuAction("Editar") && (
                        <TabPanel value={4}>
                          <UserSecurity
                            height={height * 0.6}
                            data={userInfo}
                            access="admin"
                          />
                        </TabPanel>
                      )}
                      <TabPanel value={5}>
                        <UserAccess data={userInfo} />
                      </TabPanel>
                    </TabContext>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Container>
      </AdminLayout>
    </>
  );
};

export default Users;
