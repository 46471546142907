import { useState, useEffect, useCallback } from "react";
import { useCatalogs } from "@hooks/useCatalogs";

//MUI
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  ListItemText,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { blue, grey } from "@mui/material/colors";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
const Eventos = () => {
  //Constants

  return (
    <AdminLayout>
      <Container maxWidth={false} sx={{ backgroundColor: grey }}>
        <h1>Eventos</h1>
      </Container>
    </AdminLayout>
  );
};

export default Eventos;
