import React, { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";
import {
    Box,
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Checkbox,
    Typography
} from '@mui/material';
import { forEach } from "lodash";
import { element } from "prop-types";

const PerfileRoles = (props) => {
    const {data} = props;
    const { catalogs, load, errors } = useCatalogs({
        catalogsOptions: [
          {
            id: "perfiles_hijos",
            getAll: false,
          }
        ],
        putDefaultOption: false,
      });

    const [perfiles,setPerfiles] = useState([{label:"",value:0}]);
    const [state,setState] = useState([]);
    
    useEffect(()=>{
        let DataTrue = data.map(item => {
            return {...item,
            label:`${item.label} (${item.Ambito})`,
            checked:true,
            disabled:true
        }});
        let cat = catalogs.perfiles_hijos ? catalogs.perfiles_hijos.map(item => {
            return {...item,
            label:`${item.label} (${item.Ambito})`,
            checked:false,
            disabled:false
        }}): [];
        let perfilesAll = DataTrue.concat(cat);
        let perfilesList = [];

        perfilesAll.forEach(element => {
            let index = perfilesList.findIndex(item => ((item.value === element.value) && (item.idAmbitoCargo === element.idAmbitoCargo)));
            let isTrue = element.disabled;
            let isChecked = element.checked;
         
            if(index !== -1){
                if(!perfilesList[index].disabled || !element.disabled){
                  isTrue=false;
                }
                if(perfilesList[index].checked || element.checked){
                  isChecked=true;
                }
                if(!perfilesList[index].disabled || !element.disabled){
                  isTrue=false;
                }
                perfilesList[index] = {...element, disabled:isTrue, checked:isChecked};
            }else{
                perfilesList.push({...element, disabled:isTrue, checked:isChecked});
            }
        });
     
        setPerfiles(perfilesList);

        console.log(perfilesList,perfilesList.filter(item => item.checked === true))
        setState(perfilesList.filter(item => item.checked));

    },[load,data])


    const handleChange = (element) => {

        let perfilesList = perfiles;
        let index = perfiles.findIndex(item => item.value === element.value && item.idAmbitoCargo === element.idAmbitoCargo);
        perfilesList[index] = {...element, checked:!element.checked};
        if(!element.checked){
            setState([...state,{...element, checked:!element.checked}])
        }else{
            let notIn = state.filter(item => item.value !== element.value && item.idAmbitoCargo !== element.idAmbitoCargo);
            setState(notIn);
        }
        setPerfiles(perfilesList); 
    };


      return (
        <Box sx={{ display: 'flex', maxHeight: 440 , overflowX:"auto" }} >
        <Typography>Perfiles que puede asignar</Typography>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend"></FormLabel>
          <FormGroup>
            {
                perfiles.map(item => {
                 return (  <FormControlLabel disabled={item.disabled}
                    control={
                      <Checkbox checked={state.find(i =>  item.value === i.value && item.idAmbitoCargo === i.idAmbitoCargo).checked} onChange={()=>handleChange(item)} name={`${item.label}`}  />
                    }
                    label={item.label}
                  />)
                })
            }
          </FormGroup>
        </FormControl>
      </Box>  
      );
    

}
export default PerfileRoles;