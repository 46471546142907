import { useState, useEffect } from "react";
import { useCatalogs } from "@hooks/useCatalogs";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  LinearProgress,
  CardHeader,
  Typography,
} from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import moment from "moment";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import PersonalInformationForm from "@components/Compromisos/PersonalInformationForm";
import ContactInformationForm from "@components/Compromisos/ContactInformationForm";
import AddressForm from "@components/Compromisos/AddressForm";

import { getVars } from "@utils/global";
import FrenteAmplioServices from "@services/FrenteAmplioServices";
import { FrenteAddInterface } from "@data/interfaces/FrenteAmplioInterfaces";
import {
  FrenteAddSchema,
  FrenteEditSchema,
} from "@data/schemas/FrenteAmplioSchemas";
import { isEmptyOrNullObject, isNullOrUndefined } from "@utils/validations";
import { validaINE, handleAttrs } from "@utils/Utilities";
import ResponsibleListFrente from "@components/Compromisos/ResponsibleListFrente";
import {
  VIGENCIA_INE,
  VOL_RC,
  VOL_RG,
  VOL_OBSERVER,
  VOL_FiL,
} from "@data/constants";

const Form = () => {
  const catalogsOptions = [
    { id: "generos" },
    { id: "entidades" },
    { id: "municipios" },
    { id: "voluntarios" },
    { id: "tipo_vialidad" },
    { id: "mis_segmentos" },
    { id: "partidos" },
    { id: "medios_contacto" },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = getVars("Token");
  const [flagEdit, setFlagEdit] = useState(false);
  const getFindCommitment = location.state ? location.state : null;

  //LoadigForm
  const [loadingEdit, setloadingEdit] = useState(location.state ? true : false);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [finished, setFinished] = useState(false);

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false,
  });

  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });

  useEffect(() => {
    formik.setFieldValue("idUsuarioPropietario", user.id, false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (load === false) {
      setCatalogFiltered(catalogs);
    }
    // eslint-disable-next-line
  }, [load]);

  useEffect(() => {
    if (
      !isEmptyOrNullObject(getFindCommitment) &&
      catalogsFiltered.tipo_vialidad.length > 0
    ) {
      let cp = parseInt(getFindCommitment.CPVive);
      let vialidad = getFindCommitment.CalleVive.split(" ");
      let CalleVive = vialidad.slice(1, vialidad.length);
      let found = catalogsFiltered.tipo_vialidad.find(
        (item) => item.value === vialidad[0]
      );
      let formatedData = {
        ...getFindCommitment,
        insertID: getFindCommitment.insertID,
        CPVive: cp,
        CalleVive: CalleVive.join(" "),
        TipoVialidad: found ? vialidad[0] : "",
        Segmentos: getFindCommitment.Segmentos
          ? getFindCommitment.Segmentos.map((item) => parseInt(item))
          : [],
        idVoluntario: getFindCommitment.idVoluntario
          ? getFindCommitment.idVoluntario
          : "",
        idMedioContacto: getFindCommitment.idMedioContacto
          ? getFindCommitment.idMedioContacto
          : "",
        TieneAuto: !isNullOrUndefined(getFindCommitment.TieneAuto)
          ? getFindCommitment.TieneAuto
          : "",
        TieneExperiencia: !isNullOrUndefined(getFindCommitment.TieneExperiencia)
          ? getFindCommitment.TieneExperiencia
          : "",
        AceptaCuidarOtra: !isNullOrUndefined(getFindCommitment.AceptaCuidarOtra)
          ? getFindCommitment.AceptaCuidarOtra
          : "",
      };
      setloadingEdit(false);
      formik.setValues(formatedData);
    }
    // eslint-disable-next-line
  }, [getFindCommitment, catalogsFiltered.tipo_vialidad]);

  const formik = useFormik({
    initialValues: FrenteAddInterface,
    validationSchema: location.state ? FrenteEditSchema : FrenteAddSchema,
    onSubmit: (values) => {
      handleVerification(handleAttrs(values));
    },
  });

  const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      const res = validaINE(
        values.INE,
        values.Nombre,
        values.Paterno,
        values.Materno,
        values.idSexo,
        values.FechaNacimiento,
        values.idEstadoNacimiento
      );

      if (!res.results) {
        Swal.fire({
          title: `¡Atención!`,
          text: res.message,
          icon: "info",
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
      } else {
        if (
          values.EsVoluntario === 1 &&
          [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(
            values.idVoluntario
          ) &&
          values.VigenciaINE < VIGENCIA_INE
        ) {
          Swal.fire({
            title: `¡Vigencia INE!`,
            text: "Invite a la persona a renovar su credencial de elector, ya que no será válida como medio de identificación en el siguiente proceso",
            icon: "info",
            reverseButtons: true,
            showCancelButton: false,
            confirmButtonText: "Continuar",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((res) => {
            if (res.isConfirmed) {
              handleSave(values);
            }
          });
        } else {
          handleSave(values);
        }
      }
    }
  };

  const handleSave = (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);
    let data = {};
    Object.keys(FrenteAddInterface).forEach((key) => {
      data[key] = values[key];
    });

    if (getFindCommitment) {
      data = {
        ...data,
        idCompromisoUnico: getFindCommitment.idCompromisoUnico,
      };
    }

    data = {
      ...data,
      VigenciaINE: values.VigenciaINE ? parseInt(values.VigenciaINE) : null,
      INE: values.INE.toUpperCase(),
      Nombre: values.Nombre.toUpperCase(),
      Paterno: values.Paterno.toUpperCase(),
      Materno: values.Materno.toUpperCase(),
      FechaNacimiento: moment(values.FechaNacimiento).format("YYYY-MM-DD"),
      CalleVive: `${values.TipoVialidad} ${values.CalleVive}`,
    };
    delete data.TipoVialidad;
    delete data.FrontIne;
    delete data.BehindIne;
    delete data.flagOtro;

    if (!isEmptyOrNullObject(getFindCommitment)) {
      data = { id: getFindCommitment.id, ...data };
      delete data.insertID;
      FrenteAmplioServices.update(data)
        .then((res) => {
          if (res.success && res.results) {
            setFinished(true);
            Swal.fire({
              title: res.message,
              icon: "success",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((isResponse) => {
              if (isResponse.isConfirmed) {
                clear();
                navigate("/frente-list");
              }
            });
          } else {
            setFinished(false);
            Swal.fire({ title: res.message, icon: "warning" });
          }
        })
        .catch((e) => {
          setFinished(false);
          Swal.fire({ title: e.message, icon: "warning" });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
          setIsSuccessFormSave(true);
        });
    } else {
      let EsNuevo = !isNullOrUndefined(getFindCommitment)
        ? getFindCommitment
        : 0;
      data = { ...data, EsNuevo };

      FrenteAmplioServices.create(data)
        .then((res) => {
          if (res.success && res.results) {
            setFinished(true);
            Swal.fire({
              title: res.message,
              icon: "success",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((isResponse) => {
              if (isResponse.isConfirmed) {
                clear();
                if (!isNullOrUndefined(getFindCommitment))
                  navigate("/pase-lista");
              }
            });
          } else {
            setFinished(false);
            Swal.fire({ title: res.message, icon: "warning" });
          }
        })
        .catch((e) => {
          setFinished(false);
          Swal.fire({ title: e.message, icon: "warning" });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
          setIsSuccessFormSave(true);
        });
    }
  };

  const clear = () => {
    setFinished(false);
    formik.setValues({
      ...formik.values,
      INE: "",
      VigenciaINE: "",
      Nombre: "",
      Paterno: "",
      Materno: "",
      flagOtro: false,
      PublicoGeneral: 0,
      InvitadoPor: null,
      FechaNacimiento: "",
      idSexo: "",
      idEstadoNacimiento: "",
      CalleVive: "",
      TipoVialidad: "C",
      NumExtVive: "",
      NumIntVive: "",
      idMunicipioVive: "",
      idLocalidadVive: "",
      ColoniaVive: "",
      CPVive: "",
      SeccionVota: "",
      Celular: "",
      Telefono: "",
      TelMensajes: "",
      Correo: "",
      Facebook: "",
      Instagram: "",
      Twitter: "",
      TikTok: "",
      FrontIne: null,
      BehindIne: null,
    });
  };

  const handleLabelClick = (labelContent) => {
    const tempInput = document.createElement("input");
    tempInput.value = labelContent;
    document.body.appendChild(tempInput);
    tempInput.select();

    document.execCommand("copy");

    document.body.removeChild(tempInput);
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        {!isEmptyOrNullObject(getFindCommitment) ? (
          <ActionHeader
            title="Captura de Frente Amplio"
            isAction={true}
            titleButton="Regresar"
            iconButton="arrow_back"
            handleclick={() => navigate(-1)}
          />
        ) : (
          <ActionHeader title="Captura de Frente Amplio" isAction={false} />
        )}
        <Card className="card-primary">
          {loadingEdit && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          {getFindCommitment && (
            <CardHeader
              action={
                <Button
                  aria-label="Editar"
                  variant="contained"
                  onClick={() => setFlagEdit(!flagEdit)}
                  startIcon={
                    flagEdit ? (
                      <KeyboardBackspaceIcon />
                    ) : (
                      <DriveFileRenameOutlineIcon />
                    )
                  }
                >
                  {flagEdit ? "Regresar" : "Editar"}
                </Button>
              }
            />
          )}
          {getFindCommitment && !flagEdit && (
            <CardContent>
              <Grid2 container spacing={0}>
              <Grid2 xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="h6"
                    component="div"
                  >
                   SI QUIERES COPIAR ALGÚN DATO DE LA PERSONA DA CLIC EN EL QUE DESEES
                  </Typography>
                  <br/>
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={{ p: 0, m: 0 }}
                  >
                    Nombre:
                  </Typography>
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  {getFindCommitment.Nombre && (
                    <Typography
                      component={Button}
                      variant="h6"
                      onClick={() => handleLabelClick(getFindCommitment.Nombre)}
                    >
                      {getFindCommitment.Nombre}
                    </Typography>
                  )}
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Primer Apellido:
                  </Typography>
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  {getFindCommitment.Paterno && (
                    <Typography
                      component={Button}
                      variant="h6"
                      onClick={() =>
                        handleLabelClick(getFindCommitment.Paterno)
                      }
                    >
                      {getFindCommitment.Paterno}
                    </Typography>
                  )}
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="subtitle1" color="text.secondary">
                    Segundo Apellido:
                  </Typography>
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  {getFindCommitment.Materno && (
                    <Typography
                      component={Button}
                      variant="h6"
                      onClick={() =>
                        handleLabelClick(getFindCommitment.Materno)
                      }
                    >
                      {getFindCommitment.Materno}
                    </Typography>
                  )}
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Clave Elector:
                  </Typography>
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  {getFindCommitment.INE && (
                    <Typography
                      component={Button}
                      variant="h6"
                      onClick={() => handleLabelClick(getFindCommitment.INE)}
                    >
                      {getFindCommitment.INE}
                    </Typography>
                  )}
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Sección:
                  </Typography>
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  {getFindCommitment.SeccionVota && (
                    <Typography
                      component={Button}
                      variant="h6"
                      onClick={() =>
                        handleLabelClick(getFindCommitment.SeccionVota)
                      }
                    >
                      {getFindCommitment.SeccionVota}
                    </Typography>
                  )}
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Correo:
                  </Typography>
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  {getFindCommitment.account && (
                    <Typography
                      component={Button}
                      variant="h6"
                      onClick={() =>
                        handleLabelClick(getFindCommitment.account)
                      }
                    >
                      {getFindCommitment.account}
                    </Typography>
                  )}
                </Grid2>
              </Grid2>
            </CardContent>
          )}
          {(!getFindCommitment || flagEdit) &&
            ((
              <CardContent>
                <LoadingForm
                  loadinMessage={"Cargando datos..."}
                  successMessage="¡Cargado con exito!"
                  isLoading={isLoadigForm}
                  success={isSuccessFormSave}
                  isOpen={openLinearProgress}
                  setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
                />

                <ResponsibleListFrente
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  setValues={formik.setValues}
                  handleChange={formik.handleChange}
                  finished={finished}
                />

                <PersonalInformationForm
                  catalogs={catalogsFiltered}
                  loadigCatalogs={load}
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  setValues={formik.setValues}
                  handleChange={formik.handleChange}
                  ScanIne={true}
                  NotSimple={true}
                />

                <ContactInformationForm
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  handleChange={formik.handleChange}
                />

                <AddressForm
                  catalogs={catalogsFiltered}
                  loadigCatalogs={load}
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  setValues={formik.setValues}
                  handleChange={formik.handleChange}
                  NotVialidad={true}
                />

                <Grid2 xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="button"
                      color="primaryDark"
                      variant="contained"
                      size="small"
                      onClick={formik.submitForm}
                      startIcon={<Icon>save</Icon>}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Grid2>
              </CardContent>
            ))}
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default Form;
