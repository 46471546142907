import React from "react";
import {
  Modal,
  Box,
  IconButton,
  Button,
  Icon,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  CardActionArea,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ProfileRepresentante = ({
  open,
  setOpen,
  title = { tipo: "Propietatario 1", casilla: "POR DEFINIR", seccion: 1 },
}) => {
  /*   const [data, setData] = useState({}); */

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            //overflowY: "auto",
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 2,
          }}
        >
           <IconButton aria-label="cancel"  onClick={() => setOpen(false)} sx={{position: 'fixed',left:"3%",top:"2%",color:"#FFFFFF"}}>
            <Icon>cancel</Icon>
            </IconButton>
          <Card elevation={0}>
            <CardHeader
              title={`Información ${title.tipo}`}
              subheader={`Casilla ${title.casilla} Sección ${title.seccion}`}
              titleTypographyProps={{
                align: "center",
                variant: "h5",
                color: "white",
              }}
              subheaderTypographyProps={{ align: "center", color: "white" }}
              sx={{ backgroundColor: "#152A9E", pt:"25px" }}
            />
            <CardContent>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0.5}
              >
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Nombre:
                </Typography>
                <Typography component="div" variant="h6">
                  Jhoana Isabel Dominguez Aguilar
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Celular:
                </Typography>
                <Typography component="div" variant="h6">
                  4622650235
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Domicilio:
                </Typography>
                <Typography component="div" variant="h6">
                  ABASOLO, Abasolo Centro
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Sección Vota:
                </Typography>
                <Typography component="div" variant="h6">
                  1315
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Asignado por:
                </Typography>
                <Typography component="div" variant="h6">
                  SISTEMA SIANGTO
                </Typography>
                <Typography component="div" variant="h6">
                  2021-07-13 17:58:07
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            spacing={0.5}
          >
            <Button
              size="small"
              variant="contained"
              color="error"
              sx={{ borderRadius: "10px" }}
              endIcon={<CloseIcon />}
            >
              Cerrar
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ProfileRepresentante;
