import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Icon,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

//Services
import { LoadingButton } from "@mui/lab";

const ListCasillas = ({ title, handleSave, data = [] }) => {
  /* const data = [
    { id: 100, NombreCasilla: "Basica" },
    { id: 101, NombreCasilla: "Contigua" },
  ]; */

  const [checkedsCasillas, setCheckedsCasillas] = useState([]);
  const [hour, setHour] = useState("");
  const [match, setMatch] = useState(null);

  const handleToggle = (isChecked, id) => {
    const idx = checkedsCasillas.indexOf(id);
    if (isChecked)
      return setCheckedsCasillas((prevState) => [...prevState, id]);

    if (!isChecked && idx > -1) {
      return setCheckedsCasillas((prevState) => {
        prevState.splice(idx, 1);
        return JSON.parse(JSON.stringify(prevState));
      });
    }
  };

  const handleToggleAllChecked = (isChecked) => {
    if (isChecked) {
      return setCheckedsCasillas(data.map((item) => item.id));
    } else {
      setCheckedsCasillas([]);
    }
  };

  const handleChangeValueHour = (hour) => {
    const rgx = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
    setMatch(rgx.test(hour));
    setHour(hour);
  };

  return (
    <Card className="card-primary">
      <CardContent>
        <Typography
          textAlign={"center"}
          variant="body2"
          fontWeight={600}
          marginBottom={2}
        >
          {title}
        </Typography>
        <Box marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            Selecciona la casilla
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                edge="center"
                checked={checkedsCasillas.length === data.length}
                disableRipple
                onChange={(e) => handleToggleAllChecked(e.target.checked)}
                indeterminate={
                  checkedsCasillas.length !== data.length &&
                  checkedsCasillas.length > 0
                }
              />
            }
            label=<Typography variant="subtitle2" fontWeight={600}>
              TODOS
            </Typography>
          />
          <Divider />

          <List sx={{ width: "100%" }}>
            {data.map((item) => {
              const labelId = `checkbox-list-label-${item.id}`;

              return (
                <ListItem key={item.id} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={(e) => handleToggle(e.target.checked, item.id)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checkedsCasillas.includes(item.id)}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`SECCIÓN ${item.Seccion} - ${item.NombreCasilla}`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>

        <Box marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            Hora de pase de lista
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <TextField
            label={"* Hora de Pase de Lista (Formato: 24 hrs, ejemplo: 13:30)"}
            name="hora"
            value={hour}
            onChange={(e) => handleChangeValueHour(e.target.value)}
            size="small"
            sx={{ width: "100%", mb: 3 }}
            InputProps={{
              size: "small",
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>schedule</Icon>
                </InputAdornment>
              ),
            }}
            error={!match && "Ingrese una hora válida"}
            helperText={!match && "Ingrese una hora válida"}
          />
        </Box>
        <Stack direction={"row"} justifyContent={"center"}>
          <Button
            variant="contained"
            color="primaryDark"
            size="small"
            disabled={!checkedsCasillas.length > 0 || hour.length === 0}
            onClick={() =>
              handleSave({ idsCasillas: checkedsCasillas, FechaHora: hour })
            }
          >
            Guardar
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ListCasillas;
