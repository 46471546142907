import React from "react";

import {
  Box,
  TextField,
  Chip,
  Icon
} from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import {
  isEmptyOrInvalidString,
  isTypePhone,
} from "@utils/validations";

const UserForm = (props) => {
  const {
    errors,
    touched,
    values,
    handleChange
  } = props;

  const hadleUsuario = (value) => {
    handleChange({
      target: {
        name: "Celular",
        value: isTypePhone(value)
          ? value
          : values.Celular,
      },
    })
    
    handleChange({
      target: {
        name: "PIN",
        value: isTypePhone(value.substring(0,5))
          ? value.substring(0,5)
          : values.PIN,
      },
    })
  };

  return (
    <Box sx={{ mt: 1, mb: 2 }}>
      <Grid2 xs={12} sm={12} md={12} lg={12}>
        <Chip icon={<Icon>person</Icon>} label="Datos de Usuario" />
      </Grid2>
      <Grid2 container spacing={3} sx={{ mt: 2 }}>
      <Grid2 xs={12} sm={12} md={3} lg={3}>
            <TextField
              error={
                !isEmptyOrInvalidString(errors.Celular)
              }
              helperText={
                errors.Celular &&
                errors.Celular
              }
              sx={{ width: "100%" }}
              required
              label="Usuario"
              type="text"
              name="Celular"
              onChange={(e) =>
                hadleUsuario(e.target.value)
              }
              onBlur={(e) =>
                hadleUsuario(e.target.value)
              }
              value={values.Celular}
              variant="outlined"
              size="small"
              className="fixed-input"
              placeholder="Celular"
            />
          </Grid2>
        <Grid2 xs={6} sm={12} md={2} lg={2}>
          <TextField
              error={
                !isEmptyOrInvalidString(errors.Password)
              }
              helperText={
                errors.Password &&
                errors.Password
              }
              sx={{ width: "100%" }}
              required
              label="Contraseña"
              type="text"
              name="Password"
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "Password",
                    value: e.target.value,
                  },
                })
              }
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "Password",
                    value: e.target.value
                  },
                })
              }
              value={values.Password}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
        </Grid2>
        <Grid2 xs={6} sm={12} md={2} lg={2}>
        <TextField
              error={
                !isEmptyOrInvalidString(errors.Confirm)
              }
              helperText={
                errors.Confirm &&
                errors.Confirm
              }
              sx={{ width: "100%" }}
              required
              label="Repetir Contraseña"
              type="text"
              name="Confirm"
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "Confirm",
                    value: e.target.value
                  },
                })
              }
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "Confirm",
                    value: e.target.value
                  },
                })
              }
              value={values.Confirm}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
        </Grid2>
        <Grid2 xs={6} sm={12} md={2} lg={2}>
        <TextField
              error={
                !isEmptyOrInvalidString(errors.PIN)
              }
              helperText={
                errors.PIN &&
                errors.PIN
              }
              sx={{ width: "100%" }}
              required
              label="PIN"
              type="text"
              name="PIN"
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "CPVive",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : values.CPVive,
                  },
                })
              }
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "CPVive",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : values.CPVive,
                  },
                })
              }
              value={values.PIN}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 5,
              }}
              className="fixed-input"
            />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default UserForm;
