import { Box, Table, TableContainer, TableBody } from "@mui/material";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import middleware from "@middlewares/middleware";
import MenusList from "@components/Menus/MenusList";
import MenuRowHeader from "@components/Menus/MenuRowHeader";
import MenuRow4Edit from "@components/Menus/MenuRow4Edit";
import RoleServices from "@services/RoleServices";

const UserRoleMenuList = (props) => {
  const {
    menus,
    maxHeight,
    toolbar,
    ComponentToolbar,
    configToolbar,
    externalID,
    setIsLoading,
    setIsOpen,
    handleReloadList,
  } = props;
  const inputOptions = new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        0: "Aplicar a partir de ahora",
        1: "Aplicar a todos",
      });
    }, 1000);
  });

  const handleSwitch = (e, idMenu, col) => {
    e.preventDefault();
    let value = e.target.checked ? 1 : 0;
    showImpact4Change(idMenu, col, value);
  };

  const showImpact4Change = (id, column, value) => {
    setIsLoading(true);
    setIsOpen(true);
    RoleServices.getImpact4Change(
      { idMenu: id, idPerfil: externalID, Campo: column, Valor: value }
    )
      .then((res) => {
        if (res.success) {
          let itAffects = res.response.AffectedUsers > 0 ? true : false;
          selectApplyChange(itAffects, res.response, id, column, value);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setIsOpen(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
  };

  const selectApplyChange = (itAffects, response, id, col, value) => {
    if (itAffects) {
      Swal.fire({
        title: "Advertencia",
        html: `Esta acción puede modificar el privilegio a ${response.AffectedUsers} usuario(s) que tienen asignado este menú. ¿Qué acción desea realizar?`,
        icon: "warning",
        input: "radio",
        inputOptions: inputOptions,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aplicar",
        confirmButtonColor: "#1976d2",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        inputValidator: (action) => {
          if (!action) {
            return "Debe seleccionar una opción!";
          } else {
            if (action) {
              updatePrivillege(id, col, value, parseInt(action));
            }
          }
        },
      });
    } else {
      updatePrivillege(id, col, value, 0);
    }
  };

  const updatePrivillege = (id, column, value, action) => {
    setIsLoading(true);
    setIsOpen(true);
    RoleServices.changeAccess(
      { idMenu: id, idPerfil: externalID, Campo: column, Valor: value, Accion: action }
    )
      .then((res) => {
        if (res.success && res.results) {
          handleReloadList(externalID);
          Swal.fire({ title: res.message, icon: "success" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoading(false);
        setIsOpen(false);
      });
  };


  const selectApplyDelete = (itAffects, response, id) => {
    if (itAffects) {
      Swal.fire({
        title: "Advertencia",
        html: `Esta acción afectará a ${response.AffectedUsers} usuario(s) que tienen asignado este menú. ¿Qué acción desea realizar?`,
        icon: "warning",
        input: "radio",
        inputOptions: inputOptions,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aplicar",
        confirmButtonColor: "#1976d2",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        inputValidator: (action) => {
          if (!action) {
            return "Debe seleccionar una opción!";
          } else {
            if (action) {
              deleteMenu(id, parseInt(action));
            }
          }
        },
      });
    } else {
      deleteMenu(id, 0);
    }
  };

  const showImpact4MenuDelete = (menu) => {
    setIsLoading(true);
    setIsOpen(true);
    RoleServices.getImpact4MenuDelete(
      { idMenu: menu.idMenu, idPerfil: externalID }
    )
      .then((res) => {
        if (res.success) {
          let itAffects = res.response.AffectedUsers > 0 ? true : false;
          selectApplyDelete(itAffects, res.response, menu.idMenu)
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoading(false);
        setIsOpen(false);
      });
  };

  const handleDelete = (menu) => {
    Swal.fire({
      title: "Advertencia",
      html: `Esta acción eliminará el privilegio de acceso al perfil de usuario. ¿Qué acción desea realizar?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aplicar",
      confirmButtonColor: "#1976d2",
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(res => {
      if (res.isConfirmed) {
        if (menu.submenus && menu.submenus.length > 0) {
          Swal.fire({
            title: "Acción no permitida",
            text: "El menú tiene submenus dependientes. Asegúrese de eliminar los submenús antes de realizar esta acción",
            icon: "warning"
          });
        } else {
          showImpact4MenuDelete(menu)
        }
      }
    });
  }

  const deleteMenu = (idMenu, action) => {
    setIsLoading(true);
    setIsOpen(true);
    RoleServices.deleteMenu(
      { idMenu: idMenu, idPerfil: externalID, Accion: action }
    )
      .then((res) => {
        if (res.success && res.results) {
          handleReloadList(externalID);
          Swal.fire({ title: res.message, icon: "success" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoading(false);
        setIsOpen(false);
      });
  }

  if (!middleware.checkMenuAction('Editar')) {
    return <MenusList
      menus={menus}
      type={"list"}
      maxHeight={maxHeight}
    />
  }

  return (
    <Box sx={{ width: "100%", overflow: "auto", ml: 0, mr: 0 }}>
      <TableContainer sx={{ maxHeight: maxHeight }}>
        {toolbar && <ComponentToolbar configToolbar={configToolbar} />}
        <Table stickyHeader aria-label="sticky table">
          <MenuRowHeader colSpan={3} />
          <TableBody>
            {menus.length > 0 &&
              menus.map((item, index) => (
                <MenuRow4Edit
                  key={Math.random()}
                  menu={item}
                  index={index}
                  handleSwitch={handleSwitch}
                  canDelete={middleware.checkMenuAction('Eliminar')}
                  handleDelete={handleDelete}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

UserRoleMenuList.propTypes = {
  menus: PropTypes.array.isRequired,
  maxHeight: PropTypes.number.isRequired,
  toolbar: PropTypes.bool.isRequired,
  ComponentToolbar: PropTypes.any,
  configToolbar: PropTypes.any,
  setIsOpen: PropTypes.func,
  setIsLoading: PropTypes.func,
  setIsSuccess: PropTypes.func,
  handleReloadList: PropTypes.func.isRequired,
  externalID: PropTypes.number,
};

UserRoleMenuList.defaultProps = {
  menus: [],
  maxHeight: 800,
  toolbar: false,
  configToolbar: {
    title: "",
    icon: "",
    btnAdd: true,
  },
};

export default UserRoleMenuList;
