import { useState, useEffect } from "react";

//Mui
import { Button, Card, CardContent, TextField } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

//Components
import FilterCollapse from "@components/Filters/FilterCollapse";
import BasicSelect from "@components/Selects/BasicSelect";

//Services
import sije from "@services/SijeService";
import Swal from "sweetalert2";

const Filter = ({ onChangeFilter, onClearFilter }) => {
  //Variables
  const catalogsOptions = [
    { id: "dfs" },
    { id: "secciones" },
    { id: "dls" },
    { id: "municipios_reportes" },
    { id: "rutas" },
  ];

  //States
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [filterData, setFilterData] = useState({
    DistritoLocal: "",
    DistritoFederal: "",
    Municipio: "",
    Ruta: "",
    Seccion: "",
    NombreRG: "",
    NombreRC: "",
    PaseListaRC: 9999,
    PaseListaRG: 9999,
  });

  const getCatalogs = async () => {
    try {
      setLoadingCatalogs((prevState) => !prevState);
      const result = await sije.getCatalogs({ catalogs: catalogsOptions });
      const { results, response, message } = await result;
      if (results) {
        setCatalogFiltered(response.catalogs);
        setCatalogs(response.catalogs);
      } else {
        throw message;
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs((prevState) => !prevState);
    }
  };

  const handleChangeFilter = (e) => {
    const key = e.target.name;
    setFilterData({ ...filterData, [key]: e.target.value });
  };

  const handleChangeDttoFederal = (e) => {
    const idDttoFederal = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      secciones: catalogs.secciones.filter(
        (item) => item.idDF === idDttoFederal
      ),
    }));
  };

  const handleChangeDttoLocal = (e) => {
    const idDttoLocal = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      secciones: catalogs.secciones.filter((item) => item.idDL === idDttoLocal),
    }));
  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      secciones: catalogs.secciones.filter(
        (item) => item.idMunicipioReportes === idMunicipality
      ),
    }));
  };

  const handleSearch = () => {
    let filtered = [];

    if (filterData.DistritoFederal > 0) {
      filtered = [
        ...filtered,
        {
          id: "DFED",
          filter: "=",
          value: filterData.DistritoFederal,
        },
      ];
    }

    if (filterData.DistritoLocal > 0) {
      filtered = [
        ...filtered,
        {
          id: "DLOC",
          filter: "=",
          value: filterData.DistritoLocal,
        },
      ];
    }

    if (filterData.Municipio > 0) {
      filtered = [
        ...filtered,
        {
          id: "idMunicipioReportes",
          filter: "=",
          value: filterData.Municipio,
        },
      ];
    }

    if (filterData.Ruta > 0) {
      filtered = [
        ...filtered,
        {
          id: "NombreRuta",
          filter: "=",
          value: filterData.Ruta,
        },
      ];
    }

    if (filterData.Seccion > 0) {
      filtered = [
        ...filtered,
        {
          id: "Seccion",
          filter: "=",
          value: filterData.Seccion,
        },
      ];
    }

    if (filterData.NombreRG.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "NombreRG",
          filter: "like",
          value: filterData.NombreRG,
        },
      ];
    }

    if (filterData.NombreRC.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "NombreRC",
          filter: "like",
          value: filterData.NombreRC,
        },
      ];
    }

    if (filterData.PaseListaRC !== 9999) {
      filtered = [
        ...filtered,
        {
          id: "PaseListaRC",
          filter: "=",
          value: filterData.PaseListaRC,
        },
      ];
    }

    if (filterData.PaseListaRG !== 9999) {
      filtered = [
        ...filtered,
        {
          id: "PaseListaRG",
          filter: "=",
          value: filterData.PaseListaRG,
        },
      ];
    }

    onChangeFilter({
      filtersData: filtered,
    });
  };

  const handleClearFilter = () => {
    const cleanFileds = {
      DistritoLocal: "",
      DistritoFederal: "",
      Municipio: "",
      Seccion: "",
      Ruta: "",
      NombreRG: "",
      NombreRC: "",
      PaseListaRC: 9999,
      PaseListaRG: 9999,
    };
    setCatalogFiltered(catalogs);
    setFilterData(cleanFileds);
    onClearFilter();
  };

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="card-primary">
      <CardContent>
        <FilterCollapse expand>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} md={4} lg={4}>
              <BasicSelect
                label="Distrito Federal"
                name="DistritoFederal"
                value={filterData.DistritoFederal}
                options={catalogsFiltered.dfs}
                onChange={(e) => {
                  handleChangeFilter(e);
                  handleChangeDttoFederal(e);
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={4} lg={4}>
              <BasicSelect
                label="Distrito Local"
                name="DistritoLocal"
                value={filterData.DistritoLocal}
                options={catalogsFiltered.dls}
                onChange={(e) => {
                  handleChangeFilter(e);
                  handleChangeDttoLocal(e);
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={4} lg={4}>
              <BasicSelect
                label="Municipio"
                name="Municipio"
                value={filterData.Municipio}
                options={catalogsFiltered.municipios_reportes}
                onChange={(e) => {
                  handleChangeFilter(e);
                  handleChangeMunicipality(e);
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} marginTop={1}>
            <Grid2 xs={12} md={8} lg={8}>
              <BasicSelect
                label="Ruta"
                name="Ruta"
                value={filterData.Ruta}
                options={catalogsFiltered.rutas}
                onChange={handleChangeFilter}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>

            <Grid2 xs={12} md={4} lg={4}>
              <BasicSelect
                label="Sección"
                name="Seccion"
                value={filterData.Seccion}
                options={catalogsFiltered.secciones.map((item) => {
                  return { value: item.value, label: String(item.label) };
                })}
                onChange={handleChangeFilter}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} marginTop={1}>
            <Grid2 xs={12} md={6} lg={6}>
              <TextField
                label="Nombre RG"
                name="NombreRG"
                value={filterData.NombreRG.toUpperCase()}
                size="small"
                onChange={handleChangeFilter}
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={6} lg={6}>
              <TextField
                label="Nombre RC"
                name="NombreRC"
                value={filterData.NombreRC.toUpperCase()}
                size="small"
                onChange={handleChangeFilter}
                sx={{ width: "100%" }}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} marginTop={1}>
            <Grid2 xs={12} md={5} lg={5}>
              <BasicSelect
                label="Pase Lista RC"
                name="PaseListaRC"
                value={filterData.PaseListaRC}
                options={[
                  { label: "Todos", value: 9999 },
                  { value: 0, label: "NO TIENE" },
                  { value: 1, label: "TIENE" },
                ]}
                onChange={handleChangeFilter}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={5} lg={5}>
              <BasicSelect
                label="Pase Lista RG"
                name="PaseListaRG"
                value={filterData.PaseListaRG}
                options={[
                  { label: "Todos", value: 9999 },
                  { value: 0, label: "NO TIENE" },
                  { value: 1, label: "TIENE" },
                ]}
                onChange={handleChangeFilter}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={1} lg={1}>
              <Button
                variant="contained"
                color="primaryDark"
                sx={{ width: "100%" }}
                onClick={handleSearch}
              >
                Filtrar
              </Button>
            </Grid2>
            <Grid2 xs={12} md={1} lg={1}>
              <Button
                color="primaryDark"
                sx={{ width: "100%" }}
                onClick={handleClearFilter}
              >
                Limpiar
              </Button>
            </Grid2>
          </Grid2>
        </FilterCollapse>
      </CardContent>
    </Card>
  );
};

export default Filter;
