const CapturaInterface = {
  seccion: "",
  municipio: "",
  casilla: "",
  reportaRCRG: "",
  hora: "",
  representante: "",
  idUser: "",
  NumFuncionarios: "",
  TomadosFila: "",
};

const CapturaIncidenciaInterface = {
  seccion: "",
  idCasilla: "",
  idIncidencia: "",
  DescripcionIncidencia: "",
  AsentadoEnActa: 0,
  HoraIncidencia: "",
  esResuelto: 0,
  ComoSeResolvio: "",
  HoraSolucion: "",
 // idStatus: "",
};

export { CapturaInterface, CapturaIncidenciaInterface };
