import { PrivilegedRoute } from "@middlewares";

import List from "@pages/FrenteAmplio/List";
import Form from "@pages/FrenteAmplio/Form";

const FrenteAmplioRoutes = [
  {
    path: "/frente-list",
    element: (
      <PrivilegedRoute>
        <List />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/frente-captura",
    element: (
      <PrivilegedRoute>
        <Form />
      </PrivilegedRoute>
    ),
  }
];

export default FrenteAmplioRoutes;
