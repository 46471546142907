import { useEffect, useState } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";

import { blue } from "@mui/material/colors";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import CardLayout from "./CardLayout";
import SummaryChart from "./SummaryChart";
import BadgeLabel from "./BadgeLabel";

const CasillaCard = ({ data, isLoading }) => {
  const { height } = useWindowDimensions();
  const [total, setTotal] = useState({
    CasillasInstaladas: 0,
    CasillasAprobadas: 0,
    CasillasAperturadas: 0,
    CasillasCerradas: 0,
    VotacionCerrada: 0,
  });

  const [dataChart, setDataChart] = useState({
    data: [0, 0, 0],
    height: height * 0.25,
  });

  useEffect(() => {
    setTotal(data);
    setDataChart({
      ...dataChart,
      data: [
        { y: data.CasillasAprobadas, color: blue[900] }, //green[800]
        { y: data.CasillasInstaladas, color: blue[800] }, //blue[800]
        { y: data.CasillasAperturadas, color: blue[700] }, //blue[800]
        { y: data.CasillasCerradas, color: blue[600] }, //orange[800]
        { y: data.VotacionCerrada, color: blue[500] }, //red[800]
      ],
      loading: isLoading,
    });
  }, [data]);

  return (
    <CardLayout
      title={"CASILLAS"}
      bgColor={blue[50]}
      color={blue[800]}
      icon={"home"}
      //sx={{ height: { md: "90%" } }}
    >
      <Grid2 container>
        <Grid2 xs={12} md={7}>
          <SummaryChart data={dataChart} />
        </Grid2>
        <Grid2 xs={12} md={5}>
          <BadgeLabel
            total={total.CasillasAprobadas}
            label={"Aprobadas"}
            color={blue[900]}
          />
          <BadgeLabel
            total={total.CasillasInstaladas}
            label={"Instaladas"}
            color={blue[800]}
          />
          <BadgeLabel
            total={total.CasillasAperturadas}
            label={"Aperturadas"}
            color={blue[700]}
          />
          <BadgeLabel
            total={total.CasillasCerradas}
            label={"Casillas Cerradas"}
            color={blue[600]}
          />
          <BadgeLabel
            total={total.VotacionCerrada}
            label={"Cierre Votación"}
            color={blue[500]}
          />
        </Grid2>
      </Grid2>
    </CardLayout>
  );
};

export default CasillaCard;

/* 
 <Grid2 container spacing={2}>
        <Grid2 xs={12} md={4}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Badge color="secondary" variant="dot" />
            <ListItemText
              primary=<Typography variant="body2">Aprobadas</Typography>
            />
          </Stack>
        </Grid2>
        <Grid2 xs={12} md={4}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Badge color="secondary" variant="dot" />
            <ListItemText
              primary=<Typography variant="body2">Instaladas</Typography>
            />
          </Stack>
        </Grid2>
        <Grid2 xs={12} md={4}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Badge color="secondary" variant="dot" />
            <ListItemText
              primary=<Typography variant="body2">Aperturadas</Typography>
            />
          </Stack>
        </Grid2>
      </Grid2>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={6}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Badge color="secondary" variant="dot" />
            <ListItemText
              primary=<Typography variant="body2">Casillas Cerradas</Typography>
            />
          </Stack>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Badge color="secondary" variant="dot" />
            <ListItemText
              primary=<Typography variant="body2">Cierre Votación</Typography>
            />
          </Stack>
        </Grid2>
      </Grid2>

*/
