import { ApiExec, ApiExecPublic } from "@utils/ApiExec";

class ElectoralService {

    getList = (params,api) => {
        return ApiExec(params, `electoral/${api}`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };
    

}

const electoral = new ElectoralService();
export default electoral;
