import React, { useState, useEffect } from "react";
import { Grid, Button, Stack } from "@mui/material";

import CatalogService from "@services/CatalogServices";
import BasicSelect from "@components/Selects/BasicSelect";
import Swal from "sweetalert2";
import { setVars, getVars } from "@utils/global";
const Filter = ({
  catalogs,
  loadingCatalogs,
  handleFilter
}) => {
  const [catDLOC, setCatDLOC] = useState([]);
  const [catDFED, setCatDFED] = useState([]);
  const [isLoadingCatDFED, setIsLoadingCatDFED] = useState(false);
  const [isLoadingCatDLOC, setIsLoadingCatDLOC] = useState(false);
  const [filter, setFilter] = useState({
    idMunicipioReportes: "",
    idDF: "",
    idDL: "",
  });
  useEffect(
    () => {
      let filter_saved = getVars("filterRoutes",{
        idMunicipioReportes: "",
        idDF: "",
        idDL: "",
      });
      if(filter_saved.idMunicipioReportes !== "" || filter_saved.idDF !== "" || filter_saved.idDL !== ""){
        setFilter(filter_saved);
        if(filter_saved.idMunicipioReportes !== "") loadCatalogsByMunicipio(filter_saved.idMunicipioReportes);
        if(filter_saved.idDF !== "") loadCatalogsByDFED(filter_saved.idDF);
      }
    },
    // eslint-disable-next-line
    []
  );
  const loadCatalogsByMunicipio = (idMunicipioReportes) => {
    let catalogsParams = [
      {
        id: "df",
        filtered: [{ id: "idMunicipioReportes", filter: "=", value: idMunicipioReportes }],
      },
      {
        id: "dl",
        filtered: [{ id: "idMunicipioReportes", filter: "=", value: idMunicipioReportes }],
      },
    ];

    setIsLoadingCatDFED(true);
    setIsLoadingCatDLOC(true);

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatDFED(res.response.catalogs.df);
          setCatDLOC(res.response.catalogs.dl);
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {setIsLoadingCatDFED(false);setIsLoadingCatDLOC(false);});
  };
  const loadCatalogsByDFED = (idDF) => {
    let catalogsParams = [
      {
        id: "dl",
        filtered: [
          { id: "idMunicipioReportes", filter: "=", value: filter.idMunicipioReportes },
          { id: "idDF", filter: "=", value: idDF },
        ],
      },
    ];
    setIsLoadingCatDLOC(true);

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatDLOC(res.response.catalogs.dl);
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setIsLoadingCatDLOC(false));
  };

  const handleChangeMunicipio = (e) => {
    setVars("flagCreatePolygon",false);
    const value = e.target.value;
    setFilter({
      idMunicipioReportes: value,
      idDF: "",
      idDL: "",
    });
    setVars("filterRoutes",{
      idMunicipioReportes:value,
      idDF: "",
      idDL: "",
    });

    loadCatalogsByMunicipio(value);
  };
  const handleChangeDFED = (e) => {
    setVars("flagCreatePolygon",false);
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
    loadCatalogsByDFED(e.target.value);
  };
  const handleChangeDLOC = (e) => {
    setVars("flagCreatePolygon",false);
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };
  const handleClick = () => {
    let filter_data = [];
    if(filter.idMunicipioReportes && filter.idMunicipioReportes !== ""){
      filter_data.push({id:"cat_secciones.idMunicipioReportes", filter:"=", value:filter.idMunicipioReportes});
    }
    if(filter.idDF && filter.idDF !== ""){
      filter_data.push({id:"cat_secciones.idDF", filter:"=", value:filter.idDF});
    }
    if(filter.idDL && filter.idDL !== ""){
      filter_data.push({id:"cat_secciones.idDL", filter:"=", value:filter.idDL});
    } 
    if(filter.idMunicipioReportes !== "" && filter.idDF/*  !== "" && filter.idDL !== "" */){
      setVars("flagCreatePolygon",true);
    }
    handleFilter(filter_data);
    setVars("filterRoutes",{
      idMunicipioReportes:filter.idMunicipioReportes,
      idDF: filter.idDF,
      idDL: filter.idDL
    });
  };
  return (
    <>
      <Grid container rowSpacing={1} style={{ width: "200px" }}>
        <Grid item sm={12}>
          <BasicSelect
            name="idMunicipioReportes"
            label="Muncipio *"
            options={catalogs.municipios}
            value={filter.idMunicipioReportes}
            onChange={handleChangeMunicipio}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item sm={12}>
          <BasicSelect
            name="idDF"
            label="DFED *"
            options={catDFED}
            value={filter.idDF}
            onChange={handleChangeDFED}
            isLoading={isLoadingCatDFED}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item sm={12}>
          <BasicSelect
            name="idDL"
            label="DLOC *"
            options={catDLOC}
            value={filter.idDL}
            onChange={handleChangeDLOC}
            isLoading={isLoadingCatDLOC}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <br></br>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Button
          size="small"
          style={{ borderRadius: "20px", alignSelf: "center" }}
          variant="outlined"
          disabled={(filter.idMunicipioReportes === "" && filter.idDF === "" && filter.idDL === "")?true:false}
          onClick={handleClick}
        >
          Filtrar
        </Button>
      </Stack>
    </>
  );
};
export default Filter;
