import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  Stack,
  Button,
  InputAdornment,
  Icon,
  Alert,
  CircularProgress,
} from "@mui/material";

import BasicSelect from "@components/Selects/BasicSelect";

import sije from "@services/SijeService";
import { CapturaInterface } from "@data/interfaces/SijeInterface";
import { CapturaSchema } from "@data/schemas/SijeSchema";
import { isEmptyOrInvalidString, isTypePhone } from "@utils/validations";

const CaptureForm = ({
  sx,
  title,
  labelHora,
  labelRc = "Reporta (RC/RG)",
  isNumeroFuncionario = false,
  isFila = false,
  handleSave,
  loading,
  //isRc = false,
}) => {
  const [catCasillas, setCatCasillas] = useState([]);
  const [catRcRg, setCatRcRg] = useState([]);
  const [userFound, setUserFound] = useState({
    success: null,
    message: "",
    severity: "",
    found: null,
  });
  const [loadCasillas, setLoadCasillas] = useState(false);
  const [loadRcRg, setLoadRcRg] = useState(false);
  const [loadSearchUser, setLoadSearchUser] = useState(false);

  const formik = useFormik({
    initialValues: CapturaInterface,
    validationSchema: CapturaSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  const handleChangeSection = async (e) => {
    const section = e.target.value;
    try {
      setLoadCasillas((prevState) => !prevState);
      const result = await sije.getCasillasBySeccion({ Seccion: section });
      const { response, results, message } = await result;
      if (results) {
        const municipio = response.data.map((item) => item.Municipio);
        const valueMunicipio = [...new Set(municipio)];
        const casillas = response.data.map((item) => {
          return {
            label: item.NombreCasilla,
            value: item.idCasilla,
          };
        });
        setCatCasillas(casillas);
        formik.setValues({
          seccion: section,
          municipio: valueMunicipio[0],
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadCasillas((prevState) => !prevState);
    }
  };

  const handleChangeCasilla = async (e) => {
    const casilla = e.target.value;
    try {
      setLoadRcRg((prevState) => !prevState);
      const result = await sije.getRepresentantesCasillas({
        idCasilla: casilla,
      });
      const { response, results, message } = await result;
      if (results) {
        setCatRcRg(response.data);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadRcRg((prevState) => !prevState);
    }
  };

  const handleValidateUserName = async () => {
    const number = formik.values.representante;
    if (number.length > 0 && number.length === 10) {
      try {
        setLoadSearchUser((prevState) => !prevState);
        const result = await sije.validateUsername({
          Username: number,
        });
        const { response, results } = await result;
        if (results) {
          formik.setValues({
            ...formik.values,
            idUser: response.data[0].id,
          });
          setUserFound({
            success: true,
            message: `Usuario encontrado - ${response.data[0].Nombre}`,
            severity: "success",
            found: true,
          });
        } else {
          setUserFound({
            success: false,
            message: "Usuario no encontrado",
            severity: "error",
            found: false,
          });
        }
      } catch (error) {
        Swal.fire({
          title: error,
          icon: "warning",
        });
      } finally {
        setLoadSearchUser((prevState) => !prevState);
      }
    }
  };

  const handleOnkeyDown = (event) => {
    const name = event.target.name;
    if (event.keyCode === 9) {
      event.preventDefault();
      name === "seccion"
        ? handleChangeSection(event)
        : handleValidateUserName();
    }
  };

  const handleOnkeyPress = (event) => {
    const name = event.target.name;
    if (event.key === "Enter") {
      event.preventDefault();
      name === "seccion"
        ? handleChangeSection(event)
        : handleValidateUserName();
    }
  };

  const handleClearForm = () => {
    formik.setValues({
      seccion: "",
      municipio: "",
      casilla: "",
      reportaRCRG: "",
      hora: "",
      representante: "",
      idUser: "",
      NumFuncionarios: "",
      TomadosFila: "",
    });

    setUserFound({
      success: null,
      message: "",
      severity: "",
      found: null,
    });

    setCatRcRg([]);
    setCatCasillas([]);
  };

  useEffect(() => {
    if (loading === false) {
      handleClearForm();
    }
    //eslint-disable-next-line
  }, [loading]);

  return (
    <Card className="card-primary" sx={sx}>
      <CardContent>
        <Typography textAlign={"center"} variant="body2" fontWeight={600}>
          {title}
        </Typography>
        <Typography textAlign={"center"} variant="caption">
          Escribe la sección y posteriormente presiona enter para continuar con
          la captura.
        </Typography>

        <Box component={"form"} marginTop={2}>
          <TextField
            label="Sección"
            name="seccion"
            onChange={(e) =>
              formik.handleChange({
                target: {
                  name: "seccion",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : formik.values.seccion,
                },
              })
            }
            onKeyDown={handleOnkeyDown}
            onKeyUp={handleOnkeyPress}
            value={formik.values.seccion}
            size="small"
            sx={{ width: "100%", mb: 3 }}
          />
          <TextField
            disabled
            label="Municipio"
            name="municipio"
            value={formik.values.municipio}
            size="small"
            sx={{ width: "100%", mb: 3 }}
          />
          <BasicSelect
            label="Casilla"
            name="casilla"
            options={catCasillas}
            value={formik.values.casilla}
            onChange={(e) => {
              formik.handleChange({
                target: {
                  name: "casilla",
                  value: e.target.value,
                },
              });
              handleChangeCasilla(e);
            }}
            isLoading={loadCasillas}
            sx={{ width: "100%", mb: 3 }}
          />
          <BasicSelect
            label={labelRc}
            name="reportaRCRG"
            options={catRcRg}
            value={formik.values.reportaRCRG}
            onChange={formik.handleChange}
            isLoading={loadRcRg}
            sx={{ width: "100%", mb: 3 }}
          />
          {formik.values.reportaRCRG === 9999 && (
            <>
              <TextField
                label="Busca al usuario por el número de celular"
                name="representante"
                value={formik.values.representante}
                onChange={formik.handleChange}
                //onBlur={handleValidateUserName}
                onKeyDown={handleOnkeyDown}
                onKeyUp={handleOnkeyPress}
                size="small"
                sx={{ width: "100%" }}
              />
              {loadSearchUser && (
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Typography variant="body2" fontWeight={300}>
                    Buscando...
                  </Typography>
                  <CircularProgress size={10} />
                </Box>
              )}
              {userFound.found !== null ? (
                <Alert severity={userFound.severity} sx={{ mb: 3, mt: 1 }}>
                  {userFound.message}
                </Alert>
              ) : (
                <Alert
                  variant="outlined"
                  severity={"info"}
                  sx={{ mb: 3, mt: 1, padding: "0px 10px" }}
                >
                  Escribe el número de celular y presiona enter para realizar la
                  busqueda.
                </Alert>
              )}
            </>
          )}
          {/* {isReporto && (
            <TextField
              label="Reportó"
              name="reporto"
              value={formik.values.reporto}
              onChange={formik.handleChange}
              size="small"
              sx={{ width: "100%", mb: 3 }}
            />
          )} */}
          {isNumeroFuncionario && (
            <TextField
              label="Número de funcionario de casilla presente"
              name="NumFuncionarios"
              onChange={(e) =>
                formik.handleChange({
                  target: {
                    name: "NumFuncionarios",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : formik.values.NumFuncionarios,
                  },
                })
              }
              value={formik.values.NumFuncionarios}
              size="small"
              sx={{ width: "100%", mb: 3 }}
            />
          )}
          {isFila && (
            <TextField
              label="¿Cuántos fueron tomados de la fila?"
              name="TomadosFila"
              onChange={(e) =>
                formik.handleChange({
                  target: {
                    name: "TomadosFila",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : formik.values.TomadosFila,
                  },
                })
              }
              value={formik.values.TomadosFila}
              size="small"
              sx={{ width: "100%", mb: 3 }}
            />
          )}
          <TextField
            label={labelHora}
            name="hora"
            value={formik.values.hora}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            size="small"
            sx={{ width: "100%", mb: 3 }}
            InputProps={{
              size: "small",
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>schedule</Icon>
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.hora && !isEmptyOrInvalidString(formik.errors.hora)
            }
            helperText={
              formik.touched.hora && formik.errors.hora && formik.errors.hora
            }
          />
          <Stack
            direction={"row"}
            marginTop={4}
            spacing={2}
            justifyContent={"center"}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={handleClearForm}
              color="primaryDark"
              disabled={formik.values.seccion.length === 0}
            >
              Limpiar
            </Button>
            <Button
              variant="contained"
              color="primaryDark"
              onClick={formik.submitForm}
              disabled={
                formik.values.seccion.length === 0 || userFound.found === false
              }
            >
              Guardar
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CaptureForm;
