import { Public } from "@middlewares";

import LoginSian from "@pages/auth/LoginSian";
import Confirmation from "@pages/auth/Confirmation";
import Register from "../../pages/auth/Register";

const AuthRoutes = [
  {
    path: "/login",
    element: (
      <Public>
        <LoginSian />
      </Public>
    ),
  },
  {
    path: "/confirmar-celular/:id",
    element: (
      <Public>
        <Confirmation />
      </Public>
    ),
  },
  {
    path: "/invitado/:id",
    element: (
      <Public>
        <Register />
      </Public>
    ),
  },
];

export default AuthRoutes;
