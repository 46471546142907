import ApiExec, { downloadFile } from "@utils/ApiExec";

class RcoService {
  getInfoRcos = (params) => {
    return ApiExec(params, "rcos/get-info-rcos", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
}
const rco = new RcoService();
export default rco;
