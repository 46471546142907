import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircularProgress } from "@mui/material";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
    numericSymbols: null,
  },
});

const PlacementColumns = (props) => {
  const { datos, titulo, subtitle, loading, height } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      chart: {
        type: "column",
        height: height,
      },
      title: {
        text: titulo.title,
      },
      subtitle: {
        text: subtitle.subtitle,
      },
      xAxis: { categories: datos.categories },
      yAxis: [
        {
          min: 0,
          title: {
            text: titulo.right,
          },
        },
        {
          title: {
            text: titulo.left,
          },
          opposite: true,
        },
      ],
      legend: {
        shadow: false,
      },
      tooltip: {
        shared: true,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            style: { fontSize: "8px" },
          },
        },
      },
      series: datos.series,
    });
    // eslint-disable-next-line
  }, [datos, titulo]);

  return data !== null || loading ? (
    <HighchartsReact highcharts={Highcharts} options={data} />
  ) : (
    <CircularProgress />
  );
};

export default PlacementColumns;
