import React, { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";
import { Box, TextField, Grid, Stack, Button, Icon } from "@mui/material";
import BasicSelect from "@components/Selects/BasicSelect";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import Yup from "@utils/Yupi18n";

import ActionContainer from "@components/Containers/ActionContainer";
import InputSelect from "@components/Selects/BasicSelect";
import UserRoleServices from "@services/RoleServices";
import { isEmptyOrInvalidString } from "@utils/validations";

const UserRoleForm = (props) => {
  const { handleCloseModal, handleReloadList, data } = props;

  const { catalogs, load, errors } = useCatalogs({
    catalogsOptions: [
      {
        id: "tipo_perfil",
        getAll: false,
      },
      {
        id: "ambito",
        getAll: false,
      },
    ],
    putDefaultOption: false,
  });
  const [catProfileType, setCatProfileType] = useState([]);

  const formik = useFormik({
    initialValues: data,
    validationSchema: Yup.object({
      Perfil: Yup.string().required().min(4),
      Descripcion: Yup.string().optional().nullable(true),
      idTipo: Yup.number().required(),
      idAmbitoCargo: Yup.number().required(),
      EtiquetaCargo: Yup.string().required(),
    }),
    onSubmit: (values) => {
      if (values.id === null) {
        delete values.id;
        handleSave(values);
      } else {
        handleUpdate(values);
      }
    },
  });

  useEffect(() => {
    if (!load) setCatProfileType(catalogs.tipo_perfil);

    // eslint-disable-next-line
  }, [load]);

  const handleSave = (values) => {
    UserRoleServices.setRole(values)
      .then((res) => {
        if (res.results) {
          handleCloseModal();
          Swal.fire({
            title: res.message,
            icon: "success",
            customClass: {
              container: "modal-alert",
            },
          }).then((res) => {
            if (res) {
              handleCleanForm();
              handleReloadList();
            }
          });
        } else {
          Swal.fire({
            title: res.message,
            icon: "warning",
            customClass: {
              container: "modal-alert",
            },
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
          customClass: {
            container: "modal-alert",
          },
        });
      });
  };

  const handleUpdate = (values) => {
    UserRoleServices.updRole(values)
      .then((res) => {
        if (res.results) {
          handleCloseModal();
          Swal.fire({
            title: res.message,
            icon: "success",
            customClass: {
              container: "modal-alert",
            },
          }).then((res) => {
            if (res) {
              handleCleanForm();
              handleReloadList();
            }
          });
        } else {
          Swal.fire({
            title: res.message,
            icon: "warning",
            customClass: {
              container: "modal-alert",
            },
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
          customClass: {
            container: "modal-alert",
          },
        });
      });
  };

  const handleCleanForm = () => {
    formik.setValues({
      Perfil: "",
      Descripcion: "",
    });
    handleCloseModal();
  };

 
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <InputSelect
          label="Tipo de perfil"
          options={catProfileType}
          name="idTipo"
          value={formik.values.idTipo}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          sx={{ width: "100%" }}
          error={
            formik.touched.idTipo &&
            !isEmptyOrInvalidString(formik.errors.idTipo)
          }
          errorMessage={formik.errors.idPerfil}
          isLoading={load}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <BasicSelect
          error={
            formik.touched.idAmbitoCargo &&
            !isEmptyOrInvalidString(formik.errors.idAmbitoCargo)
          }
          label={`Ambito del Cargo`}
          name="idAmbitoCargo"
          value={formik.values.idAmbitoCargo}
          options={catalogs.ambito}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          size="small"
          sx={{ width: "100%" }}
          errorMessage={formik.errors.idAmbitoCargo}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          error={
            formik.touched.Perfil &&
            !isEmptyOrInvalidString(formik.errors.Perfil)
          }
          label="Nombre del Perfil (sistema)"
          helperText={
            formik.touched.Perfil &&
            formik.errors.Perfil &&
            formik.errors.Perfil
          }
          type="text"
          name="Perfil"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.Perfil}
          variant="standard"
          className="fixed-input"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          error={
            formik.touched.EtiquetaCargo &&
            !isEmptyOrInvalidString(formik.errors.EtiquetaCargo)
          }
          label="Etiqueta del cargo"
          helperText={
            formik.touched.EtiquetaCargo &&
            formik.errors.EtiquetaCargo &&
            formik.errors.EtiquetaCargo
          }
          type="text"
          name="EtiquetaCargo"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={
            formik.values.EtiquetaCargo &&
            formik.values.EtiquetaCargo.length > 0 &&
            formik.values.EtiquetaCargo.toUpperCase()
          }
          variant="standard"
          className="fixed-input"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          error={
            formik.touched.Descripcion &&
            !isEmptyOrInvalidString(formik.errors.Descripcion)
          }
          label="Descripcion"
          helperText={
            formik.touched.Descripcion &&
            formik.errors.Descripcion &&
            formik.errors.Descripcion
          }
          type="text"
          multiline
          rows={4}
          name="Descripcion"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.Descripcion}
          className="fixed-input"
          placeholder="Especificar a qué tipo de usuario corresponde"
        />
      </Grid>
      </Grid>
      <ActionContainer>
        <Stack direction="row" spacing={2}>
          <Button
            color="error"
            variant="outlined"
            size="small"
            startIcon={<Icon>close</Icon>}
            onClick={handleCleanForm}
          >
            Cerrar
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            startIcon={<Icon>save</Icon>}
            onClick={() => formik.submitForm()}
          >
            Guardar
          </Button>
        </Stack>
      </ActionContainer>
    </Box>
  );
};

UserRoleForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func,
  handleReloadList: PropTypes.func,
};

UserRoleForm.defaultProps = {
  data: {
    id: null,
    Perfil: "",
    Descripcion: "",
  },
};

export default UserRoleForm;
