import React, { useState, useEffect } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import UserRoleService from "@services/RoleServices";
import { getVars } from "@utils/global";
import LoadingForm from "@components/LinearProgress/LoadingForm";

const UsersList = props => {
    const { data } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [isLoadigForm, setIsLoadingForm] = useState(false);
    const [isSuccessForm] = useState(false);
    const [openLinearProgress, setOpenLinearProgress] = useState(false);

    const [access_token] = useState(() => {
        let t = getVars("Token");
        if (t !== {})
            return t.access_token;
        return "";
    });

    useEffect(() => {
        setIsLoadingForm(true);
        setOpenLinearProgress(true);
        const params = {
            page: 0,
            pageSize: 10,
            idPerfil: data.id,
            filtered: [],
            sorted: [],
        };
        UserRoleService.getUsers(params, access_token)
            .then(res => {
                if (res.results) {
                    setRows(res.response.data);
                } else {
                    setRows([])
                }
            })
            .catch(error => {
                setRows([])
            }).finally(() => {
                setIsLoadingForm(false);
                setOpenLinearProgress(false);
            });
        // eslint-disable-next-line
    }, [data, access_token]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Box sx={{ width: "100%", p: 2 }}>
            <LoadingForm
                isLoading={isLoadigForm}
                success={isSuccessForm}
                isOpen={openLinearProgress}
                setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
                loadinMessage={"Cargando..."}
            />
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={6}>
                                <Typography variant="h6">
                                    {data.Perfil}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ fontSize: "0.7rem" }}>
                            <TableCell align="center">Nombre</TableCell>
                            {/* <TableCell align="center">Página de inicio</TableCell> */}
                            <TableCell align="center">Estatus</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => {
                            return (
                                <TableRow
                                    key={i}
                                    sx={{
                                        "& > *": { fontSize: "0.75rem" }
                                    }}
                                >
                                    <TableCell>{row.NombreCompleto}</TableCell>
                                    {/* <TableCell>{row.DefaultPage}</TableCell> */}
                                    <TableCell>{row.Estatus}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Registros por página"
            />
        </Box>
    );
};

export default UsersList;
