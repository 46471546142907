import { WhatsApp } from "@mui/icons-material";
import { Icon } from "@mui/material";

export const COLUMNS_OPERATION = [
  {
    id: "idDF",
    label: "DF",
  },
  { id: "idDL", label: "DL" },
  { id: "Municipio", label: "MUNICIPIO" },
  { id: "Seccion", label: "SECCIÓN" },
  { id: "NombreCasilla", label: "CASILLA" },
  { id: "Conectividad", label: "CONECTIVIDAD" },
  { id: "TipoTransmision", label: "TIPO DE TRANSMISIÓN" },
  { id: "HoraPaseListaRG", label: "ASIST.RG" },
  { id: "HoraPaseListaP1", label: "RCP1" },
  { id: "HoraPaseListaP2", label: "RCP2" },
  { id: "HoraPaseListaS1", label: "RCS1" },
  { id: "HoraPaseListaS2", label: "RCS2" },
  { id: "HoraInicioInstalacion", label: "INSTALACIÓN" },
  { id: "HoraAperturaCasilla", label: "APERTURA" },
  { id: "HoraCierreCasilla", label: "CIERRE CASILLA" },
  { id: "HoraCierreVotacion", label: "CIERRE VOTACIÓN" },
  { id: "TotalIncidencias", label: "INCIDENCIA", align: "center" },
];

export const COLUMNS_APERTURA = [
  {
    id: "idDF",
    label: "DF",
  },
  { id: "idDL", label: "DL" },
  { id: "Municipio", label: "MUNICIPIO" },
  { id: "Seccion", label: "SECCION" },
  { id: "NombreCasilla", label: "CASILLA" },
  { id: "Conectividad", label: "CONECTIVIDAD" },
  { id: "TipoTransmision", label: "TIPO DE TRANSMISIÓN" },
  { id: "RCP1", label: "RCP1" },
  { id: "RCP2", label: "RCP2" },
  { id: "RCS1", label: "RCS1" },
  { id: "RCS2", label: "RCS2" },
  { id: "HoraInicioInstalacion", label: "INSTALACIÓN" },
  { id: "HoraAperturaCasilla", label: "APERTURA" },
];

export const COLUMNS_CIERRE = [
  {
    id: "idDF",
    label: "DF",
  },
  { id: "idDL", label: "DL" },
  { id: "Municipio", label: "MUNICIPIO" },
  { id: "Seccion", label: "SECCION" },
  { id: "NombreCasilla", label: "CASILLA" },
  { id: "Conectividad", label: "CONECTIVIDAD" },
  { id: "TipoTransmision", label: "TIPO DE TRANSMISIÓN" },
  { id: "RCP1", label: "RCP1" },
  { id: "RCP2", label: "RCP2" },
  { id: "RCS1", label: "RCS1" },
  { id: "RCS2", label: "RCS2" },
  { id: "HoraCierreCasilla", label: "Cierre Casilla" },
  { id: "HoraCierreVotacion", label: "Cierre Votacion" },
];

export const COLUMNS_INCIDENTS = [
  { id: "Municipio", label: "Municipio" },
  { id: "Seccion", label: "Sección" },
  { id: "NombreCasilla", label: "Casilla" },
  { id: "HoraIncidencia", label: "Hora Incidencia" },
  { id: "Incidencia", label: "Incidencia" },
  { id: "Resuelto", label: "Solucionado" },
  { id: "HoraSolucion", label: "Hora Solucion" },
  { id: "Estatus", label: "Estatus" },
];

export const CAT_STATUS = [
  { value: 1, label: "Nueva" },
  { value: 2, label: "En proceso" },
  { value: 3, label: "Atendido" },
];

export const CAT_SOLVED = [
  { value: 9999, label: "Todos" },
  { value: 0, label: "NO" },
  { value: 1, label: "SI" },
];

export const ACTIONS_SPEED = [
  { icon: <Icon>smartphone</Icon>, name: "App", type: "app" },
  { icon: <Icon>call</Icon>, name: "Teléfono", type: "telefono" },
  { icon: <WhatsApp />, name: "Whatsapp", type: "whatsapp" },
  { icon: <Icon>edit_square</Icon>, name: "Papel", type: "papel" },
];

export const COLUMNS_REPORTS = [
  {
    id: "Region",
    label: "Región",
  },
  { id: "Municipio", label: "Municipio" },
  { id: "DistritoFederal", label: "Distrito Federal" },
  { id: "DistritoLocal", label: "Distrito Local" },
  { id: "Seccion", label: "Sección" },
  { id: "NombreCasilla", label: "Nombre Casilla" },
  { id: "Casillas", label: "Casillas" },
  { id: "AsistenciaP1", label: "Asistencia P1" },
  { id: "PorcentajeAbiertoP1", label: "% Abierto" },
  { id: "NoAsistenciaP1", label: "No. Asitencia P1" },
  { id: "AsistenciaP2", label: "Asistencia P2" },
  { id: "PorcentajeAbiertoP2", label: "% Abierto" },
  { id: "NoAsistenciaP2", label: "No. Asitencia P2" },
  { id: "AsistenciaS1", label: "Asistencia S1" },
  { id: "PorcentajeAbiertoS1", label: "% Abierto" },
  { id: "NoAsistenciaS1", label: "No. Asitencia S1" },
  { id: "AsistenciaS2", label: "Asistencia S2" },
  { id: "PorcentajeAbiertoS2", label: "% Abierto" },
  { id: "NoAsistenciaS2", label: "No. Asitencia S2" },
];

export const COLUMNS_DIRECTORY = [
  { id: "DFED", label: "DF" },
  { id: "DLOC", label: "DL" },
  { id: "Seccion", label: "Sección" },
  { id: "Municipio", label: "Municipio" },
  { id: "NombreRuta", label: "Ruta" },
  { id: "NombreCasilla", label: "Casilla" },
  { id: "NombreRG", label: "RG" },
  { id: "NombreRCP1", label: "RCP1" },
  { id: "NombreRCP2", label: "RCP2" },
  { id: "NombreRCS1", label: "RCS1" },
  { id: "NombreRCS2", label: "RCS2" },
  { id: "PaseListaRC", label: "Pase Lista RC" },
  { id: "PaseListaRG", label: "Pase Lista RG" },
];
