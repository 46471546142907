import { useEffect, useState } from "react";
import Swal from "sweetalert2";
//Mui
import {
   Box,
   Card,
   CardContent,
   FormControlLabel,
   Icon,
   Radio,
   RadioGroup,
   Tooltip,
   Typography,
   FormControl,
   Button
} from "@mui/material";
import { CameraAlt } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import BasicTableCustomizing from "@components/Tables/BasicTableCustomizing";
import MessageAlert from "@components/Downloads/MessageAlert";
import Download from "@components/Downloads/Download";

import { URL_IMAGES } from "@utils/global";
import prep from "@services/PrepServices";
import { isEmptyOrNullObject, isNullOrUndefined } from "@utils/validations";
import { numberWithCommas } from "@utils/Utilities";

const PrepTable = props => {
   const { title, params, setLoadingFilter } = props;
   const [loadingTable, setLoadingTable] = useState(true);
   const [paramsTable, setParamsTable] = useState({
      page: 0,
      pageSize: 25,
      filtered: []
   });
   const [dataTable, setDataTable] = useState({
      columns: [],
      data: [],
      total: 0
   });
   const [sortedData, setSortedData] = useState([]);
   const [selectedRadio, setSelectedRadio] = useState(2);
   const [isDownloading, setIsDownloading] = useState(false);
   const [showMessage, setShowMessage] = useState(false);
   const [successDownload, setSuccessDownload] = useState(false);

   useEffect(() => {
      if (params) {
         setParamsTable((prevState) => ({ ...prevState, sorted: [], page: 0 }));
         // setDataTable((prevState) => ({ ...prevState, total: 0 }))
      }
      //eslint-disable-next-line
   }, [params]);

   useEffect(() => {
      if (params && paramsTable.sorted) {
         const bodyParams = {
            ...paramsTable,
            ...params
         }

         getTable(bodyParams);
      }
      //eslint-disable-next-line
   }, [paramsTable]);

   useEffect(() => {
      if (sortedData.length > 0) {
         setParamsTable((prevState) => ({ ...prevState, sorted: sortedData }))
      }
      // eslint-disable-next-line
   }, [sortedData]);

   const handleChangePagination = (pagination) => {
      setParamsTable({ ...paramsTable, ...pagination });
   };

   const handleRequestSort = (sortData) => {
      if (dataTable.total > 0) setSortedData(handleSorted(sortData));
   }

   const getTable = (params) => {
      setLoadingFilter(true);
      setLoadingTable(true);
      prep.getTable({
         ...params,
         ...paramsTable
      }).then(res => {
         const { results, response, message } = res;
         if (results) {
            setDataTable({
               columns: response.columns,
               data: dataFormatted(response.data),
               total: response.total
            });
         } else {
            setDataTable({
               columns: [],
               data: [],
               total: 0
            });
            Swal.fire({
               title: message,
               icon: "warning",
            });
         }
      }).catch(error => {
         Swal.fire({
            title: error.message,
            icon: "warning",
         });
         setDataTable({
            columns: [],
            data: [],
            total: 0
         });
      }).finally(() => {
         setLoadingTable(false);
         setLoadingFilter(false);
      });
   }

   const handleShowActa = (item) => {
      const url = `${URL_IMAGES}/files/${item.RutaFotoActa}`;
      window.open(url, "_blank");
   }

   const dataFormatted = (data) => {
      return data.map(item => {
         if (!isNullOrUndefined(item.RutaFotoActa)) {
            item.Foto = (
               <Button
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%" }}
                  color="primaryDark"
                  onClick={() =>
                     handleShowActa(item)
                  }
               >
                  <CameraAlt />
               </Button>
            );
         } else {
            item.Foto = '';
         }

         let newItem = {};
         Object.keys(item).forEach(k => {
            if (typeof item[k] === 'number') {
               newItem[k] = numberWithCommas(item[k]);
            } else {
               newItem[k] = item[k];
            }
         });

         return newItem;
      });
   }

   const handleSorted = (dataSorted) => {
      let sorted = [];
      if (!isEmptyOrNullObject(dataSorted)) {
         switch (dataSorted.orderBy) {
            default:
               sorted.push({
                  id: dataSorted.orderBy,
                  value: dataSorted.order,
               });
               break;
         }
      }
      return sorted;
   }

   const handleChangeRadio = (e) => {
      const value = parseInt(e.target.value);
      setSelectedRadio(value);

      switch (value) {
         case 0:
            setParamsTable((prevState) => ({
               ...prevState, filtered: [
                  {
                     id: 'TotalVotos',
                     filter: 'IS',
                     value: null
                  }
               ]
            }))
            break;
         case 1:
            setParamsTable((prevState) => ({
               ...prevState, filtered: [
                  {
                     id: 'TotalVotos',
                     filter: 'IS NOT',
                     value: null
                  }
               ]
            }));
            break;
         default:
            setParamsTable((prevState) => ({ ...prevState, filtered: [] }));
            break;
      }
   };

   const handleDownload = () => {
      let bodyParams = {
         ...params,
         filtered: paramsTable.filtered ? paramsTable.filtered : [],
         sorted: paramsTable.sorted ? paramsTable.sorted : []
      }

      setIsDownloading(true);
      prep.exportTable(bodyParams).then(res => {
         if (res.success) {
            setShowMessage(true);
            setSuccessDownload(true);
         } else {
            setShowMessage(true);
            setSuccessDownload(false);
         }
      }).catch(e => {
         setShowMessage(true);
         setSuccessDownload(false);
      }).finally(() => setIsDownloading(false))
   }

   return (
      <Box component={Card} className="card-primary">
         <Box component={CardContent}>
            <Grid2 container spacing={2} m={1}>
               <Grid2 xs={12} sm={12} md={4} lg={4} justifyContent={'start'}>
                  <Typography variant="body2" fontWeight={700} fontSize={16} marginBottom={2}>
                     {title}
                  </Typography>
               </Grid2>
               <Grid2 xs={12} sm={12} md={4} lg={4} justifyContent={'center'}>
                  <FormControl>
                     <RadioGroup
                        row
                        aria-labelledby="TieneResultados"
                        name="TieneResultados"
                        onChange={handleChangeRadio}
                        value={selectedRadio}
                     >
                        <FormControlLabel value={2} control={<Radio defaultChecked={selectedRadio === 2} />} label="TODOS" />
                        <FormControlLabel value={1} control={<Radio defaultChecked={selectedRadio === 1} />} label="CON RESULTADOS" />
                        <FormControlLabel value={0} control={<Radio defaultChecked={selectedRadio === 0} />} label="SIN RESULTADOS" />
                     </RadioGroup>
                  </FormControl>
               </Grid2>
               <Grid2 xs={12} sm={12} md={4} lg={4} display={"flex"} justifyContent={"flex-end"}>
                  {isDownloading ? (
                     <Download isDownload={isDownloading} format="xlsx" />
                  ) : (
                     <Tooltip title='Click para exportar'>
                        <Button
                           variant="outlined"
                           color="primaryDark"
                           size="small"
                           startIcon={<Icon>download</Icon>}
                           onClick={handleDownload}
                           sx={{ borderRadius: "10px" }}
                        >
                           Exportar
                        </Button>
                     </Tooltip>)
                  }
               </Grid2>
            </Grid2>
            {showMessage && (
               <Box marginTop={2}>
                  <MessageAlert
                     showMessage={showMessage}
                     successDownload={successDownload}
                     setShowMessage={setShowMessage}
                  />
               </Box>
            )}

            <BasicTableCustomizing
               rows={dataTable.data}
               hcolumns={dataTable.columns}
               pageProp={paramsTable.page}
               pageSize={paramsTable.pageSize}
               total={dataTable.total}
               handleChangePagination={handleChangePagination}
               isLoading={loadingTable}
               stickyHeader={true}
               handleManualSort={handleRequestSort}
            />
         </Box>
      </Box>
   )
}

export default PrepTable;