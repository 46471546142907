import React from "react";
import MuiButton from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";

const Button = ({
  text = "Agregar",
  color = "primaryDark",
  size = "medium",
  icon = "add",
  sx,
  disabled,
  onClick,
  variant = "contained",
}) => {
  let startIcon = icon && <Icon>{icon}</Icon>;
  //let label = text && text;
  return (
    <MuiButton
      color={color}
      variant={variant}
      size={size}
      {...(startIcon = { startIcon })}
      sx={sx}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </MuiButton>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
};
export default Button;
