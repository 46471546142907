import React from "react";
import {
  Modal,
  Box,
  IconButton,
  Button,
  Icon,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  Stack,
  ListItemText,
  FormLabel,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import BasicList from "../Lists/BasicList";
import CloseIcon from "@mui/icons-material/Close";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "date-fns/locale/es";

const AssignRepresentative = ({
  open,
  setOpen,
  title = { tipo: "Propietatario 1", casilla: "POR DEFINIR", seccion: 1 },
}) => {
  /*   const [data, setData] = useState({}); */

  const fetchHasMore = () => {};
  const hasMore = () => {};

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            //overflowY: "auto",
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <IconButton
            aria-label="cancel"
            onClick={() => setOpen(false)}
            sx={{ position: "fixed", left: "3%", top: "2%", color: "#FFFFFF" }}
          >
            <Icon>cancel</Icon>
          </IconButton>
          <Card elevation={0}>
            <CardHeader
              title={`Asignar ${title.tipo}`}
              subheader={`Casilla ${title.casilla} Sección ${title.seccion}`}
              titleTypographyProps={{
                align: "center",
                variant: "h5",
                color: "white",
              }}
              subheaderTypographyProps={{ align: "center", color: "white" }}
              sx={{ backgroundColor: "#152A9E", pt: "25px" }}
            />
            <CardContent>
              <Grid2
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid2 xs={8}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0.5}
                  >
                    <BasicList
                      Header={false}
                      finder={true}
                      data={[
                        {
                          image: <Icon>update</Icon>,
                          title: "ejemplo",
                          description: "9888888",
                        },
                      ]}
                      fetchHasMore={fetchHasMore}
                      totalItems={1}
                      itemComponent={(item) => (
                        <ListItemText
                          primary="Photos"
                          secondary="Jan 9, 2014"
                        />
                      )}
                      hasMore={hasMore}
                    />
                  </Stack>
                </Grid2>
                <Grid2 xs={4}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <TextField
                      required
                      label="Voluntario"
                      name="Voluntario"
                      variant="outlined"
                      size="small"
                      className="fixed-input"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      required
                      label="Responsable"
                      name="Responsable"
                      variant="outlined"
                      size="small"
                      className="fixed-input"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      apterLocale={esLocale}
                    >
                      <DatePicker
                        // disabled={true}
                        label="Vigencia INE"
                        views={["year"]}
                        /*   error={
                          touched.FechaNacimiento &&
                          !isEmptyOrInvalidString(errors.FechaNacimiento)
                        }
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: "FechaNacimiento",
                              value: isNullOrUndefined(e) ? "" : e,
                            },
                          });
                        }} */
                        //onBlur={handleBlur}
                        /* value={values.FechaNacimiento} */
                        renderInput={(params) => (
                          <TextField
                            required
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <FormControl  sx={{alignItems:"center"}}>
                      <FormLabel id="AceptaCuidarOtra">
                        ¿Aceptaría cuidar otra casilla?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="AceptaCuidarOtra"
                        name="AceptaCuidarOtra"
                       /*  value={values.AceptaCuidarOtra}
                        onChange={handleChangeRadio} */
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="SI"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    {/*   {errors.AceptaCuidarOtra && (
                        <FormHelperText error>
                          {errors.AceptaCuidarOtra}
                        </FormHelperText>
                      )} */}
                    </FormControl>
                    <FormControl  sx={{alignItems:"center"}}>
                      <FormLabel id="TieneExperiencia">
                        ¿Tiene Experiencia?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="TieneExperiencia"
                        name="TieneExperiencia"
                        /*   value={values.TieneExperiencia}
                        onChange={handleChangeRadio} */
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="SI"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                      {/*    {errors.TieneExperiencia && (
                        <FormHelperText error>
                          {errors.TieneExperiencia}
                        </FormHelperText>
                      )} */}
                    </FormControl>
                    <FormControl  sx={{alignItems:"center"}}>
                      <FormLabel id="TieneAuto">¿Tiene Auto?</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="TieneAuto"
                        name="TieneAuto"
                        /* value={values.TieneAuto}
                        onChange={handleChangeRadio} */
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="SI"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                      {/*    {errors.TieneAuto && (
                        <FormHelperText error>
                          {errors.TieneAuto}
                        </FormHelperText>
                      )} */}
                    </FormControl>
                  </Stack>
                </Grid2>
              </Grid2>
            </CardContent>
          </Card>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            spacing={0.5}
          >
            <Button
              size="small"
              variant="contained"
              color="error"
              sx={{ borderRadius: "10px" }}
              endIcon={<CloseIcon />}
            >
              Cerrar
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default AssignRepresentative;
