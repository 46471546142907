import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import { Container } from "@mui/material";
import {
  Icon,
  Typography,
  IconButton,
  LinearProgress,
  Breadcrumbs,
  Box,
  Tab,
  Tabs,
  Chip,
} from "@mui/material";
import Card from "@components/Card/Card";
import TreeViewSpaces from "@components/Structure/TreePermisos";
import Grid from "@mui/material/Unstable_Grid2";
import CardCommitments from "@components/Card/StatesCard";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import Modal from "@components/Structure/Modal";
import FormStruct from "@components/Structure/FormStruct";
import AutoComplete from "@components/Selects/Autocomplete";
import ListComponente from "@components/Structure/List";
import ListLink from "@components/Structure/ListLink";
import SocialService from "@services/SocialServices";
import middleware from "@middlewares/middleware";
import { getVars } from "@utils/global";
import Swal from "sweetalert2";
//ICONS
import DeleteIcon from "@mui/icons-material/Delete";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StructureTabs = (props) => {
  const navigate = useNavigate();
  var today = new Date();
  const [user] = useState(() => {
    let t = getVars("Token");
    if (t !== {}) return t.user.ambito_perfil;
    return [];
  });
  const [loading, setLoading] = useState(false);
  const [valueTab, setValueTab] = useState(0);
  const [structs, setStructs] = useState([]);
  const [dataChart, setDataChart] = useState({
    TotalEstructuras: 0,
    TotalCompromisos: 0,
    compromisos: {
      TotalCompromisos: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
    responsables: {
      TotalResponsables: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
    enlaces: {
      TotalEnlaces: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
  });
  const [infoChart, setInfoData] = useState({});
  const [selectStruct, setSelectStruct] = useState({
    idPadre: null,
    id: null,
    NombreSocial: "",
    Alias: "",
    idAmbito: null,
    idMunicipio: null,
    Meta: null,
    idUsuarioCreo: 1,
    TotalEstructura: 0,
    children: [],
  });
  const [loadingChart, setLoadingChart] = useState(false);
  const [responsabilidad, setResponsabilidad] = useState([]);
  //Api Loading
  const [isloadigFormApi, setIsLoadingFormApi] = useState(false);
  const [issuccessFormApi, setIsSuccessFormApi] = useState(false);
  const [openLinearProgressApi, setOpenLinearProgressApi] = useState(false);
  const [messageLoading, setMessageLoading] = useState("");
  const [iconLoading, setIconLoading] = useState("delete_forever");
  //Three
  const [searchEstructura /* setSearchEstructura */] = useState("");
  //Tree
  const [total, setTotal] = useState(0);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [dataModal, setDataModal] = useState({
    title: "",
  });
  const [params /* , setData */] = useState({
    page: 0,
    pageSize: 20,
    filtered: [],
  });
  const [openModal, setOpenModal] = useState(false);
  //Coordinadores
  const [dataCoordinadores, setDataCoordinadores] = useState([]);
  const [paramsCoordinadores, setParamsCoordinadores] = useState({
    page: 0,
    pageSize: 20,
    filtered: [],
  });
  const [totalCoordinadores, setTotalCoordinadores] = useState(0);
  const [hasMoreCoordinadores, setHasMoreCoordinadores] = useState(true);
  const [loadingCoordinadores, setLoadingCoordinadores] = useState(false);
  const [textSearchCoor, setTextSearchCoor] = useState("");

  //Enlaces
  const [dataEnlaces, setDataEnlaces] = useState([]);
  const [hasMoreEnlaces, setHasMoreEnlaces] = useState(true);
  const [paramsEnlaces, setParamsEnlaces] = useState({
    page: 0,
    pageSize: 20,
    filtered: [],
  });
  const [loadingEnlaces, setLoadingEnlaces] = useState(false);
  const [textSearchEnlaces, setTextSearchEnlaces] = useState("");
  const [totalEnlace, setTotalEnlace] = useState(0);

  //Asiignar Enlace
  const [dataUserLinks, setDataUserLinks] = useState([]);
  const [hasMoreUserLinks, setHasMoreUserLinks] = useState(true);
  const [loadingAddLinks, setLoadingAddLinks] = useState(false);
  const [paramsLinks, setParamsLinks] = useState({
    page: 0,
    pageSize: 20,
    filtered: [],
  });
  const [loadingUserLinks, setLoadingUserLinks] = useState(false);
  const [textUserSearchLinks, setTextUserSearchLinks] = useState("");
  const [totalUserLinks, setTotalUserLinks] = useState(0);

  /* Usuarios Responsables */
  const [dataUserCoordinadores, setDataUserCoordinadores] = useState([]);
  const [hasMoreUserCoordinadores, setHasMoreUserCoordinadores] = useState(true);
  const [paramsUserCoordinadores, setParamsUserCoordinadores] = useState({
    page: 0,
    pageSize: 20,
    filtered: [],
  });
  const [loadingUserCoordinadores, setLoadingUserCoordinadores] =useState(false);
  const [loadingAddCoordinadores, setLoadingAddCoordinadores] = useState(false);
  const [textUserSearchCoor, setTextUserSearchCoor] = useState("");
  const [totalUserCoordinadores, setTotalUserCoordinadores] = useState(0);

  //gets apis
  const getStructs = () => {
    setLoading(true);
    SocialService.getStruct(params)
      .then((res) => {
        if (res.results) {
          const struct = res.response.map((social) => {
            return {
              ...social,
              level: 1,
              /* NombreCompleto: (
                <Button
                  variant="text"
                  color="primaryDark"
                  size="small"
                  //onClick={() => handleEdit(commitment)}
                >
                  {commitment.Nombre} {commitment.Paterno}
                  <br />
                  {commitment.Edad} Años
                </Button>
              ),
              isVoluntario: commitment.isVoluntario === 1 ? "Si" : "No",
              Responsabilidad:
                commitment.Responsabilidad !== null
                  ? commitment.Responsabilidad
                  : "Sin Responsabiliad", */
            };
          });
          setSelectStruct(struct[0]);
          setStructs(struct);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: "warning" }))
      .finally(() => setLoading(false));
  };

  const getCharts = () => {
    SocialService.getTotalesSocial(infoChart)
      .then((res) => {
        if (res.results) {
          setDataChart(res.response);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setLoadingChart(false));
  };

  const getResponsabilidad = () => {
    SocialService.getResponsabilidades({})
      .then((res) => {
        if (res.results) {
          setResponsabilidad(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });

    SocialService.catalogoStruct({})
      .then((res) => {
        if (res.results) {
          setSegmentos(
            res.response.map((item) => {
              return {
                value: item.id,
                label: item.NombreSocial,
                idPadre: item.idPadre,
              };
            })
          );
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  const getListCoordinadores = (parametro) => {
    if (loadingCoordinadores) {
      return false;
    }
    setLoadingCoordinadores(true);
    SocialService.getCoordinators(parametro ? parametro : paramsCoordinadores)
      .then((res) => {
        if (res.results) {
          if (
            parametro ? parametro.page === 0 : paramsCoordinadores.page === 0
          ) {
            setDataCoordinadores(
              res.response.data.map((item) => {
                return {
                  ...item,
                  title: item.NombreCompleto,
                  subTitle: validateDeleteResponsable(4, item.idPerfil) ? (
                    <Chip
                      label={item.PerfilCompleto}
                      color="primary"
                      onDelete={() =>
                        DeleteResponsabilidad({ ...item, idUsuario: item.id })
                      }
                      deleteIcon={<DeleteIcon />}
                      variant="outlined"
                    />
                  ) : (
                    item.PerfilCompleto
                  ),
                };
              })
            );
          } else {
            setDataCoordinadores(
              dataCoordinadores.concat(
                res.response.data.map((item) => {
                  return {
                    ...item,
                    title: item.NombreCompleto,
                    subTitle: validateDeleteResponsable(4, item.idPerfil) ? (
                      <Chip
                        label={item.PerfilCompleto}
                        color="primary"
                        onDelete={() =>
                          DeleteResponsabilidad({ ...item, idUsuario: item.id })
                        }
                        deleteIcon={<DeleteIcon />}
                        variant="outlined"
                      />
                    ) : (
                      item.PerfilCompleto
                    ),
                  };
                })
              )
            );
          }

          setTotalCoordinadores(res.response.total);
          let end =
            (parametro ? parametro.page + 1 : paramsCoordinadores.page + 1) *
            paramsCoordinadores.pageSize;
          if (end >= res.response.total) {
            setHasMoreCoordinadores(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMoreCoordinadores(false);
        }
      })
      .catch((e) => {
        setHasMoreCoordinadores(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoadingCoordinadores(false);
      });
  };

  const getListLinks = (parametro) => {
    if (loadingEnlaces) {
      return false;
    }

    setLoadingEnlaces(true);
    /* Enlaces */
    SocialService.getLinks(parametro ? parametro : paramsEnlaces)
      .then((res) => {
        if (res.results) {
          if (parametro ? parametro.page === 0 : paramsEnlaces.page === 0) {
            setDataEnlaces(
              res.response.data.map((item) => {
                return {
                  ...item,
                  title: item.NombreCompleto,
                  subTitle: item.children.map((label) =>
                    validateLinks(label.idEstructuraSocial) ? (
                      <Chip
                        label={label.NombreSocial}
                        color="primary"
                        onDelete={() =>
                          DeleteEnlace({
                            idEstructuraSocial: label.idEstructuraSocial,
                            idUsuario: item.id,
                          })
                        }
                        deleteIcon={<DeleteIcon />}
                        variant="outlined"
                      />
                    ) : (
                      item.children.map((label) => ` ,${label.NombreSocial}`)
                    )
                  ),
                };
              })
            );
          } else {
            setDataEnlaces(
              dataEnlaces.concat(
                res.response.data.map((item) => {
                  return {
                    ...item,
                    title: item.NombreCompleto,
                    subTitle: item.children.map((label) =>
                      validateLinks(label.idEstructuraSocial) ? (
                        <Chip
                          label={label.NombreSocial}
                          color="primary"
                          onDelete={() =>
                            DeleteEnlace({
                              idEstructuraSocial: label.idEstructuraSocial,
                              idUsuario: item.id,
                            })
                          }
                          deleteIcon={<DeleteIcon />}
                          variant="outlined"
                        />
                      ) : (
                        item.children.map((label) => ` ,${label.NombreSocial}`)
                      )
                    ),
                  };
                })
              )
            );
          }

          setTotalEnlace(res.response.total);
          let end =
            (parametro ? parametro.page + 1 : paramsEnlaces.page + 1) *
            paramsEnlaces.pageSize;
          if (end >= res.response.total) {
            setHasMoreEnlaces(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMoreEnlaces(false);
        }
      })
      .catch((e) => {
        setHasMoreEnlaces(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoadingEnlaces(false);
      });
  };

  const getListUserLinks = (parametro) => {
    if (loadingUserLinks) {
      return false;
    }

    setLoadingUserLinks(true);
    /* Usuario Enlaces */
    SocialService.getUserLinks(parametro ? parametro : paramsLinks)
      .then((res) => {
        if (res.results) {
          if (parametro ? parametro.page === 0 : paramsLinks.page === 0) {
            setDataUserLinks(
              res.response.data.map((item) => {
                return {
                  ...item,
                  title: item.NombreCompleto,
                  subTitle: item.Celular,
                  content: item.children.map((index) => {
                    return {
                      ...index,
                      validate: validateLinks(index.idEstructuraSocial),
                    };
                  }),
                };
              })
            );
          } else {
            setDataUserLinks(
              dataUserLinks.concat(
                res.response.data.map((item) => {
                  return {
                    ...item,
                    title: item.NombreCompleto,
                    subTitle: item.Celular,
                    content: item.children.map((index) => {
                      return {
                        ...index,
                        validate: validateLinks(index.idEstructuraSocial),
                      };
                    }),
                  };
                })
              )
            );
          }

          setTotalUserLinks(res.response.total);
          let end =
            (parametro ? parametro.page + 1 : paramsLinks.page + 1) *
            paramsLinks.pageSize;
          if (end >= res.response.total) {
            setHasMoreUserLinks(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMoreUserLinks(false);
        }
      })
      .catch((e) => {
        setHasMoreUserLinks(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoadingUserLinks(false);
      });
  };

  const getListUserCoordinadores = (params) => {
    /* Usuario Responsabilidad */
    if (loadingUserCoordinadores === true) {
      return false;
    }
    console.log(params);
    setLoadingUserCoordinadores(true);
    SocialService.getUserCoordinators(params ? params : paramsUserCoordinadores)
      .then((res) => {
        if (res.results) {
        if (params ? params.page === 0 : paramsUserCoordinadores.page === 0) {
            setDataUserCoordinadores(
                  res.response.data.map((item) => {
                    return {
                      ...item,
                      title: item.NombreCompleto,
                      subTitle: item.Celular,
                      content: item.children.map((index) => {
                        return {
                          ...index,
                          validate: validateDeleteResponsable(
                            index.idTipo,
                            index.idPerfil
                          ),
                        };
                      }),
                    };
                  })
              );
        }else{
            setDataUserCoordinadores(
                dataUserCoordinadores.concat(
                  res.response.data.map((item) => {
                    return {
                      ...item,
                      title: item.NombreCompleto,
                      subTitle: item.Celular,
                      content: item.children.map((index) => {
                        return {
                          ...index,
                          validate: validateDeleteResponsable(
                            index.idTipo,
                            index.idPerfil
                          ),
                        };
                      }),
                    };
                  })
                )
              );
        }
          
          setTotalUserCoordinadores(res.response.total);
          //handleClickItem(res.response.data[0].id);
          let end =
            (paramsUserCoordinadores.page + 1) *
            paramsUserCoordinadores.pageSize;
          if (end >= res.response.total) {
            setHasMoreUserCoordinadores(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMoreUserCoordinadores(false);
        }
      })
      .catch((e) => {
        setHasMoreUserCoordinadores(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoadingUserCoordinadores(false);
      });
  };

    //Validaciones

    function validateDeleteResponsable(idTipo, idPerfil) {
      let arrayResponsabilidad = responsabilidad.map(i=>i.value);
      let vali = false;
  
      if (idTipo === 4 && middleware.checkMenuActionId("Eliminar", 76)) {
        let foundSocial = user.find((e) => e.idTipo === 4);
        let foundAdmin = user.find((e) => e.idPerfil === 99);
  
      /*   let permiso = foundSocial ? foundSocial : { idPerfil: 0 };
        if (foundAdmin) {
          permiso = foundAdmin;
        } */
  
  /* 
        switch (permiso.idPerfil) {
          case 99:
            arrayResponsabilidad = [30, 31, 34, 35,131];
            break;
          case 30:
            arrayResponsabilidad = [31, 34, 35,131];
            break;
          case 34:
            arrayResponsabilidad = [31, 35,131];
            break;
          case 31:
            arrayResponsabilidad = [35,131];
            break;
          default:
            arrayResponsabilidad = [35,131];
            break;
        } */
  
  
        if (arrayResponsabilidad.includes(idPerfil)) {
          vali = true;
        }
      }
  
      return vali;
    }
  
    function validateLinks(idEstructuraSocial) {
      let idsSegmentos = segmentos.map((item) => {
        return item.value;
      });
  
      return (
        idsSegmentos.includes(idEstructuraSocial) &&
        middleware.checkMenuActionId("Eliminar", 76)
      );
    }
    

  useEffect(() => {
    if (middleware.checkMenuActionId("Ver") && !openModal) {
      setLoadingChart(true)
      getCharts();
    }
    // eslint-disable-next-line
  }, [infoChart, openModal]);

  useEffect(() => {
    if (middleware.checkMenuActionId("Ver")) {
      getResponsabilidad();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    changeTab(valueTab);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (middleware.checkMenuActionId("Ver") && !openModal) {
      getStructs();
    }
    // eslint-disable-next-line
  }, [openModal]);

  const handleChangeTab = (event, newValue) => {

    setValueTab(newValue);
    changeTab(newValue, {
      page: 0,
      pageSize: 20,
      filtered: infoChart.idEstructura ?  [{id:"cat_social.id",value:infoChart.idEstructura,filter:"="}] : []
    });
  };

  const filterSegmento = (e) => {
    let search = searchTree(structs[0], e.label.toUpperCase());
    if (search) {
      SelecFilter(search);
    }
  };
  const onDelete = () => {
    Swal.fire({
      title: `¿Está seguro de eliminar ${selectStruct.NombreSocial}?`,
      text: "Se eliminarán todas las subestructura, coordinadores, enlaces y compromisos asignados a esta estructura!",
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      confirmButtonColor: "#14A52E",
    }).then((result) => {
      if (result.isConfirmed) {
        SocialService.deleteSocial({ id: selectStruct.id })
          .then((res) => {
            if (res.success && res.results) {
              Swal.fire("Eliminado!", "", "success");
              setSelectStruct({
                idPadre: null,
                id: null,
                NombreSocial: "",
                Alias: "",
                idAmbito: null,
                idMunicipio: null,
                Meta: null,
                idUsuarioCreo: 1,
                TotalEstructura: 0,
                children: [],
              });
              getStructs();
            } else {
              Swal.fire({ title: res.message, icon: "warning" });
            }
          })
          .catch((e) => {
            Swal.fire({ title: e.message, icon: "warning" });
          })
          .finally(() => {
            // setIsLoading(false);
          });
      }
    });
  };

  const onEdit = () => {
    setDataModal({
      title: (
        <>
          EDITAR SUBESTRUCTURA A{" "}
          <strong>
          <ins>{selectStruct.NombreSocial.toUpperCase()}</ins>
          </strong>
        </>
      ),
      idPadre: selectStruct.id,
      Nivel: selectStruct.Nivel,
      edit: {
        id: selectStruct.id,
        NombreSocial: selectStruct.label,
        Alias: selectStruct.Alias,
      },
    });
    setOpenModal(!openModal);
  };

  const onAdd = () => {
    setDataModal({
      title: (
        <>
          AGREGAR SUBESTRUCTURA A{" "}
          <strong>
            <ins>{selectStruct.NombreSocial.toUpperCase()}</ins>
          </strong>
        </>
      ),
      idPadre: selectStruct.id,
      Nivel: selectStruct.Nivel,
    });
    setOpenModal(!openModal);
  };

  const searchTree = (node, value) => {
    if (node["NombreSocial"].toUpperCase().match(value)) {
      return node;
    } else if (node.children.length > 0) {
      var i;
      var result = null;
      for (i = 0; result == null && i < node.children.length; i++) {
        result = searchTree(node.children[i], value.trim());
      }
      return result;
    }
    return null;
  };

  const SelecFilter = (e) => {
    setInfoData({
      idEstructura: e.id,
    });
    setSelected(e.id);
    let exp = [e.id];
    let stringExp = [`${e.id}`];
    for (let count = 0; count < e.Nivel; count++) {
      let search = segmentos.filter((item) => item.value === exp[count]);
      exp.push(search[0].idPadre);
      stringExp.push(`${search[0].idPadre}`);
    }
    setExpanded(stringExp);
    setSelectStruct(e);
  };

  const Selec = (e) => {
    if(loadingChart){
      return true;
    }
    setInfoData({
      idEstructura: e.id,
    });
    let params = {
      page: 0,
      pageSize: 20,
      filtered: [{id:"cat_social.id",value:e.id,filter:"="}],
    };
    setDataCoordinadores([]);
    setDataEnlaces([]);
    setParamsCoordinadores(params);
    setParamsEnlaces(params);
    setSelectStruct(e);
    changeTab(valueTab,params);
  };

  //Coordinadores Funciones
  const fetchHasMoreCoordinadores = () => {
    let params = {
      ...paramsCoordinadores,
      page: paramsCoordinadores.page + 1,
    };
    setParamsCoordinadores(params);
    getListCoordinadores(params);
  };

  const searchCoordinadores = (params) => {
    setParamsCoordinadores(params);
    getListCoordinadores(params);
  };

  //Enlaces Funciones
  const fetchHasMoreEnlaces = () => {
    let parametros = {
      ...paramsEnlaces,
      page: paramsEnlaces.page + 1,
    };
    setParamsEnlaces(parametros);
    getListLinks(parametros);
  };

  const searchEnlaces = (params) => {
    setDataEnlaces([]);
    setParamsEnlaces(params);
    getListLinks(params);
  };

  /* Usuarios Enlaces */

  const fetchHasUserMoreLinks = () => {
    let parametro = {
      ...paramsLinks,
      page: paramsLinks.page + 1,
    };
    setParamsLinks(parametro);
    getListUserLinks(parametro);
  };

  const searchUserLinks = (params) => {
    setDataUserLinks([]);
    setParamsLinks(params);
    getListUserLinks(params);
  };

  const AsignarEnlace = (item) => {
    if (loadingAddLinks) {
      return false;
    }
    setLoadingAddLinks(true);
    let paramsData = {
      idUsuario: item.data.id,
      idEstructuraSocial: Array.isArray(item.idEstructura) ? item.idEstructura.map(i => i.value) : [item.idEstructura.value],
    };

    SocialService.assignLinks(paramsData)
      .then((res) => {
        if (res.success && res.results) {
          Swal.fire({
            title: res.message,
            icon: "success",
            confirmButtonText: "Listo",
          });
          changeTab(valueTab);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoadingAddLinks(false);
      });
  };

  /* Usuarios Coordinadores */

  const fetchHasUserMoreCoordinadores = () => {
    let params = {
      ...paramsUserCoordinadores,
      page: paramsUserCoordinadores.page + 1,
    };
    setParamsUserCoordinadores(params);
    getListUserCoordinadores(params);
  };

  const searchUserCoordinadores = (params) => {
    setDataUserCoordinadores([]);
    setParamsUserCoordinadores(params);
    getListUserCoordinadores(params);
  };

  const AsignarResponsabilidad = (item) => {
    if (loadingAddCoordinadores) {
      return true;
    }
    console.log(item);
    setLoadingAddCoordinadores(true);
    let paramsData = {
      idUsuario: item.data.id,
      idEstructuraSocial:Array.isArray(item.idEstructura) ? item.idEstructura.map(i => i.value) : [item.idEstructura.value],
      idPerfil: item.responsabilidad.value,
    };

    SocialService.assignCoordinators(paramsData)
      .then((res) => {
        if (res.success && res.results) {
          Swal.fire({
            title: res.message,
            icon: "success",
            confirmButtonText: "Listo",
          });
          changeTab(valueTab);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoadingAddCoordinadores(false);
      });
  };

  //Delete´s
  const DeleteResponsabilidad = (item) => {
    if (isloadigFormApi) {
      return true;
    }

    let params = {
      idUsuario: item.idUsuario,
      idPerfil: item.idPerfil,
      idEstructuraSocial: item.idEstructuraSocial,
    };
    Swal.fire({
      title: `Está seguro de eliminar la responsabilidad?`,
      text: "",
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      confirmButtonColor: "#14A52E",
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenLinearProgressApi(true);
        setIsLoadingFormApi(true);
        setIsSuccessFormApi(true);
        setIconLoading("delete_forever");
        setMessageLoading("Eliminando Responsabilidad");
        SocialService.removeCoordinator(params)
          .then((res) => {
            if (res.success && res.results) {
              Swal.fire("Eliminado!", "", "success");
              switch (valueTab) {
                case 0:
                  if (middleware.checkMenuActionId("Ver")) {
                    getListCoordinadores({...paramsCoordinadores});
                  }
                  break;
                case 3:
                  if (middleware.checkMenuActionId("Ver", 76)) {
                    getListUserCoordinadores({...paramsUserCoordinadores});
                  }
                  break;
                default:
                  break;
              }
            } else {
              Swal.fire({ title: res.message, icon: "warning" });
            }
          })
          .catch((e) => {
            Swal.fire({ title: e.message, icon: "warning" });
          })
          .finally(() => {
            setOpenLinearProgressApi(false);
            setIsLoadingFormApi(false);
            setIsSuccessFormApi(false);
          });
      }
    });
  };

  const DeleteEnlace = (item) => {
    if (isloadigFormApi) {
      return true;
    }

    let params = {
      idUsuario: item.idUsuario,
      idEstructuraSocial: item.idEstructuraSocial,
    };
    Swal.fire({
      title: `¿Está seguro de eliminar el enlace?`,
      text: "",
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      confirmButtonColor: "#14A52E",
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenLinearProgressApi(true);
        setIsLoadingFormApi(true);
        setIsSuccessFormApi(true);
        setIconLoading("delete_forever");
        setMessageLoading("Eliminando Enlaces");
        SocialService.removeLinks(params)
          .then((res) => {
            if (res.success && res.results) {
              Swal.fire("Eliminado!", "", "success");
              switch (valueTab) {
                case 1:
                  if (middleware.checkMenuActionId("Ver")) {
                    getListLinks({...paramsEnlaces});
                  }
                  break;
                case 2:
                  if (middleware.checkMenuActionId("Ver", 76)) {
                    getListUserLinks({...paramsLinks});
                  }
                  break;
                default:
                  break;
              }
            } else {
              Swal.fire({ title: res.message, icon: "warning" });
            }
          })
          .catch((e) => {
            Swal.fire({ title: e.message, icon: "warning" });
          })
          .finally(() => {
            setOpenLinearProgressApi(false);
            setIsLoadingFormApi(false);
            setIsSuccessFormApi(false);
          });
      }
    });
  };

  //funciones

  const changeTab = (value, param) => {
    switch (value) {
      case 0:
        if (middleware.checkMenuActionId("Ver")) {
          getListCoordinadores(param);
        }
        break;
      case 1:
        if (middleware.checkMenuActionId("Ver")) {
          getListLinks(param);
        }
        break;
      case 2:
        if (middleware.checkMenuActionId("Ver", 76)) {
          getListUserLinks();
        }
        break;
      case 3:
        if (middleware.checkMenuActionId("Ver", 76)) {
          getListUserCoordinadores();
        }
        break;
      default:
        break;
    }
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <Modal
          open={openModal}
          setOpen={(e) => setOpenModal(e)}
          Children={
            <FormStruct data={dataModal} setOpen={(e) => setOpenModal(e)} />
          }
        />
        <LoadingForm
          isLoading={isloadigFormApi}
          success={issuccessFormApi}
          isOpen={openLinearProgressApi}
          setIsOpen={() => setOpenLinearProgressApi(!openLinearProgressApi)}
          IconOption={iconLoading}
          successMessage={messageLoading}
          loadinMessage={"Cargando..."}
        />
        <ActionHeader
          title={
            /*  selectStruct.id  */ false ? (
              <>
                <Breadcrumbs
                  separator={<Icon fontSize="small">arrow_forward_ios</Icon>}
                  aria-label="breadcrumb"
                >
                  <Typography variant="body1">Estructura Social</Typography>
                  <Typography variant="h6" color={"InfoText"}>
                    {/*  {selectStruct.NombreSocial} */}
                  </Typography>
                </Breadcrumbs>
              </>
            ) : (
              "Estructura Social"
            )
          }
          handleclick={() => navigate("/estructura-social")}
        />
        <Grid container spacing={2}>
          <Grid xs={5}>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <CardCommitments
                  icon={"fact_check"}
                  title={"Total Estructuras"}
                  total={dataChart.TotalEstructuras}
                  subtitle1={"Compromisos"}
                  subtotal1={`${dataChart.compromisos.TotalCompromisos} ${dataChart.MetaCompromisos ? `de ${dataChart.MetaCompromisos}`: ""}`}
                  subtitle2={"Enlaces"}
                  subtotal2={dataChart.enlaces.TotalEnlaces}
                  subtitle3={"Responsables"}
                  subtotal3={dataChart.responsables.TotalResponsables}
                  subIcon={"update"}
                  subFooter={today.toLocaleDateString()}
                  loading={loadingChart}
                />
              </Grid>
              <Grid item xs={12}>
                <Card
                  sx={{ height: "850px", overflow: "auto" }}
                  children={
                    <>
                      {true && (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <AutoComplete
                                required
                                label="Estructuras"
                                name="Estructuras"
                                autoComplete="Estructuras"
                                value={searchEstructura}
                                id="Estructuras"
                                size="small"
                                disableUnderline={false}
                                setSelect={(e) => filterSegmento(e)}
                                data={segmentos}
                                //onChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                          <br />
                        </>
                      )}
                      <TreeViewSpaces
                        loading={loading}
                        total={total}
                        data={structs}
                        setSelected={(e) => setSelected(e)}
                        setExpanded={(e) => setExpanded(e)}
                        onExpanded={expanded}
                        onSelected={selected}
                        typeData={{ value: "id", label: "NombreSocial" }}
                        onSelect={(e) => Selec(e)}
                      />
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={7}>
            <Card
              sx={{ height: "1090px", overflow: "scroll" }}
              hasCardHeader={true}
              colorbgtittle="#E6F2FC"
              colorTittle="#3063BA"
              Tittle={
                selectStruct.id !== null && (
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5" component="div">
                        {selectStruct.NombreSocial}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {selectStruct.level !== 1 && (
                        <>
                          {middleware.checkMenuActionId("Eliminar") && (
                            <IconButton onClick={() => onDelete()}>
                              <Icon color="error">delete</Icon>
                            </IconButton>
                          )}
                          {middleware.checkMenuActionId("Editar") && (
                            <IconButton onClick={() => onEdit()}>
                              <Icon color="secondary">edit</Icon>
                            </IconButton>
                          )}
                        </>
                      )}
                      {middleware.checkMenuActionId("Agregar") && (
                        <IconButton onClick={() => onAdd()}>
                          <Icon color="success">add_circle_outline</Icon>
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                )
              }
              children={
                loading ? (
                  <LinearProgress color="info" />
                ) : (
                  <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <Tabs
                      value={valueTab}
                      onChange={handleChangeTab}
                      variant="scrollable"
                      scrollButtons="auto"
                      centered
                    >
                      <Tab label="Coordinadores" />
                      <Tab label="Enlaces" />
                      {middleware.checkMenuActionId("Ver", 76) && (
                        <Tab label="Asignar Enlaces" />
                      )}
                      {middleware.checkMenuActionId("Ver", 76) && (
                        <Tab label="Asignar Coordinadores" />
                      )}
                    </Tabs>
                    <TabPanel value={valueTab} index={0}>
                      <ListComponente
                        more={true}
                        Buscar={true}
                        titleSearch="Nombre/Celular"
                        loading={loadingCoordinadores}
                        pageSize={20}
                        fetchHasMore={(e) => fetchHasMoreCoordinadores(e)}
                        data={dataCoordinadores}
                        totalItems={totalCoordinadores}
                        hasMore={hasMoreCoordinadores}
                        handleSearchItems={searchCoordinadores}
                        searchText={textSearchCoor}
                        setSearch={(e) => setTextSearchCoor(e)}
                      />
                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                      <ListComponente
                        more={false}
                        Buscar={true}
                        titleSearch="Nombre/Celular"
                        pageSize={20}
                        loading={loadingEnlaces}
                        fetchHasMore={(e) => fetchHasMoreEnlaces(e)}
                        data={dataEnlaces}
                        totalItems={totalEnlace}
                        hasMore={hasMoreEnlaces}
                        handleSearchItems={searchEnlaces}
                        // handleClickItem={(e) => console.log(e)}
                        searchText={textSearchEnlaces}
                        setSearch={(e) => setTextSearchEnlaces(e)}
                      />
                    </TabPanel>
                    <TabPanel value={valueTab} index={2}>
                      <ListLink
                        Buscar={true}
                        selectBasic={false}
                        titleSearch="Nombre/Celular"
                        titleLineDivider="Enlaces"
                        pageSize={20}
                        loading={loadingUserLinks}
                        loadingButon={loadingAddLinks}
                        fetchHasMore={(e) => fetchHasUserMoreLinks(e)}
                        data={dataUserLinks}
                        totalItems={totalUserLinks}
                        hasMore={hasMoreUserLinks}
                        handleSearchItems={searchUserLinks}
                        ClickItem={!middleware.checkMenuActionId("Agregar", 76)}
                        handleClickItem={(e) => AsignarEnlace(e)}
                        handleDeleteItem={(e) => DeleteEnlace(e)}
                        searchText={textUserSearchLinks}
                        setSearch={(e) => setTextUserSearchLinks(e)}
                        idEstructura={{value:selectStruct.id,label:selectStruct.NombreSocial}}
                        structs={segmentos}
                      />
                    </TabPanel>
                    <TabPanel value={valueTab} index={3}>
                      <ListLink
                        Buscar={true}
                        titleSearch="Nombre/Celular"
                        titleLineDivider="Responsabilidades"
                        pageSize={paramsUserCoordinadores.pageSize}
                        loading={loadingUserCoordinadores}
                        loadingButon={loadingAddCoordinadores}
                        dataBasic={{
                          title: "Responsabilidad *",
                          options: responsabilidad,
                          required: true,
                        }}
                        fetchHasMore={(e) => fetchHasUserMoreCoordinadores(e)}
                        data={dataUserCoordinadores}
                        totalItems={totalUserCoordinadores}
                        hasMore={hasMoreUserCoordinadores}
                        handleSearchItems={searchUserCoordinadores}
                        ClickItem={!middleware.checkMenuActionId("Agregar", 76)}
                        handleClickItem={(e) => AsignarResponsabilidad(e)}
                        handleDeleteItem={(e) => DeleteResponsabilidad(e)}
                        searchText={textUserSearchCoor}
                        setSearch={(e) => setTextUserSearchCoor(e)}
                        idEstructura={{value:selectStruct.id,label:selectStruct.NombreSocial}}
                        structs={segmentos}
                      />
                    </TabPanel>
                  </Box>
                )
              }
            />
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};
export default StructureTabs;
