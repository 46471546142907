import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const DetailPolygonal = ({ data }) => {
  return (
    <Card elevation={0} variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Grid2 container>
          <Grid2 xs={12} md={5} lg={5}>
            <Typography variant="body2" fontWeight={600}>
              DETALLE
            </Typography>
            <Divider />
            <List>
              <ListItem
                sx={{ justifyContent: "space-between", padding: 0, mb: 0.5 }}
              >
                <Typography variant="subtitle2">Región:</Typography>
                <Typography variant="body2" sx={{ width: "85%" }}>
                  R1
                </Typography>
              </ListItem>

              <ListItem
                sx={{ justifyContent: "space-between", padding: 0, mb: 0.5 }}
              >
                <Typography variant="subtitle2">Municipio:</Typography>
                <Typography variant="body2" sx={{ width: "85%" }}>
                  Irapuato
                </Typography>
              </ListItem>

              <ListItem
                sx={{ justifyContent: "space-between", padding: 0, mb: 0.5 }}
              >
                <Typography variant="subtitle2">Polígono:</Typography>
                <Typography variant="body2" sx={{ width: "85%" }}>
                  40_2
                </Typography>
              </ListItem>
            </List>
          </Grid2>
          <Grid2
            xs={12}
            md={1}
            lg={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Divider orientation="vertical" variant="middle" />
          </Grid2>
          <Grid2 xs={12} md={6} lg={6}>
            <Typography variant="body2" fontWeight={600}>
              POLÍGONAL
            </Typography>
            <Divider />
            <Stack
              direction={"row"}
              marginTop={1}
              spacing={2}
              flexWrap={"wrap"}
            >
              {[0, 1, 2].map((item) => (
                <Typography variant="body2" marginBottom={0.5}>
                  NICOLASA PEREZ PEREZ
                </Typography>
              ))}
            </Stack>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default DetailPolygonal;
