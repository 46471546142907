import React, { useState } from "react";
import {
    Box,
    Icon,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Collapse,
    Divider,
    Typography
} from "@mui/material";
import MenusList from "@components/Menus/MenusList";
import useWindowDimensions from "@hooks/useWindowDimensions";

const MenusListAll = props => {
    const { webMenus, getSelectedMenu, type, title } = props;
    const { height } = useWindowDimensions();
    const [openWebMenus, setOpenWebMenus] = useState(true);

    return (
        <Box sx={{ p: 2, height: height, overflow: "auto" }}>
            <List>
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={<Typography variant="h6">{title}</Typography>}
                    />
                </ListItem>
                <ListItem
                    alignItems="flex-start"
                    onClick={() => setOpenWebMenus(!openWebMenus)}
                >
                    <ListItemAvatar>
                        <Icon>computer</Icon>
                    </ListItemAvatar>
                    <ListItemText primary="Menús Web" />
                </ListItem>
                <Collapse in={openWebMenus} timeout="auto" unmountOnExit>
                    <MenusList
                        menus={webMenus}
                        setMenus={getSelectedMenu}
                        type={type}
                        maxHeight={height * 0.5}
                    //   reset={resetForm}
                    />
                </Collapse>
                <Divider variant="inset" component="li" />
            </List>
        </Box>
    )
}

export default MenusListAll;