import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ThemeProvider } from "@mui/material/styles";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { theme } from "@src/theme/index";
import Yup from "@utils/Yupi18n";
import { setVars, Version } from "@utils/global";
import ApiExec from "@utils/ApiExec";
import QrReader from "react-camera-qr";
import ActionHeader from "@components/Containers/ActionHeader";
import events from "@services/EventsServices";
import { grey } from "@mui/material/colors";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Filter from "@components/Events/PaseListaInfoFilter";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import ModalQR from "@components/Events/ModalQR";

const QRs = (props) => {
  const [openModal1, setOpenModal1] = useState(false);
  const [startScan, setStartScan] = useState(false);
  const [loading, setLoading] = useState(true);
  const [facingMode, setFacinMode] = useState("front");
  const [loadingPaseLista, setLoadingPaseLista] = useState(false);
  const [dataItem, setDataItem] = useState(null);
  const view = "table";
  const [data, setData] = useState({
    page: 0,
    pageSize: 20,
    filtered: [],
    sorted: [],
  });
  const [ultimoScann, setUltimoScann] = useState("");
  const [dataListEvents, setDataListEvents] = useState({
    Actividad: "1er Informe de Gobierno",
    Descripcion: "",
    FechaFin: "",
    FechaFinPreregistro: "",
    FechaInicio: "",
    HoraInicio: "",
    Modalidad: "",
    Municipio: "",
    TienePreregistro: null,
    TipoActividad: "",
    UUID: "",
    id: 1,
    idCategoria: null,
    idEstatus: null,
    idTipoActividad: null,
    imgURL: "",
    isPrivado: null,
    textoPreregistro: "",
  });
  const [eventoStats, setEventoStats] = useState({
    id: 1,
    UUID: 1,
    Invitados: 1,
    Asistieron: 0,
  });
  const [deviceId, setDeviceId] = useState(null);

  var misLogs = "";

  useEffect(() => {
    console.log("=>QR: ", eventoStats);
  }, [eventoStats]);

  useEffect(() => {
    const getCameraDeviceId = async () => {
      try {
        misLogs = "checar devices" + "\n" + misLogs;
        const devices = await navigator.mediaDevices.enumerateDevices();
        misLogs = "devices" + devices + "\n" + misLogs;
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        const rearCamera = videoDevices.find((device) =>
          device.label.toLowerCase().includes("back")
        );

        console.log("=>rearCamera: ", rearCamera);
        setDeviceId(rearCamera?.deviceId || videoDevices[0]?.deviceId || null);
      } catch (error) {
        console.error("Error al obtener los dispositivos de la cámara:", error);
      }
    };

    getCameraDeviceId();
  }, []);

  useEffect(() => {
    if (dataItem !== null) {
      console.log("=>dataItem: ", dataItem);
      setPaseLista(dataItem);
    }
  }, [dataItem]);

  const setPaseLista = (item) => {
    setLoadingPaseLista(true);
    const data = {
      Folio: item,
    };

    // console.log("=>setPaseListaXG: ", JSON.stringify(data));
    events
      .setEventsAsisenciaInforme(data)
      .then((res) => {
        console.log("=>res: ", res);
        if (res.results) {
          if (res.response) {
            setUltimoScann(res.message);
            Swal.fire({ title: res.message, icon: "success" });
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => {
        console.log("error: ", err);
        Swal.fire({ title: err, icon: "warning" });
      })
      .finally(() => setLoadingPaseLista(false));
  };

  const handleChangeFilter = (filtered) => {
    setData((prevState) => ({ ...prevState, filtered }));
  };

  const handleScan = (itemQR) => {
    if (itemQR !== null) {
      console.log("=>itemQR: ", itemQR);
      if (itemQR !== dataItem) {
        if (itemQR.length === 36) {
          setDataItem(itemQR);
          //setStartScan(false);
        }
      } else {
        console.log("=>Es el mismo QR: ", itemQR);
      }
    }
  };

  const handleError = (error) => {
    console.log("=>Error: ", error);
  };

  const handleQR = () => {
    setStartScan(!startScan);
  };

  const handleCloseModal = () => {
    setDataItem(null);
    setOpenModal1(false);
  };

  const signIn = (values) => {
    setLoading(true);
    ApiExec(values, "auth/login")
      .then(function (res) {
        return res;
      })
      .then((token) => {
        if (token.results) {
          setVars("Token", token.response);
        } else {
          Swal.fire({ title: token.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
        });
      })
      .finally((end) => {
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Box>
          {/* <Grid2 xs={6} sm={6} md={6} lg={6}>
            <Card>
              <CardContent>
                <Typography variant="h2" fontWeight={700}>
                  {`${eventoStats.Asistieron} de ${eventoStats.Invitados}`}
                </Typography>
                <Typography variant="h5" fontWeight={700}>
                  {`${(
                    100 *
                    (eventoStats.Asistieron / eventoStats.Invitados)
                  ).toFixed(2)}%`}
                </Typography>
              </CardContent>
            </Card>
          </Grid2> */}
          <h3> 1er Informe de Gobierno</h3>

          <Filter
            onChangeFilter={(e) => handleChangeFilter(e)}
            onGetQR={(e) => handleQR(e)}
          />

          <h3>Último escaneado: {`${ultimoScann}`}</h3>
          <Grid2 xs={6} sm={6} md={4} lg={4}>
            {loadingPaseLista === false ? (
              <>
                <Button
                  variant="contained"
                  onClick={() => setFacinMode("environment")}
                  endIcon={<ThreeSixtyIcon />}
                >
                  Trasera
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setFacinMode("user")}
                  endIcon={<ThreeSixtyIcon />}
                >
                  Delantera
                </Button>

                <QrReader
                  facingMode={facingMode}
                  delay={1000}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: "100%" }}
                />
              </>
            ) : (
              <CircularProgress />
            )}
          </Grid2>
        </Box>
      </Container>
      {/* <Footer /> */}
    </ThemeProvider>
  );
};

export default QRs;
