import { ProtectedRoute } from "@middlewares";
import TableElectoral from "@pages/Electoral/TableElectoral";
import AvanceCasillas from "../../pages/Electoral/AvanceCasillas";
import StructElectoral from "../../pages/Electoral/StructElectoral";
import {
    PERFIL_RC,
    PERFIL_RG,
    PERFIL_ABOGADO,
    PERFIL_CASA,
    PERFIL_FiL,
    PERFIL_OBSERVER,
  } from "@data/constants";

const ElectoralRoutes = [
  {
    path: "/rcs",
    element: (
      <ProtectedRoute>
        <TableElectoral idPerfil ={PERFIL_RC}/>
      </ProtectedRoute>
    ),
  },
  {
    path: "/rgs",
    element: (
      <ProtectedRoute>
        <TableElectoral idPerfil ={PERFIL_RG}/>
      </ProtectedRoute>
    ),
  },
  {
    path: "/abogados",
    element: (
      <ProtectedRoute>
        <TableElectoral idPerfil ={PERFIL_ABOGADO}/>
      </ProtectedRoute>
    ),
  },
  {
    path: "/casa_azul",
    element: (
      <ProtectedRoute>
        <TableElectoral idPerfil ={PERFIL_CASA}/>
      </ProtectedRoute>
    ),
  },
  {
    path: "/primero-fila",
    element: (
      <ProtectedRoute>
        <TableElectoral idPerfil ={PERFIL_FiL}/>
      </ProtectedRoute>
    ),
  },
  {
    path: "/observador",
    element: (
      <ProtectedRoute>
        <TableElectoral idPerfil ={PERFIL_OBSERVER}/>
      </ProtectedRoute>
    ),
  },
  {
    path: "/AvanceCasillas",
    element: (
      <ProtectedRoute>
        <AvanceCasillas />
      </ProtectedRoute>
    ),
  },
  {
    path: "/estructura-electoral",
    element: (
      <ProtectedRoute>
        <StructElectoral />
      </ProtectedRoute>
    ),
  },
];

export default ElectoralRoutes;
