import { ApiExec, ApiExecPublic } from "@utils/ApiExec";

class EventService {
  getEventsList = (data) => {
    return ApiExec(data, "eventos/get-my-records", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setEventsAsisenciaXochitl = (data) => {
    return ApiExec(data, "eventos/pase-lista-xg", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setEventsAsisenciaInforme = (data) => {
    return ApiExec(data, "eventos/setAsistencia1erInforme", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  statsAsisenciaXochitl = (data) => {
    return ApiExec(data, "eventos/xochitls-stats", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setEventsAsisencia = (data) => {
    return ApiExec(data, "eventos/pase-lista", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getEvents = (data) => {
    return ApiExecPublic(data, "app/users/public-get-event", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  validIne = (data) => {
    return ApiExecPublic(data, "app/users/public-get-user", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  registerEvent = (data) => {
    return ApiExecPublic(data, "app/users/public-set-guest-event", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  createUser = (params) => {
    return ApiExecPublic(params, "app/users/public-register-guest", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getListPase = (data) => {
    return ApiExec(data, "eventos/get-list-records-xochitl", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
}

const events = new EventService();
export default events;
