import { useEffect, useState } from "react";
import { ACTIONS_SPEED, COLUMNS_OPERATION } from "@data/constants/Sije";
import { useSorted } from "@hooks/useSorted";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "@hooks/useWindowDimensions";
import moment from "moment";
import Swal from "sweetalert2";
import sije from "@services/SijeService";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Typography,
} from "@mui/material";
import { blue, green, indigo, pink } from "@mui/material/colors";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import MinutesCard from "@components/Sije/OperationCasilla/MinutesCard";
import IncidentsCard from "@components/Sije/OperationCasilla/IncidentsCard";
import CasillaCard from "@components/Sije/OperationCasilla/CasillaCard";
import PlacementColumns from "@components/Charts/DashChart";
import BasicLine from "@components/Charts/BasicLine";
import BasicTable from "@components/Tables/BasicTable";
import FilterSije from "@components/Sije/OperationCasilla/Filter";
import CardLayout from "@components/Sije/OperationCasilla/CardLayout";
import Download from "@components/Downloads/Download";

const OperationCasilla = () => {
  const { height } = useWindowDimensions();

  //STATES
  const [loadingData, setLoadingData] = useState(false);
  const [total, setTotal] = useState(10);
  const [filteredChart, setFilteredChart] = useState({ filtered: [] });
  const [totalIncidencias, setTotalIncidencias] = useState([]);
  const [totalCasillas, setTotalCasillas] = useState([]);
  const [dataOperation, setDataOperation] = useState([]);
  const [isDownload, setIsDownloading] = useState(false);
  const [infoGraficasRCs, setInfoGraficasRCs] = useState({
    series: [],
    categories: [],
  });
  const [infoGraficasRGs, setInfoGraficasRGs] = useState({
    series: [],
    categories: [],
  });
  const [infoGraficas, setInfoGraficas] = useState({
    series: [],
    categories: [],
  });
  const [infoGraficasCierre, setInfoGraficasCierre] = useState({
    series: [],
    categories: [],
  });
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const [loadGetTotal, setLoadGetTotal] = useState(false);
  const [loadPaseLista, setLoadPaseLista] = useState(false);
  const [loadAperturaCierre, setLoadAperturaCierre] = useState(false);
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
  });
  const navigate = useNavigate();

  const getListSije = async (data) => {
    try {
      setLoadingData((prevState) => !prevState);
      const result = await sije.getListSije(data);
      const { response, results } = await result;
      if (results) {
        const data = response.data.map((item) => {
          return {
            ...item,
            Conectividad: (
              <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                <Icon
                  fontSize="small"
                  color={item.ConectividadInternet ? "primary" : "disabled"}
                >
                  wifi
                </Icon>
                <Icon
                  fontSize="small"
                  color={item.ConectividadTelefono ? "primary" : "disabled"}
                >
                  phone_iphone
                </Icon>
              </Stack>
            ),
            TipoTransmision: (
              <Box>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  icon={item.TipoTransmision.total}
                  direction="right"
                  sx={{
                    "& .MuiFab-primary": { width: 32, height: 32 },
                  }}
                >
                  {ACTIONS_SPEED.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      sx={{
                        width: 32,
                        height: 32,
                        color: item.TipoTransmision[action.type] && blue[700],
                      }}
                    />
                  ))}
                </SpeedDial>
              </Box>
            ),
            TotalIncidencias: (
              <Box
                component={"a"}
                onClick={() => {
                  navigate("/incidencias", { state: item.idCasilla });
                }}
                sx={{ cursor: "pointer" }}
              >
                <Badge badgeContent={item.TotalIncidencias} color={"warning"} />
              </Box>
            ),
          };
        });

        setDataOperation(data);
        setTotal(response.total);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingData((prevState) => !prevState);
    }
  };

  const getTotalesCards = async () => {
    try {
      setLoadGetTotal((prevState) => !prevState);
      const result = await sije.getTotalesCards({
        catalogs: [{ id: "casillas" }, { id: "incidencias" }],
        filtered: filteredChart.filtered,
      });
      const { response, message, results } = await result;
      if (results) {
        setTotalIncidencias(() => {
          return {
            Favorables: response.data.incidencias[0].value,
            Competidas: response.data.incidencias[1].value,
            Adversas: response.data.incidencias[2].value,
          };
        });

        setTotalCasillas(() => {
          return {
            CasillasAprobadas: parseInt(
              response.data.casillas[0].CasillasAprobadas !== null
                ? response.data.casillas[0].CasillasAprobadas
                : 0
            ),
            CasillasInstaladas: parseInt(
              response.data.casillas[0].CasillasInstaladas !== null
                ? response.data.casillas[0].CasillasInstaladas
                : 0
            ),
            CasillasAperturadas: parseInt(
              response.data.casillas[0].CasillasAperturadas !== null
                ? response.data.casillas[0].CasillasAperturadas
                : 0
            ),
            CasillasCerradas: parseInt(
              response.data.casillas[0].CasillasCerradas !== null
                ? response.data.casillas[0].CasillasCerradas
                : 0
            ),
            VotacionCerrada: parseInt(
              response.data.casillas[0].VotacionCerrada !== null
                ? response.data.casillas[0].VotacionCerrada
                : 0
            ),
          };
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadGetTotal((prevState) => !prevState);
    }
  };

  const getChartPaseListaRcs = async () => {
    try {
      setLoadPaseLista((prevState) => !prevState);
      const result = await sije.getChartPaseListaRcs(filteredChart);
      const { response, message, results } = await result;
      if (results) {
        setInfoGraficasRCs({
          categories: response.categories,
          series: response.series2.map((item) => parseInt(item)),
          Meta: response.series1.map((item) => parseInt(item)),
          Actualizado: response.date,
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadPaseLista((prevState) => !prevState);
    }
  };

  const getChartPaseListaRG = async () => {
    try {
      const result = await sije.getChartPaseListaRG(filteredChart);
      const { response, message, results } = await result;
      if (results) {
        setInfoGraficasRGs({
          categories: response.categories,
          Actualizado: response.date,
          series: response.Avance,
          Meta: response.Meta,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    }
  };

  const getChartAperturaCierreSije = async () => {
    try {
      setLoadAperturaCierre((prevState) => !prevState);
      const result = await sije.getChartAperturaCierreSije(filteredChart);
      const { response, message, results } = await result;
      if (results) {
        setInfoGraficas({
          categories: response.Apertura.categories,
          series: [
            {
              name: "Instalación",
              data: response.Apertura.Instalacion.map((item) => parseInt(item)),
              color: green[100],
            },
            {
              name: "Apertura",
              data: response.Apertura.Apertura.map((item) => parseInt(item)),
              color: green[800],
            },
          ],
          Actualizado: response.date,
        });

        setInfoGraficasCierre({
          categories: response.Cierre.categories,
          series: [
            {
              name: "Cierre Casilla",
              data: response.Cierre.Casilla.map((item) => parseInt(item)),
              color: pink[100],
            },
            {
              name: "Cierre Votación",
              data: response.Cierre.Votacion.map((item) => parseInt(item)),
              color: pink[800],
            },
          ],
          Actualizado: response.date,
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadAperturaCierre((prevState) => !prevState);
    }
  };

  const handleExport = async () => {
    try {
      setIsDownloading((prevState) => !prevState);
      const result = await sije.exportSije({
        params: { filtered: data.filtered },
        api: "sije/export-list-sije",
      });
      const { success } = await result;

      if (success) {
        Swal.fire({
          title: "Descargado con éxito.",
          icon: "success",
        });
      } else {
        throw "Ocurrió un error, contacte al administrador.";
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsDownloading((prevState) => !prevState);
    }
  };

  const onChangeFilter = ({ filtersChart, filtersData }) => {
    if (filtersChart.length > 0 || filtersData.length > 0) {
      setFilteredChart((prevState) => ({
        ...prevState,
        filtered: filtersChart,
      }));
      setData((prevState) => ({
        ...prevState,
        page: 0,
        filtered: filtersData,
      }));
    }
  };

  const onClearFilter = () => {
    if (filteredChart.filtered.length > 0 || data.filtered.length > 0) {
      setFilteredChart((prevState) => ({
        ...prevState,
        filtered: [],
      }));
      setData((prevState) => ({ ...prevState, filtered: [] }));
    }
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  useEffect(() => {
    getListSije(data);
  }, [data]);

  useEffect(() => {
    getTotalesCards();
    getChartPaseListaRcs();
    getChartAperturaCierreSije();
    getChartPaseListaRG();
  }, [filteredChart]);

  /* useEffect(() => {
    if (sortedData.length > 0) {
      console.log("Sort")
      const params = {
        ...data,
        sorted: sortedData,
      };

      getListSije(params);
    }
    //eslint-disable-next-line
  }, [sortedData]); */

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Operación de Casillas" />

        <Grid2 container spacing={2} direction={{ md: "row-reverse" }}>
          {/* Filtros */}
          <Grid2 xs={12} md={3}>
            <FilterSije
              onChangeFilter={onChangeFilter}
              onClearFilter={onClearFilter}
            />
          </Grid2>

          {/* Dashboard */}
          <Grid2 xs={12} md={9}>
            <Box component="div" marginBottom={1}>
              <Grid2 container>
                <Grid2 xs={12} md={12}>
                  <MinutesCard />
                </Grid2>
              </Grid2>
            </Box>

            <Box>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={4}>
                  {loadGetTotal ? (
                    <Skeleton
                      variant="rounded"
                      sx={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <CasillaCard data={totalCasillas} />
                  )}
                </Grid2>
                <Grid2 xs={12} md={4}>
                  {loadGetTotal ? (
                    <Skeleton
                      variant="rounded"
                      sx={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <IncidentsCard data={totalIncidencias} />
                  )}
                </Grid2>
                <Grid2 xs={12} md={4}>
                  {loadPaseLista ? (
                    <Skeleton sx={{ width: "100%", height: "100%" }} />
                  ) : (
                    <CardLayout
                      title={"PASE DE LISTA RCS"}
                      bgColor={indigo[50]}
                      color={indigo[800]}
                      icon={"checklist"}
                    >
                      <PlacementColumns
                        titulo={{ title: "", right: "Asistencia" }}
                        subtitle={{
                          subtitle: moment(infoGraficasRCs.Actualizado).format(
                            "DD/MM/YYYY HH:mm:ss"
                          ),
                        }}
                        datos={{
                          categories: infoGraficasRCs.categories,
                          series: [
                            {
                              name: "Meta",
                              color: indigo[300],
                              data: infoGraficasRCs.Meta,
                              pointPadding: 0.3,
                              pointPlacement: -0.1,
                            },
                            {
                              name: "Avance",
                              color: indigo[700],
                              data: infoGraficasRCs.series,
                              pointPadding: 0.3,
                              pointPlacement: -0.1,
                            },
                          ],
                        }}
                        height={height * 0.25}
                      />
                    </CardLayout>
                  )}
                </Grid2>
              </Grid2>
            </Box>

            <Box marginBottom={2}>
              <Grid2 container spacing={2}>
                {/*  <Grid2 xs={12} md={6}>
                  <Card className="card-primary">
                    <CardContent>
                      <Typography variant="body2" fontWeight={700}>
                        PASE DE LISTA RCS
                      </Typography>
                      <PlacementColumns
                        titulo={{ title: "", right: "Asistencia" }}
                        subtitle={{
                          subtitle: moment().format("DD/MM/YYYY H:mm"),
                        }}
                        datos={{
                          categories: infoGraficasRCs.categories,
                          series: [
                            {
                              name: "Meta",
                              color: blue[600],
                              data: infoGraficasRCs.Meta,
                              pointPadding: 0.3,
                              pointPlacement: -0.2,
                            },
                            {
                              name: "Avance",
                              color: blue[300],
                              data: infoGraficasRCs.series,
                              pointPadding: 0.3,
                              pointPlacement: -0.2,
                            },
                          ],
                        }}
                        height={height * 0.30}
                      />
                    </CardContent>
                  </Card>
                </Grid2> */}
                <Grid2 xs={12} md={4}>
                  {loadPaseLista ? (
                    <Skeleton sx={{ width: "100%", height: "100%" }} />
                  ) : (
                    <CardLayout
                      title={"PASE DE LISTA RGS"}
                      bgColor={indigo[50]}
                      color={indigo[800]}
                      icon={"checklist"}
                    >
                      <PlacementColumns
                        titulo={{ title: "", right: "Asistencia" }}
                        subtitle={{
                          subtitle: moment().format("DD/MM/YYYY HH:mm:ss"),
                        }}
                        datos={{
                          categories: infoGraficasRGs.categories,
                          series: [
                            {
                              name: "Meta",
                              color: indigo[300],
                              data: infoGraficasRGs.Meta,
                              pointPadding: 0.3,
                              // pointPlacement: -0.1,
                            },
                            {
                              name: "Avance",
                              color: indigo[700],
                              data: infoGraficasRGs.series,
                              pointPadding: 0.3,
                              //pointPlacement: -0.1,
                            },
                          ],
                        }}
                        height={height * 0.25}
                      />
                    </CardLayout>
                  )}
                </Grid2>
                <Grid2 xs={12} md={4}>
                  {loadAperturaCierre ? (
                    <Skeleton
                      variant="rounded"
                      sx={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <CardLayout
                      title={"INICIO"}
                      bgColor={green[100]}
                      color={green[800]}
                      icon={"lock_open"}
                    >
                      <BasicLine
                        title={{
                          primary: "",
                          sub: moment(infoGraficas.Actualizado).format(
                            "DD/MM/YYYY HH:mm:ss"
                          ),
                        }}
                        series={infoGraficas.series}
                        categories={infoGraficas.categories}
                        height={height * 0.25}
                      />
                    </CardLayout>
                  )}
                </Grid2>
                <Grid2 xs={12} md={4}>
                  {loadAperturaCierre ? (
                    <Skeleton
                      variant="rounded"
                      sx={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <CardLayout
                      title={"CIERRE"}
                      bgColor={pink[100]}
                      color={pink[800]}
                      icon={"lock"}
                    >
                      <BasicLine
                        title={{
                          primary: "",
                          sub: moment(infoGraficasCierre.Actualizado).format(
                            "DD/MM/YYYY HH:mm:ss"
                          ),
                        }}
                        series={infoGraficasCierre.series}
                        categories={infoGraficasCierre.categories}
                        height={height * 0.25}
                      />
                    </CardLayout>
                  )}
                </Grid2>
              </Grid2>
            </Box>

            {/*             <Box>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={6}>
                  <Card className="card-primary">
                    <CardContent>
                      <Typography variant="body2" fontWeight={700}>
                        INICIO
                      </Typography>
                      <BasicLine
                        title={{
                          primary: "",
                          sub: new Date().toLocaleDateString(),
                        }}
                        series={infoGraficas.series}
                        categories={infoGraficas.categories}
                      />
                    </CardContent>
                  </Card>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <Card className="card-primary">
                    <CardContent>
                      <Typography variant="body2" fontWeight={700}>
                        CIERRE
                      </Typography>
                      <BasicLine
                        title={{
                          primary: "",
                          sub: new Date().toLocaleDateString(),
                        }}
                        series={infoGraficasCierre.series}
                        categories={infoGraficasCierre.categories}
                      />
                    </CardContent>
                  </Card>
                </Grid2>
              </Grid2>
            </Box> */}

            <Card className="card-primary">
              <CardContent>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  marginBottom={2}
                >
                  <Typography variant="body2" fontWeight={700}>
                    OPERACIÓN CASILLA
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    startIcon={<Icon>download</Icon>}
                    size="small"
                    disabled={isDownload}
                    onClick={handleExport}
                    // sx={{ borderRadius: "10px" }}
                  >
                    Exportar
                  </Button>
                </Stack>
                {isDownload && (
                  <Box marginBottom={2}>
                    <Download format={"xlsx"} isDownload={isDownload} />
                  </Box>
                )}
                <BasicTable
                  rows={dataOperation}
                  hcolumns={COLUMNS_OPERATION}
                  total={total}
                  pageProp={data.page}
                  pageSize={data.pageSize}
                  minHeight="0px"
                  stickyHeader={true}
                  isLoading={loadingData}
                  handleChangePagination={handleChangePagination}
                  handleManualSort={handleRequestSort}
                />
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default OperationCasilla;
