import React from "react";
import IconMap from "../../Images/map.png";
const ControlCenterView = ({handleClickEvent}) => {
  const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
  }
  const positionClass =
    POSITION_CLASSES["bottomleft"]
  const handleClick = () => {
    handleClickEvent();
  }
  return (
    <div className="leaflet-control-container">
    <div className={positionClass}>
      <div className="leaflet-bar leaflet-control">
        
        <div onClick={handleClick} style={{height:"30px", width:"30px"}}>
        <img alt="Cambiar tipo de vista" style={{backgroundColor:"white", padding:"5px"}} src={IconMap} height={"30px"} width={"30px"}></img>
        </div>
       
      </div>
    </div>
    </div>
  )
}
export default ControlCenterView;