import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
//MUI
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CardActions,
  TextField,
  Stack,
} from "@mui/material";
import BasicTable from "@components/Tables/BasicTable";
import Select from "react-select";
import ActionHeader from "@components/Containers/ActionHeader";
import events from "@services/EventsServices";
import Swal from "sweetalert2";
import { grey } from "@mui/material/colors";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { isEmptyOrNullObject } from "@utils/validations";
import CardCommitments from "@components/Card/CardStats";
import { numberWithCommas } from "@utils/Utilities";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
const ListAsistencia = () => {
  //Constants
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("table");
  const [filter, setFilter] = useState({
    Pase: { value: 1, label: "Con Asistencia" },
    Nombre: "",
  });
  const [data, setData] = useState({
    page: 0,
    pageSize: 20,
    filtered: [],
    sorted: [{ id: "eventos_xochitl_asistieron.FechaCreo", value: "asc" }],
  });
  const [dataListEvents, setDataListEvents] = useState([]);
  const [total, setTotal] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const [eventoStats, setEventoStats] = useState({
    id: null,
    UUID: null,
    Invitados: 1,
    Asistieron: 0,
  });
  const [asistencia] = useState([
    { value: 0, label: "Todos" },
    { value: 1, label: "Con Asistencia" },
    { value: 2, label: "Sin Asistencia" },
  ]);
  const colums = [
    {
      label: "Nombre",
      id: "NOMBRE",
      columnAction: false,
      width: 900,
      orderBy: "NOMBRE",
    },
    {
      label: "Fecha Asistencia",
      id: "HoraLlego",
      align: "center",
      columnAction: false,
      width: 300,
    },
  ];

  useEffect(() => {
    handleChangeFilter();
  }, []);

  useEffect(() => {
    getEventosData(data);
    getStatsEventosData();
    // eslint-disable-next-line
  }, [data]);

  const getEventosData = () => {
    setLoading(true);
    events
      .getListPase(data)
      .then((res) => {
        if (res.results) {
          const Eventos = res.response.data.map((item) => {
            return {
              ...item,
            };
          });
          setDataListEvents(Eventos);
          setTotal(res.response.data.length);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  };

  const getStatsEventosData = () => {
    setLoading(true);
    events
      .statsAsisenciaXochitl({})
      .then((res) => {
        // console.log("=>stats.data", res);
        if (res.results) {
          if (res.response.data) {
            // console.log("=>:res.response.data", res.response.data);
            setEventoStats(res.response.data);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  };

  const handleChangeFilter = () => {
    let filtered = [];

    if (filter.Pase.value > 0) {
      if (filter.Pase.value === 1) {
        filtered.push({
          id: "eventos_xochitl_asistieron.FechaCreo",
          filter: "IS NOT",
          value: "NULL",
        });
      } else {
        filtered.push({
          id: "eventos_xochitl_asistieron.FechaCreo",
          filter: "IS",
          value: "NULL",
        });
      }
    }

    if (filter.Nombre !== "") {
      let newFil = filter.Nombre.replace(/\s+/g, "%");
      filtered.push({
        id: "_qrs_xochitl1.NOMBRE",
        filter: "LIKE",
        value: `%${newFil}%`,
      });
    }

    setData((prevState) => ({ ...prevState, filtered }));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Actividad: "eventos.Actividad",
      HoraInicio: "eventos.HoraInicio",
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  const cleanFilter = () => {
    setFilter({
      Pase: { value: 0, label: "Todos" },
      Nombre: "",
    });

    setData((prevState) => ({ ...prevState, filtered: [] }));
  };

  return (
    <AdminLayout>
      <Container maxWidth={false} sx={{ backgroundColor: grey }}>
        <ActionHeader title="Listado de Asistencia" />

        <Box>
          <Grid2 container>
            <Grid2 item xs={12} md={6}>
              <CardCommitments
                icon={"fact_check"}
                title={"Invitados"}
                total={`${numberWithCommas(
                  eventoStats.Asistieron
                )} de ${numberWithCommas(eventoStats.Invitados)}`}
                subIcon={"update"}
                subtitle={`${(
                  100 *
                  (eventoStats.Asistieron / eventoStats.Invitados)
                ).toFixed(2)}%`}
                loading={loading}
              />
            </Grid2>
          </Grid2>
          <Card className="card-primary">
            <CardActions sx={{ width: "100%", display: "block" }}>
              <Grid2 container spacing={1} sx={{ alignItems: "end" }}>
                <Grid2 item xs={12} md={12} xl={6}>
                  <Select
                    placeholder="Pase de lista"
                    autosize={true}
                    // styles={selectStyles}
                    closeMenuOnSelect={false}
                    /* components={animatedComponents} */
                    value={filter.Pase}
                    onChange={(e) => setFilter({ ...filter, Pase: e })}
                    options={asistencia}
                  />
                </Grid2>
                <Grid2 item xs={12} md={12} xl={6}>
                  <TextField
                    fullWidth
                    required
                    label="Nombre"
                    type="text"
                    name="Nombre"
                    onChange={(e) =>
                      setFilter({ ...filter, Nombre: e.target.value })
                    }
                    value={filter.Nombre}
                    variant="outlined"
                    size="small"
                    className="fixed-input"
                  />
                </Grid2>
                <Grid2 item xs={12} md={12} xñ={12}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={1}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      variant="contained"
                      color="primaryDark"
                      onClick={() => handleChangeFilter()}
                    >
                      Filtrar
                    </Button>
                    <Button
                      variant="outlined"
                      color="primaryDark"
                      onClick={() => cleanFilter()}
                    >
                      Limpiar
                    </Button>
                  </Stack>
                </Grid2>
              </Grid2>
            </CardActions>
            <CardContent>
              <Grid2 container sx={{ p: 2 }} spacing={2}>
                <Grid2 xs={12} sm={12} md={4} lg={4}></Grid2>
              </Grid2>
              <BasicTable
                rows={dataListEvents}
                hcolumns={colums}
                handleChangePagination={handleChangePagination}
                isLoading={loading}
                total={total}
                pageProp={data.page}
                pageSize={data.pageSize}
                stickyHeader={true}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default ListAsistencia;
