import {
  Button,
  Card,
  Container,
  Grid,
  Icon,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import QrCode from "@mui/icons-material/QrCode";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import { EventosInvitadosFilter } from "@data/interfaces/EventosInterfaces";
import { EventosInvitadosFilterSchema } from "@data/schemas/EventosSchemas";

const PaseListaInfoFilter = ({ onChangeFilter, onGetQR }) => {
  const formik = useFormik({
    initialValues: EventosInvitadosFilter,
    validationSchema: EventosInvitadosFilterSchema,
    onSubmit: (values) => {
      handleFilter(values);
    },
  });

  const handleFilter = (values) => {
    const { celular } = values;
    let filtered = [];

    if (formik.values.actividad !== "") {
      filtered.push({
        id: ["eventos.Actividad"],
        filter: "LIKE",
        value: celular,
      });
    }

    onChangeFilter(filtered);
  };

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.submitForm();
    }
  };

  const handleClearFilter = () => {
    formik.setValues({
      ...formik,
      celular: "",
    });

    onChangeFilter([]);
  };

  return (
    <Card className="card-primary">
      <Container maxWidth={false} sx={{ p: 2 }}>
        <Stack direction="row" spacing={1} marginBottom={2} alignItems="center">
          <Icon>tune</Icon>
          <Typography variant="subtitle1" fontWeight={600}>
            Buscar
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              sx={{ width: "100%" }}
              label="Celular"
              type="text"
              name="Celular o Folio"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.celular.toUpperCase()}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={3} lg={3}>
            {/* <Button
              variant="contained"
              color="primaryDark"
              onClick={formik.submitForm}
            >
              Filtrar
            </Button>
            <Button
              variant="text"
              color="primaryDark"
              onClick={handleClearFilter}
              sx={{ ml: 1 }}
            >
              Limpiar
            </Button> */}

            <Button
              variant="contained"
              color="primaryDark"
              startIcon={<QrCode />}
              onClick={onGetQR}
              sx={{ ml: 1 }}
            >
              Escanear QR
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};

PaseListaInfoFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  onGetQR: PropTypes.func.isRequired,
};

export default PaseListaInfoFilter;
