import React, { useState } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconFilter from "../../Images/filter.png";

let style_square = {
  padding: "6px 8px",
  font: "14px/16px Arial, Helvetica, sans-serif",
  background: "rgba(255,255,255,0.8)",
  boxShadow: "0 0 15px rgba(0,0,0,0.2)",
  borderRadius: "5px",
  marginTop: "55px",
};
const ControlFilter = ({ component, title }) => {
  const [showCard, setShowCard] = useState(true);

  return (
    <>
      {showCard ? (
        <div className="leaflet-control-container">
          <div className="leaflet-top leaflet-left">
            <div className="leaflet-control" style={style_square}>
              <Card className="card-secondary">
                <CardHeader
                  title={title}
                  style={{ textAlign: "center" }}
                  subheader={""}
                  titleTypographyProps={{ fontSize:"16px" }}
                  action={
                    <IconButton
                      onClick={() => {
                        setShowCard(false);
                      }}
                      aria-label="Minimizar"
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  }
                />
                <CardContent>{component}</CardContent>
              </Card>
            </div>
          </div>
        </div>
      ) : (
        <div className="leaflet-control-container">
          <div className="leaflet-top leaflet-left">
            <div
              className="leaflet-bar leaflet-control"
              style={{ marginTop: "55px" }}
            >
              <div
                onClick={() => setShowCard(true)}
                style={{ height: "30px", width: "30px" }}
              >
                <img
                  alt="Centrar vista"
                  style={{ backgroundColor: "white", padding: "5px" }}
                  src={IconFilter}
                  height={"30px"}
                  width={"30px"}
                ></img>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ControlFilter;
