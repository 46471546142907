import { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Stack,
  Modal,
  Card,
  CardContent,
  Divider,
  CardHeader,
  IconButton,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
  Icon,
} from "@mui/material";
import QrReader from "react-qr-scanner";
import Swal from "sweetalert2";
import Grid2 from "@mui/material/Unstable_Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { orange } from "@mui/material/colors";

import UserServices from "@services/UserServices";
import BasicList from "@components/Lists/BasicList";
import { isTypePhone } from "@utils/validations";

const Item = ({ item, selectedIndex, handleSelectedIndex }) => {
  const handleListItemClick = (event, index) => {
    let data = index;
    handleSelectedIndex(index, data);
  };

  return (
    <ListItem key={item.value ? item.value : Math.random()} disablePadding>
      <ListItemButton
        selected={selectedIndex === item.value}
        onClick={(event) => handleListItemClick(event, item.value)}
      >
        <ListItemText
          primary={`${item.label}(${item.Edad} años) - ${item.Municipio}`}
          secondary={`Loc: ${item.Localidad} - Col. ${item.ColoniaVive}`}
        />
      </ListItemButton>
    </ListItem>
  );
};

const ModalQR = (props) => {
  const { openModal, handleCloseModal, data } = props;

  return (
    <Modal open={openModal} onClose={(e) => handleCloseModal()}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "60%",
          maxHeight: "100%",
          overflowY: "auto",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          p: 2,
        }}
      >
        <CardHeader
          title={"Escanear QR"}
          action={
            <IconButton aria-label="cancel" onClick={() => handleCloseModal()}>
              <CancelIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Box>Data: {data}</Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ModalQR;
