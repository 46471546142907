import { useEffect, useState } from "react";
import { _round, numberWithCommas } from "@utils/Utilities";

import {
  Box,
  Typography,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import CardLayout from "./CardLayout";
import BasicSelect from "@components/Selects/BasicSelect";

import sije from "@services/SijeService";
import Swal from "sweetalert2";

function LinearProgressWithLabel(props) {
  //${Math.round(props.value)}
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant={props.loading ? "indeterminate" : "determinate"}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}

const FilterTipoEleccion = ({ options, loading, handleChange, value }) => {
  return (
    <>
      <BasicSelect
        name="TipoEleccion"
        label="Tipo Elección"
        options={options.tipos_eleccion}
        value={value}
        onChange={handleChange}
        isLoading={loading}
        sx={{ width: "100%" }}
      />
    </>
  );
};

const MinutesCard = () => {
  const idAmbitoAgrupadorMap = {
    3: 3,
    5: 4,
    1: 5,
    2: 5,
    4: 5,
    6: 5,
  };

  const [loadingCatalog, setLoadingCatalog] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [catalogTypeElection, setCatTypeElection] = useState([]);
  const [valueTypeElection, setValueTypeElection] = useState(3);
  const [labelElection, setLabelElection] = useState("");
  const [minutesTotal, setMinutesTotal] = useState({
    minutesReceived: 0,
    minutesTotal: 0,
    porcent: 0,
  });
  const [filteredElection, setFilteredElection] = useState({
    idTipoEleccion: 3,
    ValorAmbito: 0,
    idAmbitoAgrupador: 3,
  });

  const getCatalogTypeElection = async () => {
    try {
      setLoadingCatalog((prevState) => !prevState);
      const result = await sije.getCatalogs({
        catalogs: [{ id: "tipos_eleccion" }],
      });
      const { results, response, message } = await result;
      if (results) {
        const { label } = response.catalogs.tipos_eleccion.find(
          (item) => item.value === valueTypeElection
        );
        setCatTypeElection(response.catalogs);
        setLabelElection(label);
      } else {
        throw message;
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalog((prevState) => !prevState);
    }
  };

  const getSummaryMinutes = async () => {
    try {
      setLoadingData((prevState) => !prevState);
      const result = sije.getSummary(filteredElection);
      const { response, message, results } = await result;
      if (results) {
        setMinutesTotal({
          ...minutesTotal,
          minutesReceived: response.ActasRecibidas,
          minutesTotal: response.TotalActas,
          porcent: response.PActas,
        });
      } else {
        throw message;
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingData((prevState) => !prevState);
    }
  };

  const handleChangeTypeElection = (e) => {
    const idTypeElection = e.target.value;
    const { label } = catalogTypeElection.tipos_eleccion.find(
      (item) => item.value === idTypeElection
    );
    setValueTypeElection(idTypeElection);
    setLabelElection(label);

    if (idTypeElection > 0) {
      const idAmbitoAgrupador = idAmbitoAgrupadorMap[idTypeElection] || null;
      setFilteredElection((prevState) => ({
        ...prevState,
        idTipoEleccion: idTypeElection,
        idAmbitoAgrupador,
      }));
    }
  };

  useEffect(() => {
    getCatalogTypeElection();
  }, []);

  useEffect(() => {
    getSummaryMinutes();
  }, [filteredElection]);

  return (
    <CardLayout
      title={"Actas Recibidas"}
      subtitle={labelElection}
      bgColor={blueGrey[50]}
      color={blueGrey[800]}
      icon={"description"}
      component={
        <FilterTipoEleccion
          options={catalogTypeElection}
          loading={loadingCatalog}
          value={valueTypeElection}
          handleChange={handleChangeTypeElection}
        />
      }
    >
      <div>
        {loadingData ? (
          <CircularProgress />
        ) : (
          <Typography variant="h4">
            {numberWithCommas(minutesTotal.minutesReceived)} de{" "}
            {numberWithCommas(minutesTotal.minutesTotal)}
          </Typography>
        )}
        <LinearProgressWithLabel
          value={minutesTotal.porcent}
          loading={loadingData}
        />
      </div>
    </CardLayout>
  );
};

export default MinutesCard;
