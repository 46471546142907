import { useRoutes } from "react-router-dom";
import { Routes } from "./routes";
import { ProtectedRoute, AlreadyLoggedIn } from "@middlewares";

import Welcome from "@pages/Welcome/Welcome";
import NotFound from "@pages/auth/NotFound";

const Router = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: <AlreadyLoggedIn />,
    },
    {
      path: "/welcome",
      element: (
        <ProtectedRoute>
          <Welcome />
        </ProtectedRoute>
      ),
    },
    {
      path: "*",
      element: <NotFound />,
    },
    ...Routes,
  ]);

  return routes;
};

export default Router;
