import React from 'react';
import {
	Icon
} from "@mui/material";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { isEmptyOrInvalidArray, isNullOrUndefined } from "@utils/validations";
import CircularProgress from '@mui/material/CircularProgress';

export default function Tree(props) {
	const {
		data,
		loading,
		handleClick,
		typeData = { value: "id", label: "label", S_label: "", T_label: "" },
		isSameIds = false,
		sameId = ""
	} = props;

	const renderTree = (nodes) => (
		<TreeItem
			nodeId={`${nodes[typeData.value]} ${isSameIds ? !isNullOrUndefined(nodes[sameId]) ? "-" + nodes[sameId] : "" : ""} `}
			label={`${nodes[typeData.T_label] ? `${nodes[typeData.T_label]} -` : ""} ${nodes[typeData.label]} ${nodes[typeData.S_label] ? nodes[typeData.S_label] : ""}`} onClick={() => handleClick(nodes)}
		>
			{!isEmptyOrInvalidArray(nodes.children)
				? nodes.children.map((node) => renderTree(node))
				: null}
		</TreeItem>
	);
	const TreeComponent = (
		<TreeView
			defaultCollapseIcon={<Icon>expand_more</Icon>}
			defaultExpandIcon={<Icon color="primary">chevron_right</Icon>}
			defaultEndIcon={<Icon sx={{color:"lightgray"}}>chevron_right</Icon>}
		>
			{data.map((item) => renderTree(item))}
		</TreeView>
	);

	return (
		<>
			{
				loading ? <CircularProgress /> :
					data.length !== 0 ? <>{TreeComponent}</> : <>Sin datos</>
			}
		</>
	)
}
