import { Public, ProtectedRoute } from "@middlewares";
import Eventos from "@pages/Events/Eventos.jsx";
import EventosPaseLista from "@pages/Events/EventosPaseLista.jsx";
import EventosPaseListaInfo from "@pages/Events/EventosPaseListaInfo.jsx";
import QRAsistencia from "../../pages/Events/QRAsistencia";
import PreRegistro from "../../pages/Events/PreRegistro";
import ListAsistencia from "../../pages/Events/ListAsistencia";

const EventsRoutes = [
  {
    path: "/eventos/preregistro/:id",
    element: (
      <Public>
        <PreRegistro />
      </Public>
    ),
  },
  {
    path: "/eventos",
    element: (
      <ProtectedRoute>
        <Eventos />
      </ProtectedRoute>
    ),
  },
  {
    path: "/eventos/pase-lista",
    element: (
      <ProtectedRoute>
        <EventosPaseLista />
      </ProtectedRoute>
    ),
  },
  {
    path: "/eventos/pase-lista/:id",
    element: (
      <ProtectedRoute>
        <EventosPaseListaInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: "/eventos/asistenciaQR/:id",
    element: (
      <Public>
        <QRAsistencia />
      </Public>
    ),
  }, 
  {
    path: "/eventos/lista-asistencia",
    element: (
      <ProtectedRoute>
        <ListAsistencia />
      </ProtectedRoute>
    ),
  },
  
];

export default EventsRoutes;
