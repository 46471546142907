import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

import LoadingForm from "@components/LinearProgress/LoadingForm";
import VolunteerInfo from "@components/Volunteers/VolunteerInfo";
import TerritorialForm from "@components/Volunteers/TerritorialForm";
import VolunteerServices from "@services/VolunteerServices";
import middleware from "@middlewares/middleware";

const VolunteerForm = (props) => {
  const { setView, volunteer, catalogs, onSubmit } = props;
  const [catResponsibilitiesType, setCatResponsibilitiesType] = useState({});
  const [catResponsibilities, setCatResponsibilities] = useState([]);
  const [formType, setFormType] = useState(0);
  const [assigned, setAssigned] = useState([]);
  //LoadigForm
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [perfiles, setPerfiles] = useState([]);

  useEffect(() => {
    /*     let _array = catalogs.perfiles_hijos.filter(item => [TIPO_TERRITORIAL].includes(item.idTipo)).map(item => {
            return {
                idTipo: item.idTipo,
                Tipo: item.Tipo,
            }
        });

        _array = [...new Set(_array.map(o => JSON.stringify(o)))].map(s => JSON.parse(s)); */
    let idsTypesPermitidos = [];
    let TypesPermitidos = {};
    catalogs.perfiles_hijos.forEach((element) => {
      if (
        idsTypesPermitidos.indexOf(element.idTipo) === -1 &&
        element.idTipo !== undefined &&
        element.idTipo !== 4
      ) {
        TypesPermitidos[element.idTipo] = element.Tipo;
        idsTypesPermitidos.push(element.idTipo);
      }
    });

    setCatResponsibilitiesType(TypesPermitidos);
    setPerfiles(
      catalogs.perfiles_hijos.map((item) => {
        return {
          ...item,
          id: item.value,
          value: `${item.value}-${item.idAmbitoCargo}`,
        };
      })
    );

    /*      setCatResponsibilities(() => {
            let dataResponsabilidad = {};
            Object.keys(types).forEach((key) => {
                dataResponsabilidad[key] = catalogs.perfiles_hijos.filter(item => [key].includes(item.idTipo)).map(item => {
                    return {
                        idTipo: item.idTipo,
                        Tipo: item.Tipo,
                    }
                });
            });
    
            return dataResponsabilidad;
        }); */

    // setCatResponsibilitiesType(dataResponsabilidad)

    /*  handleClickResponsibility(_array[0].idTipo); */
    //eslint-disable-next-line
  }, [catalogs]);

  const handleClickBackButton = () => {
    setView("table");
  };

  const handleClickResponsibility = (idTipo) => {
    setFormType(idTipo);
    setCatResponsibilities(
      perfiles.filter((row) => row.idTipo === parseInt(idTipo))
    );
  };

  const handleSubmit = (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);
    let newData = values;
    delete newData.idPefilAmbito;
    VolunteerServices.createResponsibility(newData)
      .then((res) => {
        if (res.results) {
          onSubmit(values);
          setAssigned([...assigned, values]);
          Swal.fire({
            title: res.message,
            icon: "success",
          });
        } else {
          Swal.fire({
            title: res.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: "warning" }))
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
        setIsSuccessFormSave(true);
      });
  };

  return (
    <Box>
      <LoadingForm
        loadinMessage={"Enviando datos..."}
        successMessage="¡Enviado con éxito!"
        isLoading={isLoadigForm}
        success={isSuccessFormSave}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />
      <Grid2 container spacing={2}>
        <Grid2 xs={12} sm={12} md={4} lg={4}>
          <VolunteerInfo
            handleClick={handleClickBackButton}
            volunteer={volunteer}
            assigned={assigned}
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={8} lg={8}>
          {middleware.checkMenuActionId("Editar", 7) && (
            <Card
              className="card-primary"
              sx={{ width: "100%", bgcolor: "background.paper", p: 2 }}
            >
              <CardActions sx={{ overflow: "auto" }}>
                <Grid2
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                    <Typography
                      component={"h6"}
                      variant="h6"
                      fontWeight={800}
                      fontSize={18}
                    >
                      AGREGAR PROPUESTAS DE RESPONSABILIDAD
                    </Typography>
                    <Stack
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="div">
                        Tipo de responsabilidad
                      </Typography>

                      <ButtonGroup size="large" variant="outlined">
                        {Object.keys(catResponsibilitiesType).map(
                          (item, index) => {
                            return (
                              <Button
                                color="info"
                                key={Math.random()}
                                onClick={(e) => handleClickResponsibility(item)}
                                variant={
                                  item === formType ? "contained" : "outlined"
                                }
                                sx={{
                                  borderWidth: "1px",
                                  borderRadius: "30px",
                                  pr: "23 px",
                                  pl: "23 px",
                                }}
                              >
                                {catResponsibilitiesType[item]}
                              </Button>
                            );
                          }
                        )}
                      </ButtonGroup>
                    </Stack>
                </Grid2>
              </CardActions>
              <CardContent sx={{ overflow: "auto" }}>
                {formType !== 0 && (
                  <TerritorialForm
                    type={catResponsibilitiesType[formType]}
                    idType={formType}
                    volunteer={volunteer}
                    catResponsibilities={catResponsibilities}
                    catalogs={catalogs}
                    onSubmit={handleSubmit}
                    assigned={assigned}
                  />
                )}
              </CardContent>
            </Card>
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
};

VolunteerForm.propTypes = {
  setView: PropTypes.func.isRequired,
  volunteer: PropTypes.object.isRequired,
  catalogs: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

VolunteerForm.defaultProps = {};

export default VolunteerForm;
