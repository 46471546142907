import { Public } from "@middlewares";

import PaseListaQR from "@pages/auth/QRs";

const InformeGobRoutes = [
  {
    path: "/qrs",
    element: (
      <Public>
        <PaseListaQR />
      </Public>
    ),
  },
];

export default InformeGobRoutes;
