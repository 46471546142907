import AdminLayout from "@components/MainPage/AdminLayout";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
  GlobalStyles,
  Container,
  CircularProgress
} from "@mui/material";
import StarIcon from "@mui/icons-material/StarBorder";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { blue } from '@mui/material/colors';
import fondoBack from "@assets/img/Back.jpg"
import MessageAlert from "@components/Downloads/MessageAlert";
import TerritorialService from "../../services/TerritorialServices";
import { getVars } from "@utils/global";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {/*  {''}
      <Link color="inherit" href="https://mui.com/">

      </Link>{' '}
      {new Date().getFullYear()}
      {'.'} */}
    </Typography>
  );
}



const FCO = () => {
  const [access_token] = useState(() => {
    let t = getVars("Token");
    if (t !== {}) return t.access_token;
    return "";
  });
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [buttonLoading,setButtonLoading] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);

  const getData = () => {
    TerritorialService.getDownloadPermissions()
     .then((res) => {
      if (res.results) {
        let dato = res.response.data;
        let array = [];
        console.log(dato);
        dato.forEach((element) => {
          for (const key in element) {
            switch (key) {
              case "F1xN":
                if(!array.find(i => i.title === "1XN")){
                  if(dato[0]["F1xN"] === 1){
                    array.push(
                      {
                        title: "1XN",
                        subheader: "FCO-01",
                        url:"F1xN",
                        icon: <DescriptionOutlinedIcon sx={{ fontSize: 100, color: blue[800]}} />,
                        description: ["Formato para impresión"],
                        buttonText: "Descargar",
                        buttonVariant: "contained",
                      }
                    )
                  }
                }
                break;
              case "FSeccional":
                if(!array.find(i => i.title === "FSeccional")){
                  if(dato[0]["FSeccional"] === 1){
                    array.push(
                      {
                        title: "FSeccional",
                        subheader: "FCO-03",
                        url:"FSeccional",
                        icon: <DescriptionOutlinedIcon sx={{ fontSize: 100, color: blue[800]}} />,
                        description: ["Formato para impresión"],
                        buttonText: "Descargar",
                        buttonVariant: "contained",
                      }
                    )
                  }
                }
                break;
              case "FPoligonal":
                if(!array.find(i => i.title === "FPoligonal")){
                  if(dato[0]["FPoligonal"] === 1){
                  array.push(
                    {
                      title: "FPoligonal",
                      subheader: "FCO-04",
                      url:"FPoligonal",
                      icon: <DescriptionOutlinedIcon sx={{ fontSize: 100, color: blue[800]}} />,
                      description: ["Formato para impresión"],
                      buttonText: "Descargar",
                      buttonVariant: "contained",
                    }
                  )
                  }
                }
                break;
              case "Manzanera1xN":
                if(!array.find(i => i.title === "Manzanera1xN")){
                  if(dato[0]["Manzanera1xN"] === 1){
                  array.push(
                    {
                      title: "Manzanera1xN",
                      subheader: "FCO-02",
                      url:"Manzanera1xN",
                      icon: <DescriptionOutlinedIcon sx={{ fontSize: 100, color: blue[800]}} />,
                      description: ["Formato para impresión"],
                      buttonText: "Descargar",
                      buttonVariant: "contained",
                    }
                  )
                  }
                }
                break;
              default:
                break;
            }
          }
        });
        setData(array);
      } else {
        Swal.fire({ title: res.message, icon: "warning" });
      }
    })
    .catch((e) => {
      Swal.fire({ title: e.message, icon: "warning" });
    })
    .finally(()=> setLoading(false));
  };

  useEffect(()=>{
    setLoading(true);
    getData();
  },[])

  const Exportar = (type,e) => {
    
      if(buttonLoading.includes(e)){
          return false;
      }
      let array = [...buttonLoading, e];
      setButtonLoading(array);
      TerritorialService.exportFormulario({"type":type},access_token)
        .then((res) => {
        })
        .catch((error) => {
          setShowMessage(true);
          setSuccessDownload(false);
        })
        .finally(() => { 
          let filter = array.filter(item => item !== e);
          setButtonLoading(filter);
        });
  };

  return (
    <AdminLayout>
      <Container maxWidth="false"  sx={{backgroundImage:`url(${fondoBack})`}}>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        />
        <CssBaseline />

        {/* Hero unit */}
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            FORMATO DE CONTROL OPERATIVO
          </Typography>
         {/*  <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            component="p"
          >
          </Typography> */}
        </Container>
        {/* End hero unit */}
        <Container maxWidth="md" component="main">
          <Grid container spacing={3} alignItems="flex-end">
          {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}
            {loading && <CircularProgress />}
            {data.map((tier,index) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === "Enterprise" ? 12 : 6}
                md={3}
              >
                <Card key={index} sx={{borderRadius:"10px"}}>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? blue[50]
                          : blue[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      {tier.icon}
                      <Typography
                        component="h2"
                        variant="h3"
                        color="text.primary"
                      >
                        {tier.subTitle}
                      </Typography>
                    </Box>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth disabled={buttonLoading.includes(index)} variant={tier.buttonVariant} onClick={()=>{Exportar(tier.url,index)}}>
                      {buttonLoading.includes(index) ? <CircularProgress /> : tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        {/* Footer */}
        <Container
          maxWidth="md"
          component="footer"
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 8,
            py: [3, 6],
          }}
        >
          <Grid container spacing={4} justifyContent="space-evenly"></Grid>
          <Copyright sx={{ mt: 20 }} />
        </Container>
        {/* End footer */}
      </Container>
    </AdminLayout>
  );
};

export default FCO;
