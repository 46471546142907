import React, { useState } from "react";
import {
  InputAdornment,
  IconButton,
  Button,
  Icon,
  TextField,
  Grid,
} from "@mui/material";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { isEmptyOrInvalidString } from "@utils/validations";
import { UserPasswordInterface } from "@data/interfaces/UserInterfaces";
import { UserPasswordSchema } from "@data/schemas/UserSchemas";
import UserService from "@services/UserServices";
import { getVars } from "@utils/global";

const UserPassword = (props) => {
  const { idUsuario } = props;
  const [flags, setFlags] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const [access_token] = useState(() => {
    let t = getVars("Token");
    if (t !== {}) return t.access_token;
    return "";
  });

  const formik = useFormik({
    initialValues: UserPasswordInterface,
    validationSchema: UserPasswordSchema,
    onSubmit: (values) => {
      values.id = idUsuario;
      handleSave(values);
    },
  });

  const handleSave = (data) => {
    data.id = idUsuario;
    UserService.updatePassword(data, access_token)
      .then((res) => {
        if (res.results) {
          Swal.fire({
            title: res.message,
            icon: "success",
          }).then((res) => {
            if (res) {
            }
          });
        } else {
          Swal.fire({
            title: res.message,
            icon: "warning",
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Ha ocurrido un error",
          text: e.message,
          icon: "warning",
        });
      });
  };

  const handleClickShowPassword = (prop) => (event) => {
    setFlags({ ...flags, [prop]: !flags[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCleanForm = (e) => {
    formik.setValues(UserPasswordInterface);
  };

  return (
    <>
      <Grid item xs={12} sm={12}>
        <TextField
          error={
            formik.touched.Password &&
            !isEmptyOrInvalidString(formik.errors.Password)
          }
          label="Contraseña"
          helperText={
            formik.touched.Password &&
            formik.errors.Password &&
            formik.errors.Password
          }
          type={flags.showPassword ? "text" : "password"}
          name="Password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.Password}
          size="small"
          className="fixed-input"
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword("showPassword")}
                  onMouseDown={handleMouseDownPassword}
                >
                  {flags.showPassword ? (
                    <Icon>visibility_off</Icon>
                  ) : (
                    <Icon>visibility</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          error={
            formik.touched.Confirm &&
            !isEmptyOrInvalidString(formik.errors.Confirm)
          }
          label="Confirmar contraseña"
          helperText={
            formik.touched.Confirm &&
            formik.errors.Confirm &&
            formik.errors.Confirm
          }
          type={flags.showConfirmPassword ? "text" : "password"}
          name="Confirm"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.Confirm}
          size="small"
          className="fixed-input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword("showConfirmPassword")}
                  onMouseDown={handleMouseDownPassword}
                >
                  {flags.showConfirmPassword ? (
                    <Icon>visibility_off</Icon>
                  ) : (
                    <Icon>visibility</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: "5vh", textAlign: "end" }}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCleanForm}
          startIcon={<Icon>cancel</Icon>}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primaryDark"
          onClick={formik.submitForm}
          startIcon={<Icon>save</Icon>}
          sx={{ ml: 1 }}
        >
          Guardar
        </Button>
      </Grid>
    </>
  );
};

export default UserPassword;
