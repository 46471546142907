import { useEffect, useState } from "react";
import { ACTIONS_SPEED, COLUMNS_APERTURA } from "@data/constants/Sije";
import { useSorted } from "@hooks/useSorted";
import Swal from "sweetalert2";
import sije from "@services/SijeService";

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Icon,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { blue, grey } from "@mui/material/colors";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import CaptureForm from "@components/Sije/CaptureForm/CaptureListCasilla";
import BasicTable from "@components/Tables/BasicTable";
import AttendanceCard from "@components/Attendance/AttendanceCard";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import UserSearchForm from "@components/Sije/CaptureForm/UserSearchForm";
import Download from "@components/Downloads/Download";
import RollCallForm from "@components/Sije/CaptureForm/RollCallForm";
import ListCasillas from "@components/Sije/CaptureForm/ListCasillas";
import CaptureRollCallForm from "../../components/Sije/CaptureForm/CaptureRollCallForm";
import CardRepresentative from "../../components/Sije/CaptureForm/CardRepresentative";

const Switch = (props) => {
  const { test, children } = props;
  return children.find((child) => {
    return child.props.flag === test;
  });
};

const StartCapture = () => {
  //States
  const [page, setPage] = useState(0);
  const [totalList, setTotalList] = useState(0);
  const [valueTab, setValueTab] = useState("1");
  const [nameTab, setNameTab] = useState("PASE DE LISTA");
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [loadingRg, setLoadingRg] = useState(false);
  const [loadingCasilla, setLoadingCasilla] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [isDownload, setIsDownloading] = useState(false);
  const [loadingListCasillas, setLoadingListCasilla] = useState(false);
  const [options, setOptions] = useState([]);
  const [listCasillas, setListCasillas] = useState([]);
  const [user, setUser] = useState({});
  const [dataRg, setDataRg] = useState({
    contentData: "",
    leftFooterData: "",
    rightFooterData: "",
  });
  const [dataCasillas, setDataCasillas] = useState({
    contentData: "",
    leftFooterData: "",
    rightFooterData: "",
  });
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const [dataListApertura, setDataListApertura] = useState([]);
  const [total, setTotal] = useState(10);
  const [dataRC, setDataRC] = useState({
    avance: [],
    total: [],
  });
  const [filterData, setFilterData] = useState({
    Celular: "",
    Nombre: "",
    Paterno: "",
    Materno: "",
  });
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
  });

  const handleChangeTab = (event, newValue) => {
    setNameTab(event.target.innerText);
    setValueTab(newValue);
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const paseListaRg = async () => {
    try {
      setLoadingRg((prevState) => !prevState);
      const result = await sije.getChartPaseListaRG({});
      const { response, results } = await result;
      if (results) {
        setDataRg({
          contentData: response.Porcentaje,
          leftFooterData: response.Meta,
          rightFooterData: response.Avance,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingRg((prevState) => !prevState);
    }
  };

  const getCardAperturadasInstaladas = async () => {
    try {
      setLoadingCasilla((prevState) => !prevState);
      const result = await sije.getCardAperturadasInstaladas({});
      const { response, results } = await result;
      if (results) {
        setDataCasillas({
          contentData: response.data[0].Porcentaje,
          leftFooterData: response.data[0].totalInstaladas,
          rightFooterData: response.data[0].totalAperturadas,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCasilla((prevState) => !prevState);
    }
  };

  const getRepresentantesCasillas = async () => {
    try {
      const result = await sije.getChartPaseListaRcs({});
      const { response, results } = await result;
      if (results) {
        setDataRC({
          avance: response.series2,
          total: response.series1,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    }
  };

  const getListSijeApertura = async (data) => {
    try {
      setLoadingDetail((prevState) => !prevState);
      const result = await sije.getListSijeApertura(data);
      const { results, response } = await result;
      if (results) {
        const _data = response.data.map((item) => {
          return {
            ...item,
            Conectividad: (
              <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                <Icon
                  fontSize="small"
                  color={item.ConectividadInternet ? "primary" : "disabled"}
                >
                  wifi
                </Icon>
                <Icon
                  fontSize="small"
                  color={item.ConectividadTelefono ? "primary" : "disabled"}
                >
                  phone_iphone
                </Icon>
              </Stack>
            ),
            TipoTransmision: (
              <Box>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  icon={item.TipoTransmision.total}
                  direction="right"
                  sx={{
                    "& .MuiFab-primary": { width: 32, height: 32 },
                  }}
                >
                  {ACTIONS_SPEED.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      sx={{
                        width: 32,
                        height: 32,
                        color: item.TipoTransmision[action.type] && blue[700],
                      }}
                    />
                  ))}
                </SpeedDial>
              </Box>
            ),
          };
        });
        setDataListApertura(_data);
        setTotal(response.total);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingDetail((prevState) => !prevState);
    }
  };

  const getCasillasUser = async ({ idUser }) => {
    try {
      setLoadingListCasilla((prevState) => !prevState);
      const result = await sije.getCasillasUsuario({ idUsuario: idUser });
      const { response, message, results } = await result;
      if (results) {
        setListCasillas(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingListCasilla((prevState) => !prevState);
    }
  };

  const handleSwitchSave = (values) => {
    const { casilla, reportaRCRG, idUser, hora, NumFuncionarios, TomadosFila } =
      values;

    switch (valueTab) {
      case "1":
        handleSavePaseListas({
          idCasilla: casilla,
          idUser,
          FechaHora: hora,
        });
        break;
      case "2":
        handleSaveInstalacionCasilla({
          idCasilla: casilla,
          idUser: reportaRCRG === 9999 ? idUser : reportaRCRG,
          FechaHora: hora,
        });
        break;
      case "3":
        handleSaveAperturaCasilla({
          idCasilla: casilla,
          idUser: reportaRCRG === 9999 ? idUser : reportaRCRG,
          FechaHora: hora,
          NumFuncionarios,
          TomadosFila,
        });
        break;
      default:
        break;
    }
  };

  const handleSavePaseListas = (params) => {
    handleSaveForm(params, savePaseListas);
  };

  const handleSaveInstalacionCasilla = (params) => {
    handleSaveForm(params, saveInstalacionCasilla);
  };

  const handleSaveAperturaCasilla = (values) => {
    handleSaveForm(values, saveAperturaCasilla);
  };

  const savePaseListas = async (params) => sije.setPaseLista(params);
  const saveInstalacionCasilla = async (params) =>
    sije.setInstalacionCasillas(params);
  const saveAperturaCasilla = async (values) => sije.setAperturaCasilla(values);

  const handleSaveForm = async (params, saveFunction) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      setIsSuccessFormSave(false);
      const result = await saveFunction(params);
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });

        setUser({});
        setOptions([]);
        setFilterData({
          Nombre: "",
          Paterno: "",
          Materno: "",
          Celular: "",
        });
        setPage(0);
        setTotalList(0);

        paseListaRg();
        getCardAperturadasInstaladas();
        getRepresentantesCasillas();
        getListSijeApertura(data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessFormSave(true);
    }
  };

  const handleMassRollCall = async ({ idsCasillas, FechaHora }) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      setIsSuccessFormSave(false);
      const result = await sije.setPaseListaMasivo({
        idUsuario: user.idUsuario,
        FechaHora: FechaHora,
        casillas: idsCasillas.map((value) => ({
          idCasilla: value,
        })),
      });
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });

        setUser({});
        setOptions([]);
        setFilterData({
          Nombre: "",
          Paterno: "",
          Materno: "",
          Celular: "",
        });
        setPage(0);
        setTotalList(0);

        paseListaRg();
        getCardAperturadasInstaladas();
        getRepresentantesCasillas();
        getListSijeApertura(data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessFormSave(true);
    }
  };

  const handleExportListSijeApertura = async () => {
    try {
      setIsDownloading((prevState) => !prevState);
      const result = await sije.exportSije({
        params: {},
        api: "sije/export-list-sije-apertura",
      });
      const { success } = await result;

      if (success) {
        Swal.fire({
          title: "Descargado con éxito.",
          icon: "success",
        });
      } else {
        throw new Error("Ocurrió un error, contacte al administrador.");
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsDownloading((prevState) => !prevState);
    }
  };

  const handleClickItem = (id) => {
    const user = options.find((item) => item.value === id);
    getCasillasUser({ idUser: user.value });
    setUser(user);
  };

  useEffect(() => {
    paseListaRg();
    getCardAperturadasInstaladas();
    getRepresentantesCasillas();
  }, []);

  useEffect(() => {
    getListSijeApertura(data);
    //eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={nameTab} />
        <LoadingForm
          loadinMessage={"Guardando datos..."}
          isLoading={isLoadigForm}
          success={isSuccessFormSave}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        />
        <TabContext value={valueTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab label="PASE DE LISTA" value="1" />
              <Tab label="INSTALACIÓN DE CASILLAS" value="2" />
              <Tab label="APERTURA CASILLA" value="3" />
              <Tab label="DETALLE" value="4" />
            </TabList>
          </Box>
          <Grid2 container spacing={2} marginTop={2}>
            <Grid2 xs={12} md={4}>
              <CardRepresentative dataRC={dataRC} />
            </Grid2>
            <Grid2 xs={12} md={4}>
              <AttendanceCard
                titlecontent={"RGS"}
                data={{
                  contentData: String(dataRg.contentData),
                  leftFooterData: String(dataRg.leftFooterData),
                  rightFooterData: String(dataRg.rightFooterData),
                }}
                config={{
                  leftFooterTitle: "Total",
                  rightFooterTitle: "Pase Lista",
                }}
                loading={loadingRg}
              />
            </Grid2>
            <Grid2 xs={12} md={4}>
              <AttendanceCard
                titlecontent={"CASILLAS"}
                data={{
                  contentData: String(dataCasillas.contentData),
                  leftFooterData: String(dataCasillas.leftFooterData),
                  rightFooterData: String(dataCasillas.rightFooterData),
                }}
                config={{
                  leftFooterTitle: "Instaladas",
                  rightFooterTitle: "Aperturadas",
                }}
                loading={loadingCasilla}
              />
            </Grid2>
          </Grid2>
          <TabPanel value="1">
            <Grid2 container spacing={2}>
              <Grid2 xs={12} md={6} lg={6}>
                <UserSearchForm
                  page={page}
                  total={totalList}
                  setPage={setPage}
                  setTotal={setTotalList}
                  filterData={filterData}
                  options={options}
                  setFilterData={setFilterData}
                  setOptions={setOptions}
                  setUser={setUser}
                  handleClickItem={handleClickItem}
                />
              </Grid2>
              <Grid2 xs={12} md={6} lg={6}>
                {Object.entries(user).length === 0 && (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"600px"}
                  >
                    <Stack
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      alignContent={"center"}
                    >
                      <Box
                        sx={{ backgroundColor: grey[200] }}
                        width={"80px"}
                        height={"80px"}
                        padding={2}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderRadius={"50%"}
                      >
                        <Icon fontSize={"large"} sx={{ color: grey[700] }}>
                          person_search
                        </Icon>
                      </Box>
                      <Typography
                        variant="body2"
                        marginTop={1}
                        fontWeight={600}
                      >
                        Busca y selecciona al usuario para completar el pase de
                        lista.
                      </Typography>
                    </Stack>
                  </Box>
                )}

                {loadingListCasillas ? (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"600px"}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Switch test={true}>
                    <CaptureRollCallForm
                      idUser={user.idUsuario}
                      title={`${nameTab} - OTRO`}
                      handleSave={handleSwitchSave}
                      flag={
                        listCasillas.length === 0 &&
                        Object.entries(user).length > 0
                      }
                      //loading={isLoadigForm}
                    />
                    <RollCallForm
                      title={`${nameTab} - RC`}
                      itemUser={user}
                      setItemUser={setUser}
                      options={options}
                      handleSave={handleSwitchSave}
                      loading={isLoadigForm}
                      flag={
                        listCasillas.length === 1 &&
                        Object.entries(user).length > 0
                      }
                    />
                    <ListCasillas
                      title={`${nameTab} - RGS`}
                      data={listCasillas}
                      handleSave={handleMassRollCall}
                      flag={
                        listCasillas.length > 1 &&
                        Object.entries(user).length > 0
                      }
                    />
                  </Switch>
                )}
              </Grid2>
            </Grid2>
          </TabPanel>
          <TabPanel value="2">
            <Box display={"flex"} justifyContent={"center"}>
              <CaptureForm
                title={nameTab}
                labelHora={
                  "* Hora de Instalación de Casillas (Formato: 24 hrs, ejemplo: 13:30)"
                }
                handleSave={handleSwitchSave}
                loading={isLoadigForm}
                sx={{ width: { md: "40%" } }}
              />
            </Box>
          </TabPanel>
          <TabPanel value="3">
            <Box display={"flex"} justifyContent={"center"}>
              <CaptureForm
                title={nameTab}
                labelHora={
                  "* Hora de Instalación de Casillas (Formato: 24 hrs, ejemplo: 13:30)"
                }
                //isReporto={false}
                //isRc={true}
                isNumeroFuncionario={true}
                isFila={true}
                handleSave={handleSwitchSave}
                loading={isLoadigForm}
                sx={{ width: { md: "40%" } }}
              />
            </Box>
          </TabPanel>
          <TabPanel value="4">
            <Card className="card-primary">
              <CardContent>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  marginBottom={2}
                >
                  <Typography variant="body2" fontWeight={700}>
                    DETALLE
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    startIcon={<Icon>download</Icon>}
                    size="small"
                    disabled={isDownload}
                    onClick={handleExportListSijeApertura}
                  >
                    Exportar
                  </Button>
                </Stack>
                {isDownload && (
                  <Box marginBottom={2}>
                    <Download format={"xlsx"} isDownload={isDownload} />
                  </Box>
                )}
                <BasicTable
                  rows={dataListApertura}
                  hcolumns={COLUMNS_APERTURA}
                  total={total}
                  pageProp={data.page}
                  pageSize={data.pageSize}
                  isLoading={loadingDetail}
                  minHeight="0px"
                  stickyHeader={true}
                  handleChangePagination={handleChangePagination}
                  handleManualSort={handleRequestSort}
                />
              </CardContent>
            </Card>
          </TabPanel>
        </TabContext>
      </Container>
    </AdminLayout>
  );
};

export default StartCapture;
