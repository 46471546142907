import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Icon,
  Modal,
  Box,
  Tab,
  Stack
} from "@mui/material";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import Swal from "sweetalert2";

import middleware from "@middlewares/middleware";
import useWindowDimensions from "@hooks/useWindowDimensions";
import AdminLayout from "@components/MainPage/AdminLayout";
import BasicList from "@components/Lists/BasicList";
import UserRoleService from "@services/RoleServices";
import UserRoleMenuList from "@components/Menus/UserRoleMenuList";
import MenusList from "@components/Menus/MenusList";
import AddNewMenuList from "@components/Menus/AddNewMenuList";
import {
  UserRoleAddInterface,
  UserRoleInfo,
} from "@interfaces/RoleInterfaces";
import MenuToolbar from "@components/Menus/MenuToolbar";
import UsersList from "@components/Roles/UsersList";
import PerfileRoles from "../../components/Roles/PerfileRoles";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import ActionContainer from "@components/Containers/ActionContainer";
import RoleListItem from "@components/Roles/RoleListItem";
import UserRoleForm from "@components/Roles/RoleForm";
import RoleOrderList from "@components/Roles/RoleOrderList";
import ButtonAdd from "@components/Button/Button";

const inputOptions = new Promise((resolve) => {
  setTimeout(() => {
    resolve({
      0: 'Aplicar a partir de ahora',
      1: 'Aplicar a todos'
    })
  }, 1000)
});

const Roles = () => {
  const [actionForm, setActionForm] = useState("menu");
  const [formData, setFormData] = useState(UserRoleAddInterface);
  const [roleList, setRoleList] = useState([]);
  const [total, setTotal] = useState(0);
  const [perfilInfo, setPerfilInfo] = useState(UserRoleInfo);
  const [webMenus, setWebMenus] = useState([]);
  const [appMenus, setAppMenus] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const { height } = useWindowDimensions();
  const [isloadigForm, setIsLoadingForm] = useState(false);
  const [issuccessForm, setIsSuccessForm] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingAddMenu, setIsLoadingAddMenu] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [filtered, setFiltered] = useState([]);

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 10;
  const config = {
    title: "Listado de Roles",
    icon: "people",
    height: 400,
    endMessage: "No hay más registros para mostrar",
  };

  const handleClickItem = useCallback(
    (id) => {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      UserRoleService.getRole(id)
        .then((res) => {
          if (res.results) {
            setPerfilInfo(res.response);
            setWebMenus(res.response.webMenus);
            setAppMenus(res.response.appMenus);
            setPerfiles(res.response.perfiles);
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: "warning" });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
        });
    },
    []
  );

  const getRoles = useCallback((data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    UserRoleService.getRoles({ page: data.page, pageSize: data.pageSize, filtered: data.filtered })
      .then((res) => {
        if (res.results) {
          if (res.response.total > 0) {
            setRoleList(res.response.data);
            setTotal(res.response.total);
            handleClickItem(res.response.data[0].id);
          }

          if (pageSize >= parseInt(res.response.total)) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      }).finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getRoles({ page, pageSize, filtered });
    // eslint-disable-next-line
  }, []);

  const fetchHasMore = () => {
    UserRoleService.getRoles({ page: page + 1, pageSize: pageSize })
      .then((res) => {
        if (res.results) {
          setRoleList(roleList.concat(res.response.data));
          let end = (page + 1) * pageSize;
          if (end >= res.response.total) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      });
    setPage(page + 1);
  };

  const handleReloadList = (id) => {
    setPage(0);
    UserRoleService.getRoles({ page: 0, pageSize: pageSize })
      .then((res) => {
        if (res.results) {
          if (res.response.total > 0) {
            setRoleList(res.response.data);
            setTotal(res.response.total);
            if (id && res.response.data.find(item => item.id === id)) {
              handleClickItem(id);
            } else {
              handleClickItem(res.response.data[0].id);
            }
          }

          if (pageSize >= res.response.total) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      });
  }

  const handleChangeTab = (e, newVal) => {
    setTabValue(newVal);
  };

  const handleAddMenu = () => {
    setIsModalOpen(true);
    setActionForm('menu')
    setModalTitle("Agregar menús")
  };

  const handleEditRole = () => {
    setIsModalOpen(true);
    setActionForm('role');
    setModalTitle("Editar perfil");
    setFormData(() => {
      let data = {};
      Object.keys(formData).forEach(key => {
        data[key] = perfilInfo[key] !== undefined ? perfilInfo[key] : '';
      })
      return data;
    })

  };

  const submitMenus = (menus) => {
    setIsLoadingAddMenu(true);
    const params = {
      menus,
      idPerfil: perfilInfo.id,
      Accion: 0,
    };

    UserRoleService.getImpact4Delete(perfilInfo.id).then(res => {
      if (res.success) {
        if (res.response.total > 0) {
          Swal.fire({
            title: "Advertencia",
            html: `Actualmente existen ${res.response.total} usuario(s) que tienen asignado este perfil. Consulte la pestaña de USUARIOS y aségurese de aplicar la acción correcta. ¿Qué acción desea realizar?`,
            icon: "warning",
            input: 'radio',
            inputOptions: inputOptions,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aplicar',
            confirmButtonColor: '#1976d2',
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            inputValidator: (action) => {
              if (!action) {
                return 'Debe seleccionar una opción!'
              } else {
                if (action) {
                  insertMenus({ ...params, Accion: parseInt(action) });
                }
              }
            },
            customClass: { container: "modal-alert" },
          });
        } else {
          insertMenus(params);
        }
      }
    }).catch(error => {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      Swal.fire({ title: error.message, icon: "warning" });
    })
  }

  const insertMenus = params => {
    UserRoleService.insertMenus(params)
      .then((res) => {
        if (res.success) {
          setIsModalOpen(false);
          Swal.fire({
            title: res.message,
            icon: "success",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: { container: "modal-alert" }
          }).then(res => {
            if (res.isConfirmed) {
              handleClickItem(perfilInfo.id)
            }
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning", customClass: { container: "modal-alert" } });
        }
      }).catch((error) => {
        Swal.fire({ title: error.message, icon: "warning", customClass: { container: "modal-alert" } });
      }).finally(() => {
        setIsLoadingAddMenu(false);
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  }

  const handleDeleteItem = (item) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    UserRoleService.getImpact4Delete(item.id).then(res => {
      if (res.response.total > 0) {
        Swal.fire({
          title: "Advertencia",
          html: `Actualmente existen ${res.response.total} usuario(s) que tienen asignado este perfil. Consulte la pestaña de USUARIOS y aségurese que no exista ningún usuario con el perfil seleccionado para eliminar.`,
          icon: "warning",
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        });
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      } else {
        UserRoleService.delete(item.id).then(res => {
          if (res.success) {
            Swal.fire({ title: res.message, icon: "success", allowOutsideClick: false, allowEscapeKey: false }).then(res => {
              if (res.isConfirmed) {
                handleReloadList()
              }
            });
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
        }).catch(e => {
          Swal.fire({ title: e.message, icon: "warning" });
        }).finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
        });
      }
    }).catch(error => {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      Swal.fire({ title: error.message, icon: "warning" });
    });
  }

  const handleSearch = (searchInput) => {
    let filtro = [];
    if (searchInput === "") {
      filtro = [];
    } else {
      filtro = [{
        id: "perfiles.Perfil",
        filter: "LIKE",
        value: searchInput,
        inheritFilterType: null
      }];
    }
    setFiltered(filtro);
    setPage(0);
    getRoles({ page: 0, pageSize, filtered: filtro });
  }

  return (
    <AdminLayout>
      <Card>
        <CardHeader
          title={"Administración de perfiles de usuario"}
          action={
            <ActionContainer>
              <Stack direction="row" spacing={1}>
                {middleware.checkMenuAction("Agregar") && (
                  <ButtonAdd
                    text="Agregar"
                    icon="add"
                    onClick={(e) => {
                      setActionForm("role");
                      setIsModalOpen(true);
                      setFormData(UserRoleAddInterface)
                      setModalTitle("Registrar Perfil");
                    }}
                  />
                )}
                {/* {middleware.checkMenuAction("Editar") && (
                  <ButtonAdd
                    text="Orden"
                    icon="reorder"
                    variant="outlined"
                    onClick={(e) => {
                      setActionForm("order");
                      setIsModalOpen(true);
                      setModalTitle("Ordenar Perfiles");
                    }}
                  />
                )} */}
              </Stack>
            </ActionContainer>
          }
        ></CardHeader>
        <CardContent className="p-0">
          <LoadingForm
            isLoading={isloadigForm}
            success={issuccessForm}
            isOpen={openLinearProgress}
            setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
            loadinMessage={"Cargando..."}
          />
          <Grid container>
            <Grid xs={12} md={4} sm={12} lg={4} item>
              {<BasicList
                configParams={config}
                data={roleList}
                fetchHasMore={fetchHasMore}
                totalItems={total}
                itemComponent={RoleListItem}
                hasMore={hasMore}
                handleClickItem={handleClickItem}
                finder={true}
                handleDeleteItem={handleDeleteItem}
                search={handleSearch}
              />}
            </Grid>
            <Grid xs={12} md={8} sm={12} lg={8} item>
              <TabContext value={tabValue}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <TabList
                    onChange={handleChangeTab}
                    aria-label="lab API tabs example"
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="fullWidth"
                    scrollButtons="auto"
                  >
                    <Tab
                      icon={<Icon>admin_panel_settings</Icon>}
                      label="Menús Web"
                      value="1"
                    />
                    <Tab
                      icon={<Icon>app_settings_alt</Icon>}
                      label="Menús App"
                      value="2"
                    />
                    <Tab
                      icon={<Icon>people</Icon>}
                      label="Usuarios"
                      value="3"
                    />
                     <Tab
                      icon={<Icon>people</Icon>}
                      label="Perfiles"
                      value="4"
                    />
                  </TabList>
                  <TabPanel value="1" sx={{ pt: 0 }}>
                    {
                      middleware.checkMenuAction("Editar") ? (
                        <UserRoleMenuList
                          menus={webMenus}
                          type={"list-edit"}
                          maxHeight={height * 0.5}
                          toolbar={true}
                          ComponentToolbar={MenuToolbar}
                          configToolbar={{
                            title: `${perfilInfo.Perfil}`,
                            icon: "add_circle",
                            btnAdd: true,
                            handleAdd: handleAddMenu,
                            handleEditRole: handleEditRole
                          }}
                          setIsOpen={setOpenLinearProgress}
                          setIsLoading={setIsLoadingForm}
                          setIsSuccess={setIsSuccessForm}
                          externalID={perfilInfo.id}
                          handleReloadList={handleClickItem}
                        />
                      ) : (
                        <MenusList
                          menus={webMenus}
                          type={"list"}
                          maxHeight={height * 0.5}
                        />
                      )
                    }

                  </TabPanel>
                  <TabPanel value="2" sx={{ pt: 0 }}>
                    {
                      middleware.checkMenuAction("Editar") ? (
                        <UserRoleMenuList
                          menus={appMenus}
                          type={"list-edit"}
                          maxHeight={height * 0.5}
                          toolbar={true}
                          ComponentToolbar={MenuToolbar}
                          configToolbar={{
                            title: `${perfilInfo.Perfil}`,
                            icon: "add_circle",
                            btnAdd: true,
                            handleAdd: handleAddMenu,
                            handleEditRole: handleEditRole
                          }}
                          setIsOpen={setOpenLinearProgress}
                          setIsLoading={setIsLoadingForm}
                          setIsSuccess={setIsSuccessForm}
                          externalID={perfilInfo.id}
                          handleReloadList={handleClickItem}
                        />
                      ) : (
                        <MenusList
                          menus={appMenus}
                          type={"list"}
                          maxHeight={height * 0.5}
                        />
                      )
                    }

                  </TabPanel>
                  <TabPanel value="3" sx={{ pt: 0 }}>
                    <UsersList data={perfilInfo} />
                  </TabPanel>
                  <TabPanel value="4" sx={{ pt: 0 }}>
                    {
                      middleware.checkMenuAction("Editar") ? (
                        <PerfileRoles
                          data = {perfiles}
                        />
                      ) : (
                        <>No Cuenta con permisos de modificación</>
                      )
                    }

                  </TabPanel>
                </Box>
              </TabContext>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "60%",
            maxHeight: "100%",
            overflowY: "auto",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            p: 4,
          }}
        >
          <CardHeader title={modalTitle} />
          <CardContent>
            {
              actionForm === 'role' && (
                <UserRoleForm handleCloseModal={() => setIsModalOpen(false)} handleReloadList={handleReloadList} data={formData} />
              )
            }
            {
              actionForm === 'menu' && (
                <AddNewMenuList
                  currentMenus={webMenus}
                  submitMenus={submitMenus}
                  isLoadingAdd={isLoadingAddMenu}
                  handleCloseModal={() => setIsModalOpen(false)}
                />
              )
            }
            {
              actionForm === 'order' && (
                <RoleOrderList />
              )
            }
          </CardContent>
        </Card>
      </Modal>
    </AdminLayout>
  );
};

export default Roles;
