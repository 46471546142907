import Swal from "sweetalert2";
import { isEmptyOrNullObject } from "@utils/validations";
import {
  URL_PUBLICA,
  PUBLIC_API_KEY,
  setVars,
  getVars,
  encryptVars,
  decryptVars,
  DEBUG,
} from "@utils/global";

export const ApiExec = (data, api, method = "POST") => {
  let headers = new Headers();
  if (!isEmptyOrNullObject(getVars("Token"))) {
    const userData = getVars("Token");
    headers.append("Authorization", `Bearer ${userData.access_token}`);
  }

  if (DEBUG) {
    headers.append("Content-Type", "application/json");
  } else {
    headers.append("Content-Type", "text/plain;charset=UTF-8");
  }

  return new Promise((resolve, reject) => {
    let requestInfo = {
      method: method,
      body: DEBUG ? JSON.stringify(data) : encryptVars(JSON.stringify(data)),
      headers: headers,
    };
    let url = "";

    switch (method) {
      case "GET":
        url = new URL(URL_PUBLICA + api);
        delete requestInfo.body;
        if (!isEmptyOrNullObject(data)) {
          Object.keys(data).forEach((key) => {
            const value = data[key];
            if (value !== "") {
              url.searchParams.append(key, data[key]);
            }
          });
        }
        break;

      case "POST":
      case "PUT":
      case "DELETE":
      case "PATCH":
      default:
        url = URL_PUBLICA + api;
        break;
    }

    let errorMessage = "";
    // console.log("=>requestInfo: ", requestInfo);
    // console.log("=>url: ", url);
    fetch(url, requestInfo)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        switch (res.status) {
          case 400:
            // errorMessage = "Error en la validación. Verifique los campos e intente nuevamente.";
            // break;
            return res.json();

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title:
                "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 428:
            errorMessage =
              "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un error, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((dataRS) => {
        if (!dataRS.success) {
          reject({ ...dataRS, results: false });
        } else {
          resolve({
            ...dataRS,
            response: DEBUG ? dataRS.response : decryptVars(dataRS.response),
          });
        }
      })
      .catch((errors) => {
        reject({
          results: false,
          data: {},
          message: errors.message,
        });
      });
  });
};

export const ApiExecPublic = (data, api, method = "POST") => {
  let headers = new Headers({
    Authorization: PUBLIC_API_KEY,
  });

  if (DEBUG) {
    headers.append("Content-Type", "application/json");
  } else {
    headers.append("Content-Type", "text/plain;charset=UTF-8");
  }

  return new Promise((resolve, reject) => {
    let requestInfo = {
      method: method,
      body: DEBUG ? JSON.stringify(data) : encryptVars(JSON.stringify(data)),
      headers: headers,
    };
    let url = "";

    switch (method) {
      case "GET":
        url = new URL(URL_PUBLICA + api);
        delete requestInfo.body;
        if (!isEmptyOrNullObject(data)) {
          Object.keys(data).forEach((key) => {
            const value = data[key];
            if (value !== "") {
              url.searchParams.append(key, data[key]);
            }
          });
        }
        break;

      case "POST":
      case "PUT":
      case "DELETE":
      case "PATCH":
      default:
        url = URL_PUBLICA + api;
        break;
    }

    let errorMessage = "";
    fetch(url, requestInfo)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        switch (res.status) {
          case 400:
            errorMessage =
              "Error en la validación. Verifique los campos e intente nuevamente.";
            break;

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title:
                "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 428:
            errorMessage =
              "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un error, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((dataRS) => {
        if (!dataRS.success) {
          reject({ ...dataRS, results: false });
        } else {
          resolve({
            ...dataRS,
            response: DEBUG ? dataRS.response : decryptVars(dataRS.response),
          });
        }
      })
      .catch((errors) => {
        reject({
          results: false,
          data: {},
          message: errors.message,
        });
      });
  });
};

export const ApiExecDecrypted = (data, api, method = "POST") => {
  let headers = new Headers();
  if (!isEmptyOrNullObject(getVars("Token"))) {
    const userData = getVars("Token");
    headers.append("Authorization", `Bearer ${userData.access_token}`);
  }

  if (DEBUG) {
    headers.append("Content-Type", "application/json");
  } else {
    headers.append("Content-Type", "text/plain;charset=UTF-8");
  }

  return new Promise((resolve, reject) => {
    let requestInfo = {
      method: method,
      body: DEBUG ? JSON.stringify(data) : encryptVars(JSON.stringify(data)),
      headers: headers,
    };
    let url = "";

    switch (method) {
      case "GET":
        url = new URL(URL_PUBLICA + api);
        delete requestInfo.body;
        if (!isEmptyOrNullObject(data)) {
          Object.keys(data).forEach((key) => {
            const value = data[key];
            if (value !== "") {
              url.searchParams.append(key, data[key]);
            }
          });
        }
        break;

      case "POST":
      case "PUT":
      case "DELETE":
      case "PATCH":
      default:
        url = URL_PUBLICA + api;
        break;
    }

    let errorMessage = "";
    fetch(url, requestInfo)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        switch (res.status) {
          case 400:
            errorMessage =
              "Error en la validación. Verifique los campos e intente nuevamente.";
            break;

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title:
                "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 428:
            errorMessage =
              "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un error, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((dataRS) => {
        if (!dataRS.success) {
          reject({ ...dataRS, results: false });
        } else {
          resolve({
            ...dataRS,
            response: dataRS.response,
          });
        }
      })
      .catch((errors) => {
        reject({
          results: false,
          data: {},
          message: errors.message,
        });
      });
  });
};

export const downloadFile = ({ data, api, method = "GET" }) => {
  let fileName = "";
  let headers = new Headers();

  if (!isEmptyOrNullObject(getVars("Token"))) {
    const userData = getVars("Token");
    headers.append("Authorization", `Bearer ${userData.access_token}`);
  }

  if (DEBUG) {
    headers.append("Content-Type", "application/json");
  } else {
    headers.append("Content-Type", "text/plain;charset=UTF-8");
  }

  return new Promise((resolve, reject) => {
    let link = URL_PUBLICA + api;

    let requestInfo = {
      method: method,
      body: DEBUG ? JSON.stringify(data) : encryptVars(JSON.stringify(data)),
      headers: headers,
    };
    let url = "";

    if (method === "GET") {
      url = new URL(URL_PUBLICA + api);
      delete requestInfo.body;
      if (!isEmptyOrNullObject(data)) {
        Object.keys(data).forEach((key) => {
          const value = data[key];
          if (value !== "") {
            url.searchParams.append(key, data[key]);
          }
        });
      }
    }

    fetch(link, requestInfo)
      .then((response) => {
        if (response.ok) {
          const header = response.headers.get("Content-Disposition");
          if (!header) {
            throw new Error(
              "¡No cuenta con permisos suficientes, contacte al administrador!"
            );
          }
          const parts = header.split(";");
          // fileName = parts[1].split('=')[1];
          fileName = parts[1].split("=")[1].replaceAll('"', "");
          return response.blob();
        }
        throw new Error("Error=>[url]: ", response);
      })
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName.trim());

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        resolve({ success: true });
      })
      .catch((e) => {
        reject({ success: false, error: e });
      });
  });
};

export const uploadFiles = (
  data,
  api = "global/uploadFiles",
  method = "POST"
) => {
  if (isEmptyOrNullObject(getVars("Token"))) {
    return Swal.fire({
      icon: "error",
      title: "Es requerido enviar la sesión del usuario",
    });
  }

  const userData = getVars("Token");
  const headers = new Headers({
    Authorization: `Bearer ${userData.access_token}`,
    "Content-Type": "application/json",
  });

  return new Promise((resolve, reject) => {
    let errorMessage = "";

    fetch(URL_PUBLICA + api, {
      method: method,
      body: JSON.stringify(mapEncryptedData(data)),
      headers: headers,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        }

        switch (res.status) {
          case 400:
            // errorMessage = "Error en la validación. Verifique los campos e intente nuevamente.";
            // break;
            return res.json();

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title:
                "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 428:
            errorMessage =
              "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un error, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((dataRS) => {
        if (!dataRS.success) {
          reject({ ...dataRS, results: false });
        } else {
          resolve({
            ...dataRS,
            response: DEBUG ? dataRS.response : decryptVars(dataRS.response),
          });
        }
      })
      .catch((errors) => {
        reject({
          results: false,
          data: {},
          message: errors.message,
        });
      });
  });
};

export const uploadFilesFormData = (
  data,
  api = "global/uploadFiles",
  method = "POST"
) => {
  if (isEmptyOrNullObject(getVars("Token"))) {
    return Swal.fire({
      icon: "error",
      title: "Es requerido enviar la sesión del usuario",
    });
  }

  const userData = getVars("Token");
  const headers = new Headers({
    Authorization: `Bearer ${userData.access_token}`,
  });

  return new Promise((resolve, reject) => {
    const formData = new FormData();
    createFormData(formData, "data", data);

    let errorMessage = "";

    fetch(URL_PUBLICA + api, {
      method: method,
      body: formData,
      headers: headers,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        }

        switch (res.status) {
          case 400:
            // errorMessage = "Error en la validación. Verifique los campos e intente nuevamente.";
            // break;
            return res.json();

          case 401:
            return Swal.fire({
              title: "Su sesión ha expirado!",
              text: "Inicie sesión nuevamente",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 403:
            return Swal.fire({
              title:
                "Se ha iniciado sesión con su cuenta desde otra computadora.",
              text: "Si usted no fue, favor de renovar sesión y cambiar la contraseña",
              icon: "warning",
              iconColor: "#dc3741",
              // iconHtml: '<img src="https://picsum.photos/100/100">',
              showDenyButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: true,
              denyButtonText: "Renovar sesión",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: "modal-alert",
              },
            }).then((res) => {
              if (res.isDenied) {
                setVars("Token", { access_token: null });
                window.location.href = "/";
              }
            });

          case 404:
            errorMessage = "Api no encontrada.";
            break;

          case 428:
            errorMessage =
              "El captcha no pasó la validación. Contacte al administrador.";
            break;

          case 429:
            errorMessage =
              "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
            break;

          case 500:
            errorMessage = "Ocurrió un error, contacte al administrador.";
            break;

          default:
            errorMessage = res.statusText;
            break;
        }
        throw new Error(errorMessage);
      })
      .then((dataRS) => {
        if (!dataRS.success) {
          reject({ ...dataRS, results: false });
        } else {
          resolve({
            ...dataRS,
            response: DEBUG ? dataRS.response : decryptVars(dataRS.response),
          });
        }
      })
      .catch((errors) => {
        reject({
          results: false,
          data: {},
          message: errors.message,
        });
      });
  });
};

export const uploadAndDownloadFile = ({
  api = "global/uploadFiles",
  data,
  token,
  fileName,
  method = "POST",
}) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    createFormData(formData, "data", data);
    fetch(URL_PUBLICA + api, {
      method: method,
      body: formData,
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) return response.blob();
        throw new Error("Error=>[url]: ", response.statusText);
      })
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        resolve({ success: true });
      })
      .catch((e) => {
        reject({ success: false, error: e });
      });
  });
};

function createFormData(formData, key, data) {
  if (
    (data === Object(data) && !(data instanceof File)) ||
    Array.isArray(data)
  ) {
    for (var i in data) {
      createFormData(formData, key + "[" + i + "]", data[i]);
    }
  } else {
    formData.append(key, data);
  }
}

function mapEncryptedData(obj) {
  for (var k in obj) {
    if (typeof obj[k] == "object" && obj[k] !== null) mapEncryptedData(obj[k]);
    else
      obj[k] = k !== "file" ? (DEBUG ? obj[k] : encryptVars(obj[k])) : obj[k];
  }

  return obj;
}

export default ApiExec;
