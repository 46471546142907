import AdministrationRoutes from "./AdministrationRoutes";
import AuthRoutes from "./AuthRoutes";
import CompromisosRoutes from "./CompromisosRoutes";
import VolunteersRoutes from "./VolunteersRoutes";
import SocialRoutes from "./SocialRoutes";
import MapRoutes from "./MapRoutes";
import SimulacrumRoutes from "./SimulacrumRoutes";
import TerritorialRoutes from "./TerritorialRoutes";
import SijeRoutes from "./SijeRoutes";
import EventsRoutes from "./EventsRoutes";
import PrepRoutes from "./PrepRoutes";
import ElectoralRoutes from "./ElectoralRoutes";
import EncuestasRoutes from "./EncuestasRoutes";
import RcosRoutes from "./RcosRoutes";
import FrenteAmplioRoutes from "./FrenteAmplioRoutes";
import InformeGobRoutes from "./InformeGobRoutes";

const Routes = [
  ...AuthRoutes,
  ...AdministrationRoutes,
  ...CompromisosRoutes,
  ...VolunteersRoutes,
  ...SocialRoutes,
  ...MapRoutes,
  ...SimulacrumRoutes,
  ...TerritorialRoutes,
  ...ElectoralRoutes,
  ...SijeRoutes,
  ...EventsRoutes,
  ...PrepRoutes,
  ...EncuestasRoutes,
  ...RcosRoutes,
  ...FrenteAmplioRoutes,
  ...InformeGobRoutes,
];

export { Routes };
