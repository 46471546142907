import { useEffect, useState } from "react";
import BasicTable from "@components/Tables/BasicTable";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import CardCommitments from "@components/Card/CardStats";
import { numberWithCommas } from '@utils/Utilities';
import moment from "moment";
import PlacementColumns from "@components/Charts/DashChart";
import middleware from "@middlewares/middleware";
import Card from "@components/Card/Card";
import Filter from "../../components/Electoral/Filter";
import MessageAlert from "@components/Downloads/MessageAlert";
import Download from "@components/Downloads/Download";
import { useCatalogs } from "@hooks/useCatalogs";
import { useNavigate } from "react-router-dom";
import {
    Container,
    CardContent,
    Grid,
    Box,
    Button,
    Icon,
    CircularProgress
  } from "@mui/material";
import ProfileRepresentante from "../../components/Electoral/ProfileRepresentante";
import AssignRepresentative from "../../components/Electoral/AssignRepresentative";

const AvanceCasillas = (props) => {
    const navigate = useNavigate();
    let today = new Date();
      // catalogo
    const catalogsParams = [
        { id: "calidad_representante"}
    ];
    const [data, setData] = useState([
      {
        Region:"R1",
        Municipio:"Abasolo",
        Seccion:"1",
        Casilla:"POR DEFINIR",
        Sup1:1,
        Sup1:1
      }
    ]);
    const [tableData, setTableData] = useState({
      page: 0,
      pageSize: 10,
      filtered: [],
    });
    const [dataTotales,setDataTotales] = useState({
      responsable: {
        Meta: 0,
        TotalAvance: 0,
        FechaActualizo:"2023-01-11 16:00:19"
      },
      voluntarios: {
        asignados: 0,
        voluntarios: 0,
        FechaActualizo:"2023-01-11 16:00:19"
      }
    });
    const [total, setTotal] = useState(0);
    const [successDownload, setSuccessDownload] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoadingChart, setIsLoadingChart] = useState(false);
    const [dataChart, setDataChart] = useState({title:"Estructura de Casilla",subtitle:today,dato:[],categories:[]});
    const { catalogs, load } = useCatalogs({
        catalogsOptions: catalogsParams,
        putDefaultOption: false,
    });
    const [open,setOpen] = useState(false);
    const [dataRepresentante,setDataRepresentante] = useState({});

    const [columns,setColumns] = useState([]);

    useEffect(()=>{
        if(!load){
            let dataColums = catalogs.calidad_representante.map(item => {return {id:item.label,label:item.label}});
            let table = [
                { id: "Region", label: "Región" },
                { id: "Municipio", label: "Municipio" },
                { id: "Seccion", label: "Sección" },
                { id: "Casilla", label: "Casilla" }
            ].concat(dataColums)
            setColumns(table);

            let categories = catalogs.calidad_representante.map(item => {return item.label });

            let datosChart = [
                {
                    name: 'Meta',
                    color: 'rgba(98, 168, 239,0.4)',
                    data: [150, 100, 200, 80],
                    pointPadding: 0.3,
                    pointPlacement: -0.2
                },
                {
                    name: "Representantes",
                    color: 'rgba(3, 15, 152,.9)',
                    data: [140,50,200,60],
                    pointPadding: 0.4,
                    pointPlacement: -0.2
                }
            ]
            setDataChart({...dataChart,dato:datosChart,categories:categories})
        }
       
    },[load]);

    const handleChangeFilter = (type) => {
        const {
          DF,
          DL,
          Region,
          Municipio,
          Poligono,
          Seccion,
          Casillas,
          Propietario,
          Celular,
          ClaveElector,
          NombreCompleto,
          TieneAuto,
          TieneExperiencia,
          AceptaOtra,
        } = type;
    
        let filtered = [];
        let filtro = [];
    
        if (DF > 0) {
          filtered = [
            ...filtered,
            { id: "cat_secciones.idDF", filter: "=", value: DF },
          ];
    
          filtro = [
            ...filtro,
            { id: "cat_secciones.idDF", filter: "=", value: DF },
          ];
        }
        if (DL > 0) {
          filtered = [
            ...filtered,
            { id: "cat_secciones.idDL", filter: "=", value: DL },
          ];
    
          filtro = [
            ...filtro,
            { id: "cat_secciones.idDL", filter: "=", value: DL },
          ];
        }
        if (Region > 0) {
          filtered = [
            ...filtered,
            { id: "cat_secciones.idRegion", filter: "=", value: Region },
          ];
    
          filtro = [
            ...filtro,
            { id: "cat_secciones.idRegion", filter: "=", value: Region },
          ];
        }
        if (Municipio > 0) {
          filtered = [
            ...filtered,
            { id: "cat_secciones.idMunicipio", filter: "=", value: Municipio },
          ];
    
          filtro = [
            ...filtro,
            { id: "cat_secciones.idMunicipio", filter: "=", value: Municipio },
          ];
        }
        if (Poligono.length > 0) {
          filtered = [
            ...filtered,
            { id: "cat_secciones.idPoligono", filter: "IN", value: Poligono },
          ];
    
          filtro = [
            ...filtro,
            { id: "cat_secciones.idPoligono", filter: "IN", value: Poligono },
          ];
        }
        if (Seccion > 0) {
          filtered = [
            ...filtered,
            { id: "cat_secciones.Seccion", filter: "=", value: Seccion },
          ];
    
          filtro = [
            ...filtro,
            { id: "cat_secciones.Seccion", filter: "=", value: Seccion },
          ];
        }
        if (Casillas > 0) {
          filtered = [
            ...filtered,
            { id: "cat_casillas.id", filter: "=", value: Casillas },
          ];
    
          filtro = [
            ...filtro,
            { id: "cat_casillas.id", filter: "=", value: Casillas },
          ];
        }
        if (Propietario > 0) {
          filtered = [
            ...filtered,
            {
              id: "usuarios_ambito_perfil.idPropietario",
              filter: "=",
              value: Propietario,
            },
          ];
    
          filtro = [
            ...filtro,
            {
              id: "usuarios_ambito_perfil.idPropietario",
              filter: "=",
              value: Propietario,
            },
          ];
        }
        if (Celular.length > 0) {
          filtered = [
            ...filtered,
            {
              id: "usuarios.Celular",
              filter: "LIKE",
              value: Celular,
            },
          ];
        }
        if (ClaveElector.length > 0) {
          filtered = [
            ...filtered,
            {
              id: "usuarios.INE",
              filter: "=",
              value: ClaveElector,
            },
          ];
        }
        if (NombreCompleto.length > 0) {
          filtered = [
            ...filtered,
            {
              id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"],
              filter: "LIKE",
              value: NombreCompleto,
            },
          ];
        }
        if (TieneAuto) {
          filtered = [
            ...filtered,
            { id: "usuarios_ambito_perfil.TieneAuto", filter: "=", value: 1 },
          ];
    
          filtro = [
            ...filtro,
            { id: "usuarios_ambito_perfil.TieneAuto", filter: "=", value: 1 },
          ];
        }
        if (TieneExperiencia) {
          filtered = [
            ...filtered,
            {
              id: "usuarios_ambito_perfil.TieneExperiencia",
              filter: "=",
              value: 1,
            },
          ];
    
          filtro = [
            ...filtro,
            {
              id: "usuarios_ambito_perfil.TieneExperiencia",
              filter: "=",
              value: 1,
            },
          ];
        }
        if (AceptaOtra) {
          filtered = [
            ...filtered,
            {
              id: "usuarios_ambito_perfil.AceptaCuidarOtra",
              filter: "=",
              value: 1,
            },
          ];
    
          filtro = [
            ...filtro,
            {
              id: "usuarios_ambito_perfil.AceptaCuidarOtra",
              filter: "=",
              value: 1,
            },
          ];
        }
    
        setTableData({
          ...tableData,
          filtered: filtered,
        });
    
        // setChartCompromisos({filtered: filtro})
    };

    const handleExport = () => {
        setIsDownloading(true);
        /*     SocialService.exportCompromisos({filtered:tableData.filtered})
          .then((res) => {
            if (res.success) {
              setShowMessage(true);
              setSuccessDownload(true);
            } else {
              setShowMessage(true);
              setSuccessDownload(false);
            }
          })
          .catch((error) => {
            setShowMessage(true);
            setSuccessDownload(false);
          })
          .finally(() => setIsDownloading(false)); */
    };

    const handleChangePagination = (pagination) => {
        setTableData({ ...tableData, ...pagination });
    };


  return (
    <>
      <AdminLayout>
        <Container maxWidth="false">
          <ActionHeader
            title={`Avance Casillas`}
            handleclick={() => navigate(`/AvanceCasillas`)}
          />
          <AssignRepresentative open={false} />
          <ProfileRepresentante open={open} />
              {isLoadingChart ? (
              <CircularProgress />
            ) : (
              <PlacementColumns
                titulo={{ title: dataChart.title }}
                subtitle={{
                  subtitle: moment(dataChart.date).format("DD/MM/YYYY H:mm"),
                }} 
                datos={{
                  categories:dataChart.categories,
                  series: dataChart.dato,
                }}
                loading={isLoadingChart}
              />
            )}
          <Card className="card-primary">
            <CardContent>
              <Filter
                onChangeFilter={(e) => handleChangeFilter(e)}
                filterData={false}
                dl={false}
                df={false}
                region={true}
                municipio={false}
                municipioDL={true}
                poligono={false}
                seccion={true}
                tieneExperiencia = {false}
                TieneAuto= {false}
                AceptaOtra = {false}
                filtroRepresentante={true}
              />
            </CardContent>
          </Card>

          <Card className="card-primary">
            <CardContent>
              {middleware.checkMenuActionId("Exportar") && (
                <>
                  {isDownloading ? (
                    <Download isDownload={isDownloading} format="xlsx" />
                  ) : (
                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      sx={{ mb: 2 }}
                    >
                      <Button
                        variant="outlined"
                        color="primaryDark"
                        startIcon={<Icon>download</Icon>}
                        size="small"
                        onClick={handleExport}
                        sx={{ borderRadius: "10px" }}
                      >
                        Exportar
                      </Button>
                    </Box>
                  )}
                </>
              )}

              {showMessage && (
                <Box marginTop={2}>
                  <MessageAlert
                    showMessage={showMessage}
                    successDownload={successDownload}
                    setShowMessage={setShowMessage}
                  />
                </Box>
              )}

              <BasicTable
                rows={data}
                hcolumns={columns}
                pageProp={tableData.page}
                pageSize={tableData.pageSize}
                total={total}
                handleChangePagination={handleChangePagination}
                isLoading={loading}
                stickyHeader={true}
              />
            </CardContent>
          </Card>
        </Container>
      </AdminLayout>
    </>
  );
};
export default AvanceCasillas;
