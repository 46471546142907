import React from "react";
import { Box, Table, TableContainer, TableBody } from "@mui/material";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import middleware from "@middlewares/middleware";
import MenusList from "@components/Menus/MenusList";
import MenuRowHeader from "@components/Menus/MenuRowHeader";
import MenuRow4Edit from "@components/Menus/MenuRow4Edit";
import UserServices from "@services/UserServices";

const UserMenuList = (props) => {
  const {
    menus,
    maxHeight,
    toolbar,
    ComponentToolbar,
    configToolbar,
    externalID,
    info,
    setIsLoading,
    setIsOpen,
    handleReloadList,
  } = props;

  const handleSwitch = (e, idMenu, col, menu) => {
    e.preventDefault();
    let value = e.target.checked ? 1 : 0;
    Swal.fire({
      title: "Advertencia",
      html: `Esta acción modificará el privilegio de acceso al usuario. ¿Qué acción desea realizar?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aplicar",
      confirmButtonColor: "#1976d2",
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((res) => {
      if (res.isConfirmed) {
        updatePrivillege({ ...menu, [col]: value });
      }
    });
  };

  const updatePrivillege = (menu) => {
    setIsLoading(true);
    setIsOpen(true);
    
    UserServices.changeAccess(
      { ...menu, idUsuario: externalID }
    )
      .then((res) => {
        if (res.success && res.results) {
          handleReloadList(info);
          Swal.fire({
            title: res.message,
            icon: "success",
            customClass: {
              container: "modal-alert",
            },
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
          customClass: {
            container: "modal-alert",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
        setIsOpen(false);
      });
  };

  const handleDelete = (menu) => {
    Swal.fire({
      title: "Advertencia",
      html: `Esta acción eliminará el privilegio de acceso al usuario. ¿Qué acción desea realizar?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aplicar",
      confirmButtonColor: "#1976d2",
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((res) => {
      if (res.isConfirmed) {
        if (menu.submenus && menu.submenus.length > 0) {
          Swal.fire({
            title: "Acción no permitida",
            text: "El menú tiene submenus dependientes. Asegúrese de eliminar los submenús antes de realizar esta acción",
            icon: "warning",
          });
        } else {
          UserServices.deleteMenu(
            { idMenu: menu.idMenu, idUsuario: externalID }
          )
            .then((res) => {
              if (res.success && res.results) {
                handleReloadList(info);
                Swal.fire({ title: res.message, icon: "success" });
              }
            })
            .catch((e) => {
              Swal.fire({ title: e.message, icon: "warning" });
            })
            .finally(() => {
              setIsLoading(false);
              setIsOpen(false);
            });
        }
      }
    });
  };

  if (!middleware.checkMenuAction("Editar")) {
    return <MenusList menus={menus} type={"list"} maxHeight={maxHeight} />;
  }

  return (
    <Box sx={{ width: "100%", overflow: "auto", ml: 0, mr: 0 }}>
      <TableContainer sx={{ maxHeight: maxHeight }}>
        {toolbar && <ComponentToolbar configToolbar={configToolbar} />}
        <Table stickyHeader aria-label="sticky table">
          <MenuRowHeader colSpan={3} />
          <TableBody>
            {menus.length > 0 &&
              menus.map((item, index) => (
                <MenuRow4Edit
                  key={Math.random()}
                  menu={item}
                  index={index}
                  handleSwitch={handleSwitch}
                  canDelete={middleware.checkMenuAction("Eliminar")}
                  handleDelete={handleDelete}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

UserMenuList.propTypes = {
  menus: PropTypes.array.isRequired,
  maxHeight: PropTypes.number.isRequired,
  toolbar: PropTypes.bool.isRequired,
  ComponentToolbar: PropTypes.any,
  configToolbar: PropTypes.any,
  setIsOpen: PropTypes.func,
  setIsLoading: PropTypes.func,
  setIsSuccess: PropTypes.func,
  handleReloadList: PropTypes.func.isRequired,
  externalID: PropTypes.number,
};

UserMenuList.defaultProps = {
  menus: [],
  maxHeight: 800,
  toolbar: false,
  configToolbar: {
    title: "",
    icon: "",
    btnAdd: true,
  },
};

export default UserMenuList;
