import { PrivilegedRoute } from "@middlewares";
import ActaEscrutinio from "@pages/Prep/ActaEscrutinio/ActaEscrutinio";
import Prep from "@pages/Prep/Prep/Prep";
import PrepMuestreo from "@pages/Prep/Prep/PrepMuestreo";
import Partidos from "@pages/Prep/Prep/Partidos";
import Planillas from "@pages/Prep/Prep/Planillas";
import Candidatos from "@pages/Prep/Prep/Candidatos";


const PrepRoutes = [
  {
    path: "/prep",
    element: (
      <PrivilegedRoute>
        <Prep />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/captura-resultados",
    element: (
      <PrivilegedRoute>
        <ActaEscrutinio />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/prep-muestreo",
    element: (
      <PrivilegedRoute>
        <PrepMuestreo />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/prep-partidos",
    element: (
      <PrivilegedRoute>
        <Partidos />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/prep-planillas",
    element: (
      <PrivilegedRoute>
        <Planillas />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/prep-candidatos",
    element: (
      <PrivilegedRoute>
        <Candidatos />
      </PrivilegedRoute>
    ),
  },
];

export default PrepRoutes;
