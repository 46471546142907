import {ApiExec, ApiExecDecrypted} from "../../utils/ApiExec";

class MapService {
  getSections = (params) => {
    return ApiExecDecrypted(params, "map/polygons/get-sections", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getFederalDistricts = (params) => {
    return ApiExecDecrypted(params, "map/polygons/get-dfeds", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  setPolygon = (params) => {
    return ApiExec(params, "map/polygons/set-polygon", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getPolygons = (params) => {
    return ApiExec(params, "map/polygons/get-polygons", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  updatePolygon = (params) => {
    return ApiExec(params, "map/polygons/update-polygon", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  deletePolygon = (params) => {
    return ApiExec(params, "map/polygons/delete-polygon", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }
  getInformationMunicipality = (params) => {
    return ApiExec(params, "map/polygons/get-information-municipality", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }
  
  ListProgress = (params) => {
    return ApiExec(params, "map/polygons/get-sections-information", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }

  TotalsProgress = (params) => {
    return ApiExec(params, "map/polygons/get-sections-totals", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }
  
  
}
var map = new MapService();
export default map;
