
import React from 'react';
import {
    Icon,
    Box,
    Typography
} from "@mui/material";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { treeItemClasses } from "@mui/lab/TreeItem";
import { isEmptyOrInvalidArray} from "@utils/validations";
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from "@mui/material/styles";

export default function Tree(props) {
    const {
        data,
        loading,
        onDelete,
        onEdit,
        onAdd,
        onSelect,
        onExpanded,
        setExpanded,
        onSelected,
        setSelected,
        typeData = { value: "id", label: "label" }
    } = props;

   /*  useEffect(()=>{
        console.log("expad",onExpanded,onSelected)
    },[onExpanded,onSelected]) */
    
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
      };
 
    const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
        color: theme.palette.text.secondary,
        [`& .${treeItemClasses.content}`]: {
            color: theme.palette.text.secondary,
            borderTopRightRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
            paddingRight: theme.spacing(1),
            fontWeight: theme.typography.fontWeightMedium,
            "&.Mui-expanded": {
                fontWeight: theme.typography.fontWeightRegular
            },
            "&:hover": {
                backgroundColor: theme.palette.action.hover
            },
            "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
                backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
                color: "var(--tree-view-color)"
            },
            [`& .${treeItemClasses.label}`]: {
                fontWeight: "inherit",
                color: "inherit"
            }
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 0,
            [`& .${treeItemClasses.content}`]: {
                paddingLeft: theme.spacing(2)
            }
        }
    }));

    function StyledTreeItem(props) {
        const {
            onDelete,
            onEdit,
            onAdd,
            onSelect,
            bgColor,
            color,
            labelIcon: LabelIcon,
            labelInfo,
            labelText,
            ...other
        } = props;

        return (
            <StyledTreeItemRoot
                label={
                    <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: "inherit", flexGrow: 1 }}
                        >
                            {labelText}
                        </Typography>
                        {
                           
                        }

                    </Box>
                }
                onClick={(e) => onSelect(other.node)}
                style={{
                    "--tree-view-color": color,
                    "--tree-view-bg-color": bgColor
                }}
                {...other}
            />
        );
    }


    const renderTree = (nodes) => (
        <StyledTreeItem
            nodeId={`${nodes[typeData.value]}`}
            key={nodes[typeData.value]}
            labelText={nodes[typeData.label]}
            node={nodes}
            onDelete={(e) => onDelete(e)}
            onEdit={(e) => onEdit(e)}
            onAdd={(e) => onAdd(e)}
            onSelect={(e) => onSelect(e)}
        /* onClick={() => handleClick(nodes, isEmptyOrInvalidArray(nodes.children) ? 'space' : '')} */
        >
            {!isEmptyOrInvalidArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </StyledTreeItem>
    );
    const TreeComponent = (
        <TreeView
            defaultCollapseIcon={<Icon>expand_more</Icon>}
            defaultExpandIcon={<Icon color="primary">chevron_right</Icon>}
            defaultEndIcon={<Icon sx={{ color: "lightgray" }}>chevron_right</Icon>}
            expanded={onExpanded}
            selected={onSelected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
        >
            {data.map((item) => renderTree(item))}
        </TreeView>
    );

    return (
        <>
            {
                loading ? <CircularProgress /> :
                    data.length !== 0 ? <>{TreeComponent}</> : <>Sin datos</>
            }
        </>
    )
}
