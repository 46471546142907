import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  FormLabel,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  Divider,
  FormGroup,
  Checkbox,
  InputLabel,
  OutlinedInput,
  Stack,
  Chip,
  Icon
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import events from "../../services/EventsServices";
import moment from "moment";
import Swal from "sweetalert2";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { isEmptyOrInvalidString, isNullOrUndefined } from "@utils/validations";

function EventQuestions(props) {
  const {
    dataEvent,
    dataPreguntas,
    flagNot,
    handleChange,
    RegisterEvent,
    loading,
  } = props;
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    let answersNew = dataPreguntas.map((item) => {
      return {
        idPregunta: item.id,
        idTipoRespuesta: item.idTipoRespuesta,
        idRespuesta: [],
      };
    });

    setAnswers(answersNew);
  }, [dataPreguntas]);

  useEffect(() => {
    if (answers.length !== 0) {
      handleSend();
    }
  }, [answers]);

  const handleChangeRadio = (e, pregunta) => {
    let filterAnswers = answers.filter(
      (item) => item.idPregunta !== pregunta.id
    );
    const selectedAnswer = parseInt(e.target.value);
    const answerObject = {
      idPregunta: pregunta.id,
      idTipoRespuesta: pregunta.idTipoRespuesta,
      idRespuesta: [selectedAnswer],
    };
    setAnswers([...filterAnswers, answerObject]);
  };

  const handleAnswerChange = (e, pregunta) => {
    let filterAnswers = answers.filter(
      (item) => item.idPregunta !== pregunta.id
    );
    let findAnswer = answers.find((item) => item.idPregunta === pregunta.id);
    const checkedValue = parseInt(e.target.value);

    if (e.target.checked) {
      // Agregar respuesta seleccionada
      const answerObject = {
        idPregunta: pregunta.id,
        idTipoRespuesta: pregunta.idTipoRespuesta,
        idRespuesta: [...findAnswer.idRespuesta, checkedValue],
      };

      setAnswers([...filterAnswers, answerObject]);
    } else {
      // Eliminar respuesta deseleccionada
      const idNewRespuesta = findAnswer.idRespuesta.filter(
        (fil) => fil !== checkedValue
      );

      const answerObject = {
        idPregunta: pregunta.id,
        idTipoRespuesta: pregunta.idTipoRespuesta,
        idRespuesta: idNewRespuesta,
      };
      setAnswers([...filterAnswers, answerObject]);
    }
  };

  const handleChangeString = (e, pregunta) => {
    let filterAnswers = answers.filter(
      (item) => item.idPregunta !== pregunta.id
    );
    const answerObject = {
      idPregunta: pregunta.id,
      idTipoRespuesta: pregunta.idTipoRespuesta,
      idRespuesta: [],
      ValorRespuesta: e.target.value,
    };
    setAnswers([...filterAnswers, answerObject]);
  };

  const handleSend = () => {
    let validate = true;

    answers.forEach((Q) => {
      if ([1, 2].includes(Q.idTipoRespuesta)) {
        if (Q.idRespuesta.length === 0) {
          validate = false;
          return;
        }
      } else if (Q.idTipoRespuesta === 3) {
        if (
          isNullOrUndefined(Q.ValorRespuesta) ||
          Q.ValorRespuesta.trim() === ""
        ) {
          validate = false;
          return;
        }
      }
    });

    if (validate) {
      handleChange({
        target: { name: "preguntas_respuestas", value: answers },
      });
    }
  };

  return (
    <Box >
      <Container>
        <Grid container>
          <Grid
            item
            md={12}
            //sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}
          >
            {!flagNot && (
              <>
                <Typography
                  variant="h5"
                  color={"primary"}
                  fontWeight="bold"
                  align="center"
                  sx={{ fontFamily: "initial" }}
                >{`${dataEvent.Actividad}`}</Typography>
                <Typography variant="body1" color="text" mb={3} align="center">
                  Conteste las siguientes preguntas para realizar su registro.
                </Typography>
                <Divider variant="middle" sx={{ border: 1, color: "blue" }} />
              </>
            )}
            {flagNot && (
              <Grid xs={12} sm={12} md={12} lg={12}>
                <Chip icon={<Icon>home</Icon>} label={"Preguntas Adicionales"} />
              </Grid>
            )}
            <Grid container sx={{ mt: 3 }} spacing={4}>
              {dataPreguntas.map((question) => {
                return question.idTipoRespuesta === 1 ? (
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        {question.Pregunta}
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={
                          answers.length > 0
                            ? answers.find((a) => a.idPregunta === question.id)
                                .idRespuesta[0]
                            : ""
                        }
                        onChange={(e) => handleChangeRadio(e, question)}
                      >
                        {question.Respuestas.map((ans) => {
                          return (
                            <FormControlLabel
                              value={ans.id}
                              control={<Radio />}
                              label={ans.Respuesta}
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                ) : question.idTipoRespuesta === 2 ? (
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {question.Pregunta}
                      </FormLabel>
                      <FormGroup
                        aria-label="position"
                        onChange={(e) => handleAnswerChange(e, question)}
                      >
                        {question.Respuestas.map((ans) => {
                          return (
                            <FormControlLabel
                              value={ans.id}
                              control={<Checkbox />}
                              label={ans.Respuesta}
                              labelPlacement="end"
                            />
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth variant="standard">
                      <FormLabel htmlFor="standard-adornment-amount">
                        {question.Pregunta}
                      </FormLabel>
                      <OutlinedInput
                        sx={{ mt: 1 }}
                        id="standard-adornment-amount"
                        placeholder="Respuesta"
                        onChange={(e) => handleChangeString(e, question)}
                      />
                    </FormControl>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default EventQuestions;
