import React from "react";

import { Box, Button, Container, Typography } from "@mui/material";
import AdminLayout from "@components/MainPage/AdminLayout";
import logo from "@assets/img/SIAN.png";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const navigate = useNavigate();
  return (
    <AdminLayout>
      <Container component="main" maxWidth="lg">
        <Box component="div" className="main-welcome">
          <Box component="div" className="submain-welcome">
            <Box
              sx={{
                //marginTop: "80px",
                marginBottom: "50px",
                //height: "240px",
                maxWidth: "300px",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                padding: "12px",
                borderRadius: "12px",
                backgroundColor: "#fff",
              }}
            >
              <img
                src={logo}
                alt="logo_sian"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Typography
              variant="h3"
              component="p"
              fontWeight={700}
              sx={{ margin: "0px 0px 16px" }}
            >
              ¡Bienvenido al sistema!
            </Typography>
            <Typography
              variant="body1"
              component="p"
              fontWeight={400}
              sx={{
                margin: "0px",
                color: "rgb(99, 115, 129)",
                marginBottom: "50px",
              }}
            >
              Sistema de Información de Acción Nacional
            </Typography>

            <Button
              variant="contained"
              sx={{
                borderRadius: "8px",
                backgroundColor: "#0041a0",
                p: 2,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#0041a0",
                  color: "#fff",
                },
              }}
              onClick={() => navigate('/compromisos-registro')}
            >
              Ir a Compromisos
            </Button>
          </Box>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default Welcome;
