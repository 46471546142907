import React, { useEffect, useState } from "react";

//MUI
import {
  Card,
  CardContent,
  Container,
  Grid,
  Box,
  Tab,
  Icon,
  Typography,
  Stack,
  Divider,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
import CardStats from "@components/Card/CardStats";
import ActionHeader from "@components/Containers/ActionHeader";
import Tree from "@components/TreeView/TreeView";
import BasicTable from "@components/Tables/BasicTable";
import MyUsersFilter from "@components/Users/MyUsersFilter";
import UserServices from "@services/UserServices";
import CompromisoServices from "@services/CompromisoServices";
import Swal from "sweetalert2";
import { numberWithCommas, searchTree } from "@utils/Utilities";
import { isTypePhone } from "@utils/validations";

const MyUsers = () => {
  //variables
  const columns = [
    { id: "Region", label: "Región", align: 'center' },
    { id: "Municipio", label: "Municipio", align: 'center' },
    { id: "NombreCompleto", label: "Nombre", align: 'center' },
    { id: "SeccionVota", label: "Sección Vota", align: 'center' },
    { id: "EsVoluntario", label: "Voluntario", align: 'center' },
    { id: "CapturadoPor", label: "Capturado Por", align: 'center' },
  ];

  //States
  // const [total] = useState(0);
  const [loadingTree, setLoadingTree] = useState(false);
  const [showButtonCancel, setShowButtonCancel] = useState(false);
  const [valueTab, setValueTab] = useState("2");
  const [nameUser, setNameUser] = useState("");
  const [phone, setPhone] = useState("");
  const [commitments, setCommitments] = useState([]);
  const [treeUsersFound, setTreeUsersFound] = useState({});
  const [treeUsers, setTreeUsers] = useState([]);
  const [loadingCommitments, setLoadingCommitments] = useState(false);
  const [totalCommitments, setTotalCommitments] = useState(0);
  const [data, setData] = useState({
    idUsuario: 0,
    page: 0,
    pageSize: 10,
    unique: false,
    nested: false,
    filtered: [],
    //sorted: [{ id: "compromisos_unicos.FechaCreo", value: "DESC" }],
  });
  const [totalUser, setTotalUser] = useState({
    totalUsuarios: 0,
    totalCompromisos: 0,
    totalCompromisosUnicos: 0,
  });

  //API's
  const getMyUsersTree = () => {
    setLoadingTree(true);
    UserServices.getMyUsers({})
      .then((res) => {
        if (res.results) {
          setTreeUsers([res.response]);
          setTreeUsersFound(res.response);
          handleItemTree(res.response);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoadingTree(false));
  };

  const getUserCommitments = (params) => {
    setLoadingCommitments(true);
    CompromisoServices.getUserCommitments(params).then(res => {
      if (res.results) {
        setCommitments(res.response.data.map(item => {
          return {
            ...item,
            EsVoluntario: item.EsVoluntario === 1 ? 'SI' : 'NO'
          }
        }));
        setTotalCommitments(res.response.total)
      } else {
        Swal.fire({ title: res.message, icon: "warning" });
      }
    }).catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoadingCommitments(false));
  }

  useEffect(() => {
    getMyUsersTree();
    //eslint-disable-next-line
  }, []);

  //Functions and Handlers
  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleItemTree = (e) => {
    if (e !== null) {
      const {
        id,
        Nombre,
        Paterno,
        Materno,
        TotalUsuarios,
        TotalCompromisos,
        CompromisosUnicos,
      } = e;
      const NameUser = `${Nombre} ${Paterno} ${Materno}`;
      setNameUser(NameUser);
      setTotalUser({
        ...totalUser,
        totalUsuarios: numberWithCommas(TotalUsuarios),
        totalCompromisos: numberWithCommas(TotalCompromisos),
        totalCompromisosUnicos: numberWithCommas(CompromisosUnicos),
      });
      setData({ ...data, idUsuario: id });
    } else {
      setNameUser("");
      setTotalUser({
        ...totalUser,
        totalUsuarios: 0,
        totalCompromisos: 0,
        totalCompromisosUnicos: 0,
      });
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleSearch = () => {
    if (phone === "") {
      return;
    }
    const founds = searchTree(treeUsersFound, "Username", phone);
    setTreeUsers(founds !== null ? [founds] : []);
    setShowButtonCancel(true);
    handleItemTree(founds);
  };

  const handleClearSearch = () => {
    setPhone("");
    setShowButtonCancel(false);
    getMyUsersTree();
  };

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  useEffect(() => {
    if (data.idUsuario && data.idUsuario !== 0) {
      getUserCommitments(data);
    }
  }, [data])

  const handleChangeFilter = (options) => {
    setData({
      ...data,
      page: 0,
      unique: options.unique,
      nested: options.nested
    });
  }

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={"Mis Usuarios"} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card
              className="card-primary"
              sx={{ height: "1200px", overflow: "auto" }}
            >
              <CardContent>
                <Box marginBottom={"1rem"}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    marginBottom={"0.5rem"}
                  >
                    <Icon>person_search</Icon>
                    <Typography
                      fontWeight={600}
                      variant={"body2"}
                      marginBottom={"0.5rem"}
                    >
                      Buscar por número de celular
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      label="Celular"
                      size="small"
                      name="phone"
                      value={phone}
                      onChange={(e) =>
                        handleChangePhone({
                          target: {
                            name: "phone",
                            value: isTypePhone(e.target.value)
                              ? e.target.value
                              : phone,
                          },
                        })
                      }
                      onKeyUp={handleOnKeyPress}
                      InputProps={{
                        endAdornment: showButtonCancel && (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClearSearch}>
                              <Icon>clear</Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ width: "100%" }}
                    />

                    <Button
                      variant="contained"
                      color="primaryDark"
                      sx={{ width: "25%" }}
                      onClick={handleSearch}
                    >
                      Buscar
                    </Button>
                  </Stack>
                </Box>
                <Divider sx={{ marginBottom: "1rem" }} />
                <Tree
                  data={treeUsers}
                  typeData={{
                    value: "id",
                    label: "Nombre",
                    S_label: "Paterno",
                    T_label: "Username",
                  }}
                  handleClick={handleItemTree}
                  loading={loadingTree}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <CardStats
                  icon={"people"}
                  title={"Total de Usuarios"}
                  total={totalUser.totalUsuarios}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CardStats
                  icon={"feed"}
                  title={"Compromisos (Gral)"}
                  total={totalUser.totalCompromisos}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CardStats
                  icon={"fact_check"}
                  title={"Compromisos Únicos"}
                  total={totalUser.totalCompromisosUnicos}
                />
              </Grid>
            </Grid>

            <Card className="card-primary">
              <CardContent>
                {nameUser !== "" && (
                  <>
                    <Stack direction={"row"} spacing={1} alignItems="center">
                      <Icon sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
                        account_circle
                      </Icon>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {nameUser}
                      </Typography>
                    </Stack>
                    <Divider />
                  </>
                )}
                <Box
                  marginTop={"1rem"}
                  sx={{ width: "100%", typography: "body1" }}
                >
                  <TabContext value={valueTab}>
                    <Box>
                      <TabList
                        onChange={handleChangeTab}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          icon={<Icon>show_chart</Icon>}
                          iconPosition="start"
                          label="Estadística"
                          value="1"
                        />
                        <Tab
                          icon={<Icon>feed</Icon>}
                          iconPosition="start"
                          label="Compromisos"
                          value="2"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1">

                    </TabPanel>
                    <TabPanel value="2">
                      <MyUsersFilter onChangeFilter={(e) => handleChangeFilter(e)} />
                      <BasicTable
                        rows={commitments}
                        hcolumns={columns}
                        pageProp={data.page}
                        pageSize={data.pageSize}
                        total={totalCommitments}
                        handleChangePagination={handleChangePagination}
                        isLoading={loadingCommitments}
                        stickyHeader={true}
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};

export default MyUsers;
