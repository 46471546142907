import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

import {
  Button,
  Stack,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Collapse,
  CardHeader,
  CardContent,
  Card,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";

import BasicSelect from "@components/Selects/BasicSelect";

import sije from "@services/SijeService";

const FilterSije = ({ onChangeFilter, onClearFilter }) => {
  //CONSTANTS
  const catalogsOptions = [
    { id: "regiones" },
    { id: "dfs" },
    { id: "secciones" },
    { id: "dls" },
    { id: "municipios_reportes" },
    //{ id: "tipos_eleccion" },
  ];
  //STATES
  const divFilterRef = useRef(null);
  const [open, setOpen] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
  });
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [loadingCatCasillas, setLoadingCatCasillas] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogCasillas, setCatalogsCasillas] = useState([]);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [filterData, setFilterData] = useState({
    // TipoEleccion: "",
    DttoLocal: "",
    DttoFederal: "",
    Region: "",
    Municipio: "",
    Seccion: "",
    Casilla: "",
    AsistenciaRG: 9999,
    InstalacionCasilla: 9999,
    AperturaCasilla: 9999,
    CierreCasilla: 9999,
    CierreVotacion: 9999,
    PaseListaP1: 9999,
    PaseListaP2: 9999,
    PaseListaS1: 9999,
    PaseListaS2: 9999,
  });

  const getCatalogs = async () => {
    try {
      setLoadingCatalogs((prevState) => !prevState);
      const result = await sije.getCatalogs({ catalogs: catalogsOptions });
      const { results, response, message } = await result;
      if (results) {
        setCatalogFiltered(response.catalogs);
        setCatalogs(response.catalogs);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs((prevState) => !prevState);
    }
  };

  const handleChangeRegion = (e) => {
    const idRegion = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      municipios_reportes: catalogs.municipios_reportes.filter(
        (item) => item.idRegion === idRegion
      ),
      secciones: catalogs.secciones.filter(
        (item) => item.idRegion === idRegion
      ),
    }));
  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      secciones: catalogs.secciones.filter(
        (item) => item.idMunicipioReportes === idMunicipality
      ),
    }));
  };

  const handleChangeSection = async (e) => {
    const idSection = e.target.value;
    if (idSection) {
      try {
        setLoadingCatCasillas((prevState) => !prevState);
        const result = await sije.getCasillasBySeccion({
          Seccion: String(idSection),
        });
        const { response, results, message } = await result;
        if (results) {
          setCatalogsCasillas(
            response.data.map((item) => {
              return {
                label: item.NombreCasilla,
                value: item.idCasilla,
              };
            })
          );
        } else {
          Swal.fire({
            title: message,
            icon: "warning",
          });
        }
      } catch (error) {
        Swal.fire({
          title: error,
          icon: "warning",
        });
      } finally {
        setLoadingCatCasillas((prevState) => !prevState);
      }
    }
  };

  const handleChangeFilter = (e) => {
    const key = e.target.name;
    setFilterData({ ...filterData, [key]: e.target.value });
  };

  const handleSearch = () => {
    let filteredChart = [];
    let filtered = [];

    /* if (filterData.TipoEleccion > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: "cat_casilla.TipoEleccion",
          filter: "=",
          value: filterData.TipoEleccion,
        },
      ];
    } */

    if (filterData.DttoLocal > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: "cat_secciones.idDL",
          filter: "=",
          value: filterData.DttoLocal,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "idDL",
          filter: "=",
          value: filterData.DttoLocal,
        },
      ];
    }

    if (filterData.DttoFederal > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: "cat_secciones.idDF",
          filter: "=",
          value: filterData.DttoFederal,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "idDF",
          filter: "=",
          value: filterData.DttoFederal,
        },
      ];
    }

    if (filterData.Region > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: "cat_secciones.idRegion",
          filter: "=",
          value: filterData.Region,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "idRegion",
          filter: "=",
          value: filterData.Region,
        },
      ];
    }

    if (filterData.Municipio > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: "cat_secciones.idMunicipioReportes",
          filter: "=",
          value: filterData.Municipio,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "idMunicipioReportes",
          filter: "=",
          value: filterData.Municipio,
        },
      ];
    }

    if (filterData.Seccion > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: "cat_secciones.Seccion",
          filter: "=",
          value: filterData.Seccion,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "Seccion",
          filter: "=",
          value: filterData.Seccion,
        },
      ];
    }

    if (filterData.Casilla > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: "cat_casillas.id",
          filter: "=",
          value: filterData.Casilla,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "idCasilla",
          filter: "=",
          value: filterData.Casilla,
        },
      ];
    }

    if (filterData.AsistenciaRG !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: "sije_casillas.esPaseListaRG",
          filter: "=",
          value: filterData.AsistenciaRG,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "esPaseListaRG",
          filter: "=",
          value: filterData.AsistenciaRG,
        },
      ];
    }

    if (filterData.InstalacionCasilla !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: "sije_casillas.esInicioInstalacion",
          filter: "=",
          value: filterData.InstalacionCasilla,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "esInicioInstalacion",
          filter: "=",
          value: filterData.InstalacionCasilla,
        },
      ];
    }

    if (filterData.AperturaCasilla !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: "sije_casillas.esAperturaCasilla",
          filter: "=",
          value: filterData.AperturaCasilla,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "esAperturaCasilla",
          filter: "=",
          value: filterData.AperturaCasilla,
        },
      ];
    }

    if (filterData.CierreCasilla !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: "sije_casillas.esCierreCasilla",
          filter: "=",
          value: filterData.CierreCasilla,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "esCierreCasilla",
          filter: "=",
          value: filterData.CierreCasilla,
        },
      ];
    }

    if (filterData.CierreVotacion !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: "sije_casillas.esCierreVotacion",
          filter: "=",
          value: filterData.CierreVotacion,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "esCierreVotacion",
          filter: "=",
          value: filterData.CierreVotacion,
        },
      ];
    }

    if (filterData.PaseListaP1 !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: "sije_casillas.esPaseListaP1",
          filter: "=",
          value: filterData.CierreVotacion,
        },
      ];

      filtered = [
        ...filtered,
        {
          id: "esPaseListaP1",
          filter: "=",
          value: filterData.PaseListaP1,
        },
      ];
    }

    if (filterData.PaseListaP2 !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: "sije_casillas.esPaseListaP2",
          filter: "=",
          value: filterData.PaseListaP2,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "esPaseListaP2",
          filter: "=",
          value: filterData.PaseListaP2,
        },
      ];
    }

    if (filterData.PaseListaS1 !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: "sije_casillas.esPaseListaS1",
          filter: "=",
          value: filterData.PaseListaS1,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "esPaseListaS1",
          filter: "=",
          value: filterData.PaseListaS1,
        },
      ];
    }

    if (filterData.PaseListaS2 !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: "sije_casillas.esPaseListaS2",
          filter: "=",
          value: filterData.PaseListaS2,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "esPaseListaS2",
          filter: "=",
          value: filterData.PaseListaS2,
        },
      ];
    }

    onChangeFilter({
      filtersChart: filteredChart,
      filtersData: filtered,
    });
  };

  const handleClearFilter = () => {
    const cleanFileds = {
      // TipoEleccion: "",
      DttoLocal: "",
      DttoFederal: "",
      Region: "",
      Municipio: "",
      Seccion: "",
      Casilla: "",
      AsistenciaRG: 9999,
      InstalacionCasilla: 9999,
      AperturaCasilla: 9999,
      CierreCasilla: 9999,
      CierreVotacion: 9999,
      PaseListaP1: 9999,
      PaseListaP2: 9999,
      PaseListaS1: 9999,
      PaseListaS2: 9999,
    };
    setCatalogFiltered(catalogs);
    setCatalogsCasillas([]);
    setFilterData(cleanFileds);
    onClearFilter();
  };

  const handleClick = (target) => {
    setOpen({ [target]: !open[target] });
  };

  useEffect(() => {
    getCatalogs();
  }, []);

  useEffect(() => {
    divFilterRef.current.onscroll = () => {
      divFilterRef.current.scrollTop > 10
        ? setIsScroll(true)
        : setIsScroll(false);
    };
  }, []);

  return (
    <Card
      className="card-primary"
      sx={{
        //height: "56%",
        position: "sticky",
        top: "6rem",
        // overflowY: "scroll",
      }}
    >
      <CardHeader
        title={
          <Typography variant="subtitle1" fontWeight={600}>
            Filtros
          </Typography>
        }
        action={
          <Stack direction={"row"} spacing={1}>
            <Button
              onClick={handleClearFilter}
              size="small"
              color="primaryDark"
            >
              Limpiar
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleSearch}
              color="primaryDark"
            >
              Filtrar
            </Button>
          </Stack>
        }
        sx={{
          boxShadow: isScroll
            ? "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px !important"
            : "none",
        }}
      />
      <Divider />
      <CardContent
        ref={divFilterRef}
        sx={{ height: "610px", overflowY: "scroll" }}
      >
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Box marginBottom={3}>
            <ListItem
              onClick={(e) => handleClick(0)}
              sx={{ cursor: "pointer" }}
            >
              <ListItemText
                primary=<Typography variant="body1" fontWeight={600}>
                  Distritos
                </Typography>
              />
              {open[0] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={open[0]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <BasicSelect
                    name="DttoLocal"
                    label="Distrito Local"
                    options={catalogsFiltered.dls}
                    value={filterData.DttoLocal}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="DttoFederal"
                    label="Distrito Federal"
                    options={catalogsFiltered.dfs}
                    value={filterData.DttoFederal}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </Box>

          <Box marginBottom={3}>
            <ListItem
              onClick={(e) => handleClick(1)}
              sx={{ cursor: "pointer" }}
            >
              <ListItemText
                primary=<Typography variant="body1" fontWeight={600}>
                  Ámbito
                </Typography>
              />
              {open[1] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={open[1]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <BasicSelect
                    name="Region"
                    label="Región"
                    options={catalogsFiltered.regiones}
                    value={
                      catalogsFiltered.regiones.length === 1
                        ? catalogsFiltered.regiones[0].value
                        : filterData.Region
                    }
                    onChange={(e) => {
                      handleChangeFilter(e);
                      handleChangeRegion(e);
                    }}
                    isLoading={loadingCatalogs}
                    disabled={catalogsFiltered.regiones.length === 1}
                    sx={{ width: "100%" }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="Municipio"
                    label="Municipio"
                    options={catalogsFiltered.municipios_reportes}
                    value={
                      catalogsFiltered.municipios_reportes.length === 1
                        ? catalogsFiltered.municipios_reportes[0].value
                        : filterData.Municipio
                    }
                    onChange={(e) => {
                      handleChangeFilter(e);
                      handleChangeMunicipality(e);
                    }}
                    isLoading={loadingCatalogs}
                    disabled={catalogsFiltered.municipios_reportes.length === 1}
                    sx={{ width: "100%" }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="Seccion"
                    label="Seccion"
                    options={catalogsFiltered.secciones.map((item) => {
                      return { value: item.value, label: String(item.label) };
                    })}
                    value={
                      catalogsFiltered.secciones.length === 1
                        ? catalogsFiltered.secciones[0].value
                        : filterData.Seccion
                    }
                    onChange={(e) => {
                      handleChangeFilter(e);
                      handleChangeSection(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="Casilla"
                    label="Casilla"
                    options={catalogCasillas}
                    value={filterData.Casilla}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatCasillas}
                    sx={{ width: "100%" }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </Box>

          <Box marginBottom={3}>
            <ListItem
              onClick={(e) => handleClick(2)}
              sx={{ cursor: "pointer" }}
            >
              <ListItemText
                primary=<Typography variant="body1" fontWeight={600}>
                  Casilla
                </Typography>
              />
              {open[2] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={open[2]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <BasicSelect
                    name="AsistenciaRG"
                    label="Asistencia RG"
                    options={[
                      { label: "Todos", value: 9999 },
                      { label: "Asistencia RGS", value: 1 },
                      { label: "No Asistencia RGS", value: 0 },
                    ]}
                    value={filterData.AsistenciaRG}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="InstalacionCasilla"
                    label="Instalación Casilla"
                    options={[
                      { label: "Todos", value: 9999 },
                      { label: "Casillas Instaladas", value: 1 },
                      { label: "No Instaladas", value: 0 },
                    ]}
                    value={filterData.InstalacionCasilla}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="AperturaCasilla"
                    label="Apertura Casilla"
                    options={[
                      { label: "Todos", value: 9999 },
                      { label: "Aperturadas", value: 1 },
                      { label: "No Aperturadas", value: 0 },
                    ]}
                    value={filterData.AperturaCasilla}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="CierreCasilla"
                    label="Cierre Casilla"
                    options={[
                      { label: "Todos", value: 9999 },
                      { label: "Casillas Cerradas", value: 1 },
                      { label: "No Cerradas", value: 0 },
                    ]}
                    value={filterData.CierreCasilla}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="CierreVotacion"
                    label="Cierre Votacion"
                    options={[
                      { label: "Todos", value: 9999 },
                      { label: "Votación Cerradas", value: 1 },
                      { label: "No Cerradas", value: 0 },
                    ]}
                    value={filterData.CierreVotacion}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </Box>

          <Box>
            <ListItem
              onClick={(e) => handleClick(3)}
              sx={{ cursor: "pointer" }}
            >
              <ListItemText
                primary=<Typography variant="body1" fontWeight={600}>
                  Pase de Lista
                </Typography>
              />
              {open[3] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={open[3]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <BasicSelect
                    name="PaseListaP1"
                    label="Pase Lista P1"
                    options={[
                      { label: "Todos", value: 9999 },
                      { label: "Pase Lista RCP1", value: 1 },
                      { label: "No Pase Lista RCP1", value: 0 },
                    ]}
                    value={filterData.PaseListaP1}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="PaseListaP2"
                    label="Pase Lista P2"
                    options={[
                      { label: "Todos", value: 9999 },
                      { label: "Pase Lista RCP2", value: 1 },
                      { label: "No Pase Lista RCP2", value: 0 },
                    ]}
                    value={filterData.PaseListaP2}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="PaseListaS1"
                    label="Pase Lista S1"
                    options={[
                      { label: "Todos", value: 9999 },
                      { label: "Pase Lista RCS1", value: 1 },
                      { label: "No Pase Lista RCS1", value: 0 },
                    ]}
                    value={filterData.PaseListaS1}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="PaseListaS2"
                    label="Pase Lista S2"
                    options={[
                      { label: "Todos", value: 9999 },
                      { label: "Pase Lista RCS2", value: 1 },
                      { label: "No Pase Lista RCS2", value: 0 },
                    ]}
                    value={filterData.PaseListaS2}
                    onChange={(e) => {
                      handleChangeFilter(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </Box>
        </List>
        {/* <Box marginTop={2}>
          <Button
            variant="contained"
            size="small"
            sx={{ width: "100%" }}
            onClick={handleSearch}
          >
            Filtrar
          </Button>
          <Button
            size="small"
            sx={{ width: "100%", mt: 1 }}
            onClick={handleClearFilter}
          >
            Limpiar
          </Button>
        </Box> */}
      </CardContent>
    </Card>
  );
};

export default FilterSije;
